import { Button, Form, Image, Input, Typography } from 'antd';
import { lowerCase, uniqueId } from 'lodash';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';

const { Paragraph } = Typography;
function FormDisplay({ sectionHeading, headerDescription, data = {} }) {
  const [form] = Form?.useForm();

  return (
    <section className="donate-page inner-page">
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerDescription={headerDescription}
        />
      </div>
      <div className="container-rnp">
        <div className="form-block">
          <Form form={form} onFinish={() => {}} layout="vertical">
            {data?.title && (
              <div className="form-container">
                <div className="form-title">{data?.title}</div>
                {data?.description ? (
                  <Paragraph
                    className="text-small text-black-70 vc-paragraph mb-16"
                    ellipsis={{ rows: 2 }}
                  >
                    {data?.description}
                  </Paragraph>
                ) : null}
              </div>
            )}
            <div className="form-module-body">
              {data?.fields?.map((field) => {
                return (
                  <div className="form-group" key={field?.key}>
                    <Form.Item
                      name={field?.key}
                      label={field?.label}
                      rules={[
                        {
                          required: field?.required,
                          message: `Please enter ${lowerCase(field?.label)}!`
                        }
                      ]}
                    >
                      {field?.type === 'text' ? (
                        <Input.TextArea
                          rows={3}
                          className="textarea"
                          placeholder={field?.label}
                        />
                      ) : (
                        <Input placeholder={field?.label} />
                      )}
                    </Form.Item>
                  </div>
                );
              })}
              <div className="d-flex flex-wrap form-btns gap-8">
                {data?.cta?.length > 0 &&
                  data?.cta?.map((cta) => {
                    return (
                      <Button
                        icon={
                          cta?.icon?.url && (
                            <Image
                              src={cta?.icon?.url}
                              width={16}
                              height={16}
                              alt="icon"
                            />
                          )
                        }
                        key={uniqueId()}
                        onClick={() => {}}
                        size="large"
                        className={`d-flex align-center justify-center ${
                          cta?.type !== 'PRIMARY'
                            ? 'b-0 b-solid bg-ivory text-n-900 hbg-n-200 text-n-900 hc-black-80 b-transparent'
                            : 'bg-n-900 b-0 hbg-n-700 hc-white-90 text-white-90'
                        } cs-button`}
                      >
                        {cta?.label}
                      </Button>
                    );
                  })}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default FormDisplay;
