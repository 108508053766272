import { Badge, ConfigProvider, Slider, Typography } from 'antd';
import React from 'react';
import Image from '../../../components/Image';
import webStyle from '../../../styles/style-web.json';

const { Text, Paragraph } = Typography;
function PodcastCard({
  src,
  defaultValue,
  time,
  category,
  collection,
  title,
  date,
  speaker,
  Podcastlist,
  tagBackground = 'bg-transparent',
  tagTextColor = 'text-n-900',
  tags,
  description,
  blurHash
}) {
  return (
    <ConfigProvider theme={webStyle}>
      <div className={`video-card ${Podcastlist && 'videolist'}`}>
        <div className="video-image relative bg-n-600">
          {src ? (
            <Image
              className="absolute inset-0"
              src={src}
              blurHash={blurHash}
              alt={title}
            />
          ) : null}
          {time ? <div className="timestamp">{time} </div> : null}

          {defaultValue ? (
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    railSize: 4,
                    borderRadiusXS: 12,
                    colorBgElevated: ' #FFFFFF',
                    dotSize: 8,
                    handleSize: 16,
                    handleSizeHover: 16,
                    colorPrimaryBorder: '#FE4819',
                    colorBorderSecondary: '#B0ADAB',
                    colorBgContainer: '#B0ADAB',
                    colorTextDescription: '#FFFFFF',
                    colorTextDisabled: '#FFFFFF',
                    colorFillContentHover: '#FFFFFF',
                    colorFillSecondary: '#ffff',
                    colorFillTertiary: '#B0ADAB',
                    colorPrimaryBorderHover: '#3D3B38',
                    handleLineWidthHover: 0,
                    handleLineWidth: 0
                  }
                }
              }}
            >
              <Slider defaultValue={defaultValue} />
            </ConfigProvider>
          ) : null}
        </div>
        <div className="vc-description">
          {category ? (
            <Badge
              className={`badge-xs text-n-1000 ${tagBackground} ${tagTextColor}`}
            >
              {category}
            </Badge>
          ) : null}
          {collection ? (
            <Text className="text-small-kicker text-n-800  block">
              {collection}
            </Text>
          ) : null}

          {title ? (
            <Text className="text-small-sm-bold block mb-4">{title}</Text>
          ) : null}
          {description ? (
            <Paragraph
              className="text-small text-n-900  vc-paragraph"
              ellipsis={{ rows: 2 }}
            >
              {description}
            </Paragraph>
          ) : null}
          {tags?.length > 0 ? (
            <div className="tag-list mb-4">
              <Text className=" text-tiny text-n-800">{tags.join(', ')}</Text>
            </div>
          ) : null}

          {date ? (
            <Text className="text-small text-n-700 block ">{date}</Text>
          ) : null}
          {speaker ? (
            <Text className="text-small text-n-900 block">{speaker}</Text>
          ) : null}
        </div>
      </div>
    </ConfigProvider>
  );
}
export default PodcastCard;
