import React from 'react';

function PermissionCard({ type, title, active, onClick, disabled }) {
  return (
    <div
      className={`module-card ${active ? 'module-selected' : ''} ${
        disabled ? 'cursor-block' : ''
      }`}
      onClick={onClick}
    >
      {title && <p className="title text-center">{title}</p>}
      {type && <p className="type text-center">{type}</p>}
    </div>
  );
}
export default PermissionCard;
