import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import { Switch } from '../pages/component/pageModules/moduleForms/FormInputs';
import { CREATE_ADVERTISER, UPDATE_ADVERTISER } from './graphql/Mutations';
import { GET_ADVERTISER } from './graphql/Queries';

const PhoneInput = ({ value, onChange, placeholder, id }) => {
  const { number, code } = value;

  const handleChange = (val, { dialCode }) => {
    onChange({
      number: val?.replace(dialCode, ''),
      code: `+${dialCode}`
    });
  };

  return (
    <ReactPhoneInput
      inputProps={{ id }}
      placeholder={placeholder}
      value={`${code?.replace('+', '')}${number}`}
      onChange={handleChange}
    />
  );
};

const initialValues = {
  name: '',
  contactName: '',
  email: null,
  roles: [],
  contactNumber: {
    number: '',
    code: ''
  }
};

const AddEditAdvertiser = ({ history, location, match: { params } }) => {
  const [form] = Form?.useForm();
  const { filters } = location?.state || {};
  const { advertiserId } = params;
  const isEdit = !!advertiserId;
  const [buttonLoading, setButtonLoading] = useState(false);

  const addEditAdvertisersBreadcrumbs = [
    { label: MODULES?.LABELS },
    {
      label: MODULES?.ADVERTISERS,
      route: ROUTES?.LABELS_ADVERTISERS,
      addEditFilter: { filters }
    },
    advertiserId && { label: advertiserId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [getAdvertiser, { loading: fetchingDetails }] = useLazyQuery(
    GET_ADVERTISER,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [addUpdateAdvertiser, { loading }] = useMutation(
    isEdit ? UPDATE_ADVERTISER : CREATE_ADVERTISER
  );

  useEffect(() => {
    if (isEdit && !!advertiserId) {
      getAdvertiser({
        variables: {
          where: {
            id: advertiserId
          }
        }
      }).then(({ data, error }) => {
        if (!error && !!data) {
          form.setFieldsValue({
            name: data?.advertiserAdmin?.name ?? '',
            contactName: data?.advertiserAdmin?.contactName ?? '',
            email: data?.advertiserAdmin?.email ?? null,
            contactNumber: {
              number: data?.advertiserAdmin?.contactNumber ?? '',
              code: data?.advertiserAdmin?.countryCode ?? ''
            },
            isActive: !data?.advertiserAdmin?.disabledAt
          });
        }
      });
    }
  }, [isEdit, advertiserId, form]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleCancel = () => {
    history?.push(ROUTES?.LABELS_ADVERTISERS, {
      onAddEditFilters: filters
    });
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const payload = {
        name: values?.name,
        contactName: values?.contactName,
        email: !isEmpty(values?.email) ? values?.email : null,
        contactNumber: values?.contactNumber?.number,
        countryCode: values?.contactNumber?.code,
        ...(advertiserId && {
          isActive: !!values?.isActive
        })
      };

      addUpdateAdvertiser({
        variables: {
          data: payload,
          ...(isEdit && {
            where: {
              id: advertiserId
            }
          })
        }
      }).then(() => {
        history?.push(ROUTES?.LABELS_ADVERTISERS, {
          onAddEditFilters: filters
        });
      });
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  return (
    <>
      <PageHeader menu={addEditAdvertisersBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            initialValues={initialValues}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
            disabled={isViewOnly}
          >
            <Form.Item
              name="name"
              label="Name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.NAME)
              ]}
            >
              <Input
                placeholder="Enter name"
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[formValidatorRules?.email]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              name="contactName"
              label="Contact Name"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.NAME)]}
            >
              <Input
                placeholder="Enter contact name"
                disabled={isViewOnly || buttonLoading}
              />
            </Form.Item>
            <Form.Item
              label="Contact Number"
              name="contactNumber"
              rules={[
                formValidatorRules?.maxLength(
                  MAX_LENGTHS?.PHONE_NUMBER,
                  undefined,
                  (value) => value?.number?.length > MAX_LENGTHS?.PHONE_NUMBER
                )
              ]}
            >
              <PhoneInput placeholder="Enter contact number" />
            </Form.Item>
            {advertiserId && (
              <Form.Item name="isActive" valuePropName="checked">
                <Switch label="Active" />
              </Form.Item>
            )}
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}
                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditAdvertiser;
