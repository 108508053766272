import { Avatar, Button, ConfigProvider } from 'antd';
import React from 'react';
import webStyle from '../../../../styles/style-web.json';

function HeavyModule({
  heroModule,
  centerAlign,
  title,
  contributorImage,
  contributorName,
  contributorSubTitle,
  date,
  secondaryButton,
  primaryButton,
  cmsContent,
  responsive,
  backgroundImage
}) {
  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`heavy-module ${heroModule && 'heromodule'} ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        {heroModule && backgroundImage && (
          <div className="cover-img">
            <img src={backgroundImage} fill objectFit="cover" alt="images" />
          </div>
        )}
        <div className="container">
          <div className="heavy-module-container">
            {title && (
              <h2 className={`${centerAlign && 'text-center'}`}>{title}</h2>
            )}
            {contributorName && (
              <div className={`contributor ${centerAlign && 'justify-center'}`}>
                <Avatar src={contributorImage} size="small" />
                <div className="contributor-content">
                  <div className="contributor-name">{contributorName}</div>
                  <p> {contributorSubTitle}</p>
                </div>
              </div>
            )}
            {date && (
              <div className={`date mb-16 ${centerAlign && 'text-center'}`}>
                {date}
              </div>
            )}
            {cmsContent && <pre className="cms-content">{cmsContent}</pre>}
            <div
              className={`button-wrapper ${centerAlign && 'justify-center'}`}
            >
              {secondaryButton && (
                <Button
                  size="large"
                  className={`cs-button ${
                    heroModule
                      ? 'bg-transparent b-1 b-n-100 text-n-100 hb-n-100 hbg-n-100 hc-n-900'
                      : ' bg-n-transparent text-n-900 text-n-100 hbg-n-900 hc-n-100 hb-n-900'
                  }`}
                >
                  {secondaryButton}
                </Button>
              )}
              {primaryButton && (
                <Button
                  size="large"
                  className={`cs-button ${
                    heroModule
                      ? 'bg-n-100 text-n-900 hbg-transparent hc-n-100 hb-n-100'
                      : ' bg-n-900 text-n-100 hbg-ivory hb-n-900 hc-n-900'
                  }`}
                >
                  {primaryButton}
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default HeavyModule;
