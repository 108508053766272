import { gql } from '@apollo/client';

export const GET_SOURCES = gql`
  query sourcesAdmin(
    $filter: SourcesFilter
    $where: SourcesWhereInput
    $sort: SourcesSort
  ) {
    sourcesAdmin(filter: $filter, where: $where, sort: $sort) {
      sources {
        id
        articlesCount
        createdAt
        description
        image {
          blurhash
          id
          url
        }
        isActive
        name
        podcastsCount
        shortCode
        videosCount
      }
      count
    }
  }
`;

export const GET_SOURCE = gql`
  query SourceAdmin($where: SourceWhereUniqueInput!) {
    sourceAdmin(where: $where) {
      description
      id
      image {
        id
        url
      }
      isActive
      metaFooter
      metaHeader
      name
      shortCode
      slug
    }
  }
`;
