import { Button, Image } from 'antd';
import { ArrowsOut, Play } from 'phosphor-react';
import React from 'react';

function VideoInfoV2({ title, thumbnail }) {
  return (
    <section className="video-info">
      <div className="container">
        <div className="video-info-block">
          <div className="video-info-left">
            <div className="video-thumb-img">
              <Image src={thumbnail} preview={false} />
            </div>
            <div className="video-name">{title}</div>
          </div>
          <div className="video-info-right">
            <Button type="text" className="cs-button">
              <Play />
            </Button>
            <Button type="text" className="cs-button">
              <ArrowsOut />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default VideoInfoV2;
