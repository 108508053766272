import { Button } from 'antd';
import { isFunction } from 'lodash';
import { Trash } from 'phosphor-react';
import React from 'react';

function ModuleCard({
  type,
  title,
  active,
  onClick,
  disabled,
  showDelete,
  disableDelete,
  loadingDelete,
  onDelete
}) {
  const handleDelete = (e) => {
    e?.stopPropagation();

    if (isFunction(onDelete)) {
      onDelete();
    }
  };
  return (
    <div
      className={`module-card ${active ? 'module-selected' : ''} ${
        disabled ? 'cursor-block' : ''
      }`}
      onClick={onClick}
    >
      <p className="title">{title || 'Module Title'}</p>
      <p className="type">{type}</p>

      {showDelete && (
        <Button
          type="button"
          loading={loadingDelete}
          disabled={disableDelete}
          onClick={handleDelete}
        >
          <Trash size={18} weight="fill" />
        </Button>
      )}
    </div>
  );
}
export default ModuleCard;
