import React from 'react';
import Image from '../../../components/Image';

function FeaturedContributor({
  contributorName,
  contributorDescription,
  contributorImage,
  blurHash,
  responsive
}) {
  return (
    <section
      className={`featuredcontributor ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container-rnp">
        <div className="featuredcontributor-wrapper">
          <div className="featuredcontributor-image">
            {contributorImage ? (
              <Image
                blurHash={blurHash}
                src={contributorImage}
                alt={contributorName}
              />
            ) : null}
          </div>

          <div className="featuredcontributor-detail">
            <div className="featuredcontributor-detail-wrapper">
              {contributorName ? (
                <div className="featurecontributor-name">{contributorName}</div>
              ) : null}
              {contributorDescription ? <p>{contributorDescription}</p> : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FeaturedContributor;
