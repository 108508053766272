import { useEffect, useState } from 'react';

const useDebounce = (value, timeout = 500) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setState(value);
    }, timeout);

    return () => {
      clearTimeout(id);
    };
  }, [value, timeout]);

  return state;
};

export default useDebounce;
