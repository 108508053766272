import { gql } from '@apollo/client';

export const GET_PRAYERS = gql`
  query PrayersAdmin($filter: PrayersFilter, $sort: PrayersSort) {
    prayersAdmin(filter: $filter, sort: $sort) {
      count
      prayers {
        id
        description
        image {
          blurhash
          id
          url
        }
        slides {
          content
          id
          image {
            blurhash
            id
            url
          }
          title
        }
        title
        viewCount
      }
    }
  }
`;

export const GET_PRAYER = gql`
  query PrayerAdmin($where: PrayerWhereInput!) {
    prayerAdmin(where: $where) {
      allowedPlans {
        key
        expiredAt
      }
      autoPublishAt
      contributors {
        id
        firstName
        lastName
        primaryColor
        image {
          id
          title
          url
        }
      }
      description
      id
      image {
        id
        url
        title
      }
      permissions
      publishedAt
      slides {
        id
        title
        content
        image {
          id
          url
          title
        }
      }
      slug
      status
      tags {
        key
        id
        name
      }
      title
      topics {
        id
        key
        primaryColor
        name
      }
    }
  }
`;

export const GET_PRAYERS_FOR_MODULES = gql`
  query PrayersAdmin($filter: PrayersFilter) {
    prayersAdmin(filter: $filter) {
      count
      prayers {
        id
        title
        image {
          id
          url
        }
      }
    }
  }
`;
