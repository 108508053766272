import { useEffect, useState } from 'react';
import { staticApi } from '../common/utils';

const useStaticData = (key) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: responseData } = await staticApi?.get(`/${key}.json`);
        if (responseData) {
          setData(responseData);
        }
      } catch (err) {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, [key]);

  return { data, loading };
};

export default useStaticData;
