import React from 'react';

function FormLayout({ title, children }) {
  return (
    <div className="form-layout-section">
      <div className="module-title">{title}</div>
      <div className="form-layout">{children}</div>
    </div>
  );
}
export default FormLayout;
