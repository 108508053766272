import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react';
import {
  ASSET_CATEGORY,
  ASSET_CONTENT_TYPE,
  FORM_CTA_TYPES,
  GRID_TYPE,
  MAX_LENGTHS,
  MODULE_TYPES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  STATIC_PAGES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ExternalLinkRelInput from '../../../../../components/ExternalLinkRelInput';
import SelectAsset from '../../../../assets/components/SelectAsset';
import { GET_SLUGS } from '../../../../menus/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ACTION_TYPES } from './CTAForm';
import { ModuleFields, Permissions, ShowFields } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    description: true,
    itemImage: true,
    itemTitle: true,
    title: true,
    buttonSeparator: true,
    itemIcon: true
  },
  config: {
    items: [],
    type: GRID_TYPE.BUTTON
  }
};
const CTA_TITLE_MAX_LENGTH = 50;

const CONFIG_TITLE = {
  [MODULE_TYPES.CTA_GRID]: 'CTA Grid',
  [MODULE_TYPES.CTA_CAROUSEL]: 'CTA Carousel',
  [MODULE_TYPES.CTA_BUTTON_LIST]: 'CTA Button List'
};

const MODULE_KEYS = {
  [MODULE_TYPES.CTA_GRID]: 'ctaGridModule',
  [MODULE_TYPES.CTA_CAROUSEL]: 'ctaCarouselModule',
  [MODULE_TYPES.CTA_BUTTON_LIST]: 'ctaButtonListModule'
};

const MODULE_TYPE_KEYS = {
  [MODULE_TYPES.CTA_GRID]: 'ctaGridType',
  [MODULE_TYPES.CTA_CAROUSEL]: 'ctaCarouselType'
};

const GRID_TYPE_OPTIONS = [
  { label: 'Button', value: GRID_TYPE.BUTTON },
  { label: 'Card', value: GRID_TYPE.CARD }
];

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]
  },
  {
    name: 'itemIcon',
    label: 'Item Icon',
    allowedTypes: [MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]
  },
  {
    name: 'itemImage',
    label: 'Item Image',
    allowedTypes: [MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]
  },
  {
    name: 'itemTitle',
    label: 'Item Title',
    allowedTypes: [MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]
  },
  {
    name: 'buttonSeparator',
    label: 'Button Separator',
    allowedTypes: [MODULE_TYPES.CTA_BUTTON_LIST]
  }
];

const ACTION_TYPE_OPTIONS = [
  { label: 'Internal', value: ACTION_TYPES.INTERNAL },
  { label: 'External', value: ACTION_TYPES.EXTERNAL }
];

const ItemInput = ({ name, remove, form, fieldKey, type: moduleType }) => {
  const { internalPageType, type } =
    Form.useWatch(['config', 'items', fieldKey?.toString()], form) ?? {};

  return (
    <>
      <Space className="w-full" direction="vertical">
        <Form.Item
          label="Title"
          name={[name, 'title']}
          rules={[formValidatorRules?.maxLength(CTA_TITLE_MAX_LENGTH)]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        {moduleType !== MODULE_TYPES.CTA_BUTTON_LIST ? (
          <Form.Item label="Description" name={[name, 'description']}>
            <TextArea placeholder="Enter description" />
          </Form.Item>
        ) : (
          <Form.Item
            label="Button Type"
            name={[name, 'buttonType']}
            rules={[formValidatorRules?.maxLength(MAX_LENGTHS.CTA)]}
          >
            <AntdSelect options={FORM_CTA_TYPES} placeholder="Select page" />
          </Form.Item>
        )}
        <Form.Item label="Type" name={[name, 'type']}>
          <AntdSelect
            options={ACTION_TYPE_OPTIONS}
            onChange={() => {
              form.resetFields([
                ['config', 'items', name, 'internalPageType'],
                ['config', 'items', name, 'slug'],
                ['config', 'items', name, 'url']
              ]);
            }}
            placeholder="Enter Type"
          />
        </Form.Item>
        {type === ACTION_TYPES.INTERNAL && (
          <>
            <Form.Item label="Page Type" name={[name, 'internalPageType']}>
              <AntdSelect
                options={PAGE_TYPES_OPTIONS}
                placeholder="Select page type"
                onChange={() => {
                  form.resetFields([
                    ['config', 'items', name, 'slug'],
                    ['config', 'items', name, 'url']
                  ]);
                }}
              />
            </Form.Item>
            {internalPageType && (
              <>
                {internalPageType === PAGE_TYPES.STATIC ? (
                  <Form.Item label="Page" name={[name, 'slug']}>
                    <AntdSelect
                      options={STATIC_PAGES}
                      onChange={(value) => {
                        form.setFieldValue(
                          ['config', 'items', name, 'url'],
                          `${value}`
                        );
                      }}
                      placeholder="Select page"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item label="Page" name={[name, 'slug']}>
                    <Select
                      placeholder="Select"
                      query={GET_SLUGS}
                      variablesSelector={(filter) => ({
                        filter: {
                          ...filter,
                          pageType: internalPageType
                        }
                      })}
                      dataSelector={(data) => {
                        return (
                          data?.slugs?.slugs?.map(({ slug }) => ({
                            label: `/${slug}`,
                            value: slug
                          })) ?? []
                        );
                      }}
                      keys={{
                        data: 'slugs',
                        records: 'slugs',
                        count: 'count'
                      }}
                      onChange={({ value }) => {
                        form.setFieldValue(
                          ['config', 'items', name, 'url'],
                          `${value}`
                        );
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}

        <Form.Item label="URL" name={[name, 'url']}>
          <Input
            readOnly={type === ACTION_TYPES.INTERNAL}
            disabled={type === ACTION_TYPES.INTERNAL}
            placeholder="Enter url"
          />
        </Form.Item>
        {type === ACTION_TYPES.EXTERNAL && (
          <Form.Item name={[name, 'rel']} label="Relationship">
            <ExternalLinkRelInput />
          </Form.Item>
        )}
        {moduleType === MODULE_TYPES.CTA_BUTTON_LIST ? (
          <Form.Item label="Icon" name={[name, 'asset']}>
            <SelectAsset
              modalTitle="Select Icon"
              categoryKey={ASSET_CATEGORY?.ICON}
              btnText="Icon"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
              contentType={ASSET_CONTENT_TYPE.SVG}
            />
          </Form.Item>
        ) : (
          <Form.Item label="Images" name={[name, 'asset']}>
            <SelectAsset
              modalTitle="Select Image"
              categoryKey={ASSET_CATEGORY.IMAGE}
              btnText="Image"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
            />
          </Form.Item>
        )}
        {[MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]?.includes(
          moduleType
        ) && (
          <Form.Item label="Icon" name={[name, 'icon']}>
            <SelectAsset
              modalTitle="Select Icon"
              categoryKey={ASSET_CATEGORY?.ICON}
              btnText="Icon"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
              contentType={ASSET_CONTENT_TYPE.SVG}
            />
          </Form.Item>
        )}
      </Space>
      <Button
        className="text-btn mb-12"
        type="text"
        size="small"
        onClick={() => remove(name)}
      >
        Remove Item
      </Button>
    </>
  );
};

const CTAGridCarouselForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const hasButtonIcon = [MODULE_TYPES.CTA_BLOCK, MODULE_TYPES.HERO].includes(
    type
  );
  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          type: defaultValues?.moduleData?.config?.[MODULE_TYPE_KEYS[type]],
          items: defaultValues?.moduleData?.config?.items?.map((item) => ({
            asset: item?.asset,
            description: item?.description,
            internalPageType: item?.internalPageType,
            type: item?.type,
            title: item?.title,
            url: item?.url,
            icon: item?.icon,
            rel: item?.rel ?? null,
            ...(type === MODULE_TYPES.CTA_BUTTON_LIST && {
              buttonType: item?.buttonType
            }),
            ...(item?.type === ACTION_TYPES.INTERNAL && {
              ...(item?.internalPageType === PAGE_TYPES.STATIC
                ? { slug: item?.url }
                : {
                    slug: item?.url
                      ? {
                          label: item?.url,
                          value: item?.url
                        }
                      : null
                  })
            })
          }))
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    type,
    defaultValues,
    form,
    initialValues,
    hasButtonIcon
  ]);

  const handleSubmit = (values) => {
    const key = MODULE_KEYS[type];
    if (!key) return;
    const { config, permissions, settings, ...rest } = values ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          type: config?.type,
          items: config?.items?.map((item) => ({
            assetId: item?.asset?.id,
            description: item?.description,
            title: item?.title,
            internalPageType: item?.internalPageType,
            type: item?.type,
            url: item?.url,
            rel: item?.rel ?? null,
            ...(type === MODULE_TYPES.CTA_BUTTON_LIST && {
              buttonType: item?.buttonType
            }),
            ...([MODULE_TYPES.CTA_GRID, MODULE_TYPES.CTA_CAROUSEL]?.includes(
              type
            ) && {
              iconId: item?.icon?.id
            })
          }))
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <Space className="w-full" direction="vertical">
          <ShowFields settings={SETTINGS} type={type} />
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          {(type === MODULE_TYPES.CTA_GRID ||
            type === MODULE_TYPES.CTA_CAROUSEL) && (
            <Form.Item label="Grid type" name={['config', 'type']}>
              <AntdSelect
                placeholder="Select grid type"
                options={GRID_TYPE_OPTIONS}
              />
            </Form.Item>
          )}

          <Form.List name={['config', 'items']}>
            {(fields, { add, remove }) => (
              <>
                {fields?.map((field) => (
                  <ItemInput
                    form={form}
                    remove={remove}
                    key={field?.key}
                    type={type}
                    {...field}
                  />
                ))}
                <Form.Item>
                  <Button
                    className="text-btn"
                    type="text"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Items
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default CTAGridCarouselForm;
