import { Col, Collapse, ConfigProvider, Row } from 'antd';
import { filter, map } from 'lodash';
import { CaretDown, CaretUp } from 'phosphor-react';
import React from 'react';
import webStyle from '../../../../styles/style-web.json';

function FaqModule({ title, subTitle, responsive, data = [] }) {
  return (
    <ConfigProvider theme={webStyle}>
      <section className={`faq-section ${responsive ? 'responsive-view' : ''}`}>
        <div className="container">
          <div className="container-marketing">
            <div className="faq-section-title mb-32">
              {title && <div className=" title">{title}</div>}
              {subTitle && <p className=" subtitle">{subTitle}</p>}
            </div>
            <div className="cs-accordion">
              <Row gutter={[{ md: 32, xs: 0 }, 0]}>
                <Col xs={{ span: 24 }} md={{ span: responsive ? 24 : 12 }}>
                  <Collapse
                    accordion
                    expandIconPosition="end"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandIcon={({ isActive }) =>
                      isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
                    }
                    items={map(
                      filter(data, (_, index) => index % 2 === 0),
                      (item) => {
                        return {
                          key: item?.id,
                          label: item?.heading,
                          children: (
                            <div
                              className="m-0 paragraph-m"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: item.cmsContent
                              }}
                            />
                          )
                        };
                      }
                    )}
                  />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: responsive ? 24 : 12 }}>
                  <Collapse
                    accordion
                    expandIconPosition="end"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    expandIcon={({ isActive }) =>
                      isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
                    }
                    items={map(
                      filter(data, (_, index) => index % 2 !== 0),
                      (item) => {
                        return {
                          key: item?.id,
                          label: item?.heading,
                          children: (
                            <div
                              className="m-0 paragraph-m"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: item.cmsContent
                              }}
                            />
                          )
                        };
                      }
                    )}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default FaqModule;
