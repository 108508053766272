import { gql } from '@apollo/client';

export const CREATE_ASSET = gql`
  mutation createAsset($data: CreateAssetInput!) {
    createAsset(data: $data) {
      message
      asset {
        id
        title
        description
        categoryKey
        url
      }
    }
  }
`;

export const CREATE_VIDEO_ASSET = gql`
  mutation createVideoAsset($data: CreateVideoAssetInput!) {
    createVideoAsset(data: $data) {
      signedUrl
      uploadId
      asset {
        id
        title
        description
        categoryKey
        url
      }
    }
  }
`;

export const CREATE_AUDIO_ASSET = gql`
  mutation createAudioAsset($data: CreateAudioAssetInput!) {
    createAudioAsset(data: $data) {
      signedUrl
      uploadId
      asset {
        id
        title
        description
        categoryKey
        url
      }
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation updateAsset(
    $data: UpdateAssetInput!
    $where: AssetWhereUniqueInput!
  ) {
    updateAsset(data: $data, where: $where) {
      message
      asset {
        id
        title
        description
        categoryKey
        url
      }
    }
  }
`;

export const UPDATE_VIDEO_ASSET = gql`
  mutation updateVideoAsset(
    $data: UpdateVideoAssetInput!
    $where: AssetWhereUniqueInput!
  ) {
    updateVideoAsset(data: $data, where: $where) {
      message
      asset {
        id
        title
        description
        categoryKey
        url
      }
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation deleteAsset($where: AssetWhereUniqueInput!) {
    deleteAsset(where: $where) {
      message
    }
  }
`;

export const UPDATE_VIDEO_ASSET_STATUS = gql`
  mutation updateVideoAssetStatus(
    $peertubeUUID: ID!
    $id: ID!
    $platformId: ID!
  ) {
    updateVideoAssetStatus(
      where: { id: $id }
      data: { peertubeUUID: $peertubeUUID, platformId: $platformId }
    ) {
      message
    }
  }
`;

export const UPDATE_AUDIO_ASSET = gql`
  mutation updateAudioAsset(
    $data: UpdateAudioAssetInput!
    $where: AssetWhereUniqueInput!
  ) {
    updateAudioAsset(data: $data, where: $where) {
      message
      asset {
        id
        title
        description
        categoryKey
        url
      }
    }
  }
`;

export const UPDATE_AUDIO_ASSET_STATUS = gql`
  mutation updateAudioAssetStatus(
    $peertubeUUID: ID!
    $id: ID!
    $platformId: ID!
  ) {
    updateAudioAssetStatus(
      where: { id: $id }
      data: { peertubeUUID: $peertubeUUID, platformId: $platformId }
    ) {
      message
    }
  }
`;

export const IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET = gql`
  mutation importPlatformVideoToWorkspaceAsset(
    $where: WorkspacePlatformVideoUniqueWhereInput!
    $data: ImportPlatformVideoToAssetData!
  ) {
    importPlatformVideoToWorkspaceAsset(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_ASSET_STATUS = gql`
  mutation UpdateAssetStatus(
    $where: AssetWhereUniqueInput!
    $data: UpdateAssetStatusInput!
  ) {
    updateAssetStatus(where: $where, data: $data) {
      asset {
        id
      }
    }
  }
`;
