import { gql } from '@apollo/client';

export const CREATE_UI_LABEL = gql`
  mutation createUILabel($data: CreateUILabelInput!) {
    createUILabel(data: $data) {
      message
    }
  }
`;

export const UPDATE_UI_LABEL = gql`
  mutation updateUILabel($id: ID!, $data: UpdateUILabelInput!) {
    updateUILabel(where: { id: $id }, data: $data) {
      message
    }
  }
`;

export const DELETE_UI_LABEL = gql`
  mutation deleteUILabel($id: ID!) {
    deleteUILabel(where: { id: $id }) {
      message
    }
  }
`;

export const EXPORT_UI_LABELS = gql`
  mutation RequestUILabelsExport($where: RequestExportUILabelsWhereInput!) {
    requestUILabelsExport(where: $where) {
      jobId
      message
    }
  }
`;

export const CREATE_IMPORT_JOB = gql`
  mutation CreateImportJob($data: CreateImportJobInput!) {
    createImportJob(data: $data) {
      jobId
      message
    }
  }
`;
