import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import dayjs from 'dayjs';
import { isObject, map } from 'lodash';
import moment from 'moment-timezone';
import { CalendarBlank } from 'phosphor-react';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  DEFAULT_TIMEZONE,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  TIMEZONE_OPTIONS,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION,
  defaultDateFormatWithTime
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import AllowedPlansTable from '../../components/AllowedPlansTable';
import HtmlEditor from '../../components/HtmlEditor';
import PageHeader from '../../components/PageHeader';
import SeoInput, {
  defaultSeoValues,
  getSeoFormFields
} from '../../components/SeoInput';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import {
  Permissions,
  SelectSources,
  Switch
} from '../pages/component/pageModules/moduleForms/FormInputs';
import { Select, SelectContributor } from '../videos/components/FormInputs';
import { GET_TAGS, GET_TOPICS } from '../videos/graphql/Queries';
import { CREATE_PODCAST, UPDATE_PODCAST } from './graphql/Mutations';
import { GET_PODCAST } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  slug: '/',
  tags: [],
  topics: [],
  status: STATUS_TYPES.DRAFT,
  autoPublishAt: null,
  contentRating: '',
  speakers: [],
  sources: [],
  image: {
    ...initialAsset
  },
  imageThumbnail: {
    ...initialAsset
  },
  audioId: {
    ...initialAsset
  },
  metaHeader: '',
  metaFooter: '',
  descriptionHtml: '',
  permissions: [],
  timezone: DEFAULT_TIMEZONE,
  seo: defaultSeoValues
};

const TYPE_KEYS = {
  PODCAST: 'PODCAST',
  MUSIC: 'MUSIC'
};

const TYPE_OPTIONS = [
  { label: 'Podcast', value: TYPE_KEYS.PODCAST },
  { label: 'Music', value: TYPE_KEYS.MUSIC }
];

const AddEditPodcast = ({ history, location, match: { params } }) => {
  const [form] = Form.useForm();
  const { filters, listMode } = location?.state || {};
  const { podcastId } = params;
  const isEdit = !!podcastId;
  const type = Form.useWatch(['type'], form);

  const addEditPodcastBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    {
      label: MODULES?.PODCASTS,
      route: ROUTES?.CONTENTS_PODCASTS,
      addEditFilter: { filters, listMode }
    },
    podcastId && { label: podcastId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [fetchPodcastDetails, { loading: fetchingDetails }] = useLazyQuery(
    GET_PODCAST,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (isEdit && !!podcastId) {
      fetchPodcastDetails({
        variables: {
          where: {
            id: podcastId
          }
        }
      }).then((res) => {
        const podcast = res?.data?.podcastAdmin;
        if (podcast) {
          const slug = podcast?.slug?.startsWith('/')
            ? podcast?.slug
            : `/${podcast?.slug}`;
          form.setFieldsValue({
            title: podcast?.title ?? '',
            description: podcast?.description ?? '',
            slug,
            permissions:
              podcast?.permissions?.map((value) => ({
                label: value,
                value
              })) ?? [],
            tags:
              podcast?.tags?.map((tag) => ({
                label: tag?.name,
                value: tag?.id
              })) ?? [],
            topics:
              podcast?.topics?.length > 0
                ? map(podcast?.topics, (item) => {
                    return {
                      value: item?.id,
                      label: item?.name
                    };
                  })
                : [],

            status: podcast?.status ?? STATUS_TYPES.DRAFT,
            autoPublishAt: podcast?.autoPublishAt
              ? dayjs(podcast?.autoPublishAt)
              : null,
            contentRating: podcast?.contentRating ?? '',
            speakers:
              podcast?.speakers?.map((speaker) => ({
                id: speaker?.id ?? '',
                firstName: speaker?.firstName ?? '',
                lastName: speaker?.lastName ?? '',
                imageURL: speaker?.image?.url ?? '',
                color: speaker?.primaryColor ?? ''
              })) ?? [],
            sources:
              podcast?.sources?.map((source) => ({
                id: source?.id,
                title: source?.name,
                url: source?.image?.url
              })) || [],
            image: {
              id: podcast?.image?.id ?? '',
              url: podcast?.image?.url ?? ''
            },
            imageThumbnail: {
              id: podcast?.imageThumbnail?.id ?? '',
              url: podcast?.imageThumbnail?.url ?? ''
            },
            audioId: podcast?.audio
              ? {
                  id: podcast?.audio?.id,
                  url: podcast?.audio?.serviceImageThumbnail,
                  title: podcast?.audio?.title
                }
              : {},
            descriptionHtml: podcast?.descriptionHtml ?? '',
            metaHeader: podcast?.metaHeader ?? '',
            metaFooter: podcast?.metaFooter ?? '',
            allowedPlans:
              podcast?.allowedPlans?.map((item) => ({
                ...item,
                key: { label: item?.key, value: item?.key },
                expiredAt: item?.expiredAt ? dayjs(item?.expiredAt) : null
              })) ?? [],
            type: podcast?.type ?? null,
            meditation: podcast?.config?.meditation ?? false,
            timezone: DEFAULT_TIMEZONE,
            publishedAt: podcast?.publishedAt
              ? dayjs(podcast?.publishedAt)
              : null,
            seo: getSeoFormFields(podcast?.seo)
          });
        }
      });
    }
  }, [isEdit, podcastId, form, fetchPodcastDetails]);

  const [addUpdatePodcast, { loading }] = useMutation(
    isEdit ? UPDATE_PODCAST : CREATE_PODCAST
  );

  const handleSubmit = (data) => {
    const payload = {
      title: data?.title || '',
      description: data?.description || '',
      slug: data?.slug?.startsWith('/') ? data?.slug?.substring(1) : data?.slug,
      speakers:
        data?.speakers?.map((speaker, index) => {
          return {
            contributorId: speaker?.id,
            order: index + 1
          };
        }) ?? [],
      tags:
        data?.tags?.map((item, index) => {
          return {
            tagId: item?.value,
            order: index + 1
          };
        }) || [],
      topics:
        data?.topics?.length > 0
          ? map(data?.topics, (item, index) => {
              return {
                topicId: item?.value,
                order: index + 1
              };
            })
          : [],
      sources: data?.sources?.map(({ id }, index) => ({
        sourceId: id,
        order: index + 1
      })),
      status: data?.status,
      autoPublishAt:
        data?.autoPublishAt &&
        moment(dayjs(data?.autoPublishAt)?.format())
          .tz(data?.timezone)
          .format(),
      contentRating: data?.contentRating || '',
      imageId: data?.image?.id || null,
      imageThumbnailId: data?.imageThumbnail?.id || null,
      audioId: data?.audioId?.id,
      descriptionHtml: data?.descriptionHtml ?? '',
      metaHeader: data?.metaHeader || '',
      metaFooter: data?.metaFooter || '',
      permissions: data?.permissions?.map(({ value }) => value),
      allowedPlans: data?.allowedPlans?.map(({ key, expiredAt }) => ({
        key: isObject(key) ? key?.value : key,
        expiredAt: expiredAt ? dayjs(expiredAt) : null
      })),
      type: data?.type,
      ...(data?.type === TYPE_KEYS.MUSIC && {
        config: {
          meditation: data?.meditation ?? false
        }
      }),
      seo: data?.seo
    };

    addUpdatePodcast({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: podcastId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES?.CONTENTS_PODCASTS, {
        onAddEditFilters: filters,
        onAddEditListMode: listMode
      });
    });
  };

  const handleCancel = () => {
    history.replace(ROUTES?.CONTENTS_PODCASTS, {
      onAddEditFilters: filters,
      onAddEditListMode: listMode
    });
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <div>
      <PageHeader menu={addEditPodcastBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails}
          >
            <Row gutter={[16, 0]}>
              <Col md={24} lg={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title!'),
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select type!'
                    }
                  ]}
                >
                  <AntdSelect
                    options={TYPE_OPTIONS.map(({ label, value }) => ({
                      label,
                      value
                    }))}
                    placeholder="Select type"
                  />
                </Form.Item>
                {type === TYPE_KEYS.MUSIC && (
                  <Form.Item name="meditation" valuePropName="checked">
                    <Switch label="Meditation" />
                  </Form.Item>
                )}
                <Form.Item
                  label="Speakers"
                  name="speakers"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (value?.length < 1) {
                          throw new Error(
                            'Please select at least one speaker!'
                          );
                        }
                      }
                    }
                  ]}
                >
                  <SelectContributor
                    displayText="Speaker"
                    disabled={isViewOnly}
                    multiple
                  />
                </Form.Item>
                <Form.Item name="tags" label="Tags">
                  <Select
                    mode="multiple"
                    placeholder="Select tags"
                    query={GET_TAGS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.tagsAdmin?.tags?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? []
                    }
                    keys={{
                      data: 'tagsAdmin',
                      records: 'tags',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="topics"
                  label="Topic"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.length) {
                          throw new Error('Please select topic!');
                        }
                      }
                    }
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select topic"
                    query={GET_TOPICS}
                    variablesSelector={(filter) => ({ filter })}
                    dataSelector={(data) =>
                      data?.topicsAdmin?.topics?.map(({ id, name }) => ({
                        label: name,
                        value: id
                      })) ?? 0
                    }
                    keys={{
                      data: 'topicsAdmin',
                      records: 'topics',
                      count: 'count'
                    }}
                  />
                </Form.Item>
                <Form.Item name="sources" label="Sources">
                  <SelectSources />
                </Form.Item>
                <Form.Item label="Publish Status" name="status">
                  <AntdSelect
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select status"
                  />
                </Form.Item>
                <Form.Item label="Timezone" name="timezone">
                  <AntdSelect showSearch options={TIMEZONE_OPTIONS} disabled />
                </Form.Item>
                <Form.Item label="Published At" name="publishedAt">
                  <DatePicker
                    showTime
                    disabled
                    suffixIcon={<CalendarBlank size={20} />}
                    format={defaultDateFormatWithTime}
                  />
                </Form.Item>
                <Form.Item label="Auto Publish On" name="autoPublishAt">
                  <DatePicker
                    showTime
                    suffixIcon={<CalendarBlank size={20} />}
                    format={defaultDateFormatWithTime}
                  />
                </Form.Item>
                <Form.Item
                  name="contentRating"
                  label="Content Rating"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter content rating" />
                </Form.Item>
                <Form.Item
                  label="Audio"
                  name="audioId"
                  required
                  rules={[
                    {
                      async validator(_, value) {
                        if (!value?.id?.length) {
                          throw new Error('Please select audio!');
                        }
                      }
                    }
                  ]}
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Audio"
                    btnText="Audio"
                    categoryKey={ASSET_CATEGORY.AUDIO}
                    dataSelector={({ id, serviceImageThumbnail, title }) => ({
                      id,
                      title,
                      url: serviceImageThumbnail
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image"
                  name="image"
                  extra="Recommended size  (2500 * 1242)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label="Image Thumbnail"
                  name="imageThumbnail"
                  extra="Recommended size  (590 * 330)"
                >
                  <SelectAsset
                    disabled={isViewOnly}
                    modalTitle="Select Image Thumbnail"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Image Thumbnail"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>

                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>

                <Permissions />
                <fieldset className="mb-12">
                  <legend className="role-legend">Allowed Plans</legend>
                  <AllowedPlansTable
                    loading={fetchingDetails}
                    form={form}
                    formKey="allowedPlans"
                  />
                </fieldset>
                <SeoInput form={form} />
              </Col>
              <Col md={24} lg={12}>
                <Form.Item
                  className="full-html"
                  label="Html"
                  name="descriptionHtml"
                >
                  <HtmlEditor disabled={isViewOnly} className="full-html" />
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditPodcast;
