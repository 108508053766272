import { Typography } from 'antd';
import React, { useMemo } from 'react';

const Avatar = ({ src, firstName, lastName }) => {
  const avatar = useMemo(() => {
    if (src) return <img alt={firstName} src={src} />;

    return (
      <Typography.Text className="initials">
        {[firstName, lastName].filter(Boolean).map((name) => name[0])}
      </Typography.Text>
    );
  }, [src, firstName, lastName]);

  return (
    <>
      <div className="avatar">{avatar}</div>
    </>
  );
};

export default Avatar;
