import {
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined
} from '@ant-design/icons';
import { Button, Flex, Radio, Space, Spin, Tooltip, Typography } from 'antd';
import { ArrowSquareOut, Eye } from 'phosphor-react';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ModalComponent from '../../../../../components/ModalComponent';
import { useEditPage } from '../../../context/EditPageProvider';
import { usePagePreview } from '../../../context/PreviewPageProvider';

export const SIZES = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET'
};

export const SIZES_OPTIONS = [
  {
    label: 'Mobile',
    value: SIZES.MOBILE,
    icon: <MobileOutlined />,
    width: 425
  },
  {
    label: 'Tablet',
    value: SIZES.TABLET,
    icon: <TabletOutlined />,
    width: 768
  },
  {
    label: 'Desktop',
    value: SIZES.DESKTOP,
    icon: <DesktopOutlined />,
    width: '100vw'
  }
];

export const PreviewFrame = memo(
  forwardRef(({ onLoad, url, loading }, ref) => {
    if (!url && !loading) {
      return <p>Web page not found!</p>;
    }

    return (
      <iframe
        ref={ref}
        className="preview-frame"
        onLoad={onLoad}
        src={url}
        title="Preview"
      />
    );
  })
);
export const Preview = ({ allowChange = true }) => {
  const iframeRef = useRef();
  const [isFrameReady, setIsFrameReady] = useState(false);

  const {
    placeholderPreviewUrl,
    onPlaceholderPreviewLoad,
    loadingPlaceholderPreview
  } = usePagePreview();

  const { modules, form } = useEditPage();

  const currentModuleIndex = form?.index;

  const sendMessage = useCallback((type, value) => {
    const targetWindow = iframeRef.current?.contentWindow;

    if (!targetWindow) return;

    targetWindow?.postMessage(
      JSON.stringify({
        type,
        data: value
      }),
      '*'
    );
  }, []);

  useEffect(() => {
    const handleIframeReady = (event) => {
      if (event.data === 'ready') {
        setIsFrameReady(true);
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('message', handleIframeReady);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('message', handleIframeReady);
      setIsFrameReady(false);
    };
  }, []);

  useEffect(() => {
    if (
      !loadingPlaceholderPreview &&
      iframeRef.current &&
      allowChange &&
      isFrameReady
    ) {
      sendMessage('set-modules', modules);
    }
  }, [
    modules,
    loadingPlaceholderPreview,
    sendMessage,
    allowChange,
    isFrameReady
  ]);

  useEffect(() => {
    if (
      !loadingPlaceholderPreview &&
      iframeRef.current &&
      allowChange &&
      isFrameReady
    ) {
      sendMessage('current-module-index', currentModuleIndex);
    }
  }, [
    currentModuleIndex,
    loadingPlaceholderPreview,
    sendMessage,
    allowChange,
    isFrameReady
  ]);

  return (
    <PreviewFrame
      ref={iframeRef}
      loading={loadingPlaceholderPreview}
      onLoad={onPlaceholderPreviewLoad}
      url={placeholderPreviewUrl}
    />
  );
};

export const PreviewModalContent = ({ children, size, onSizeChange }) => {
  return (
    <Flex className="h-full" vertical gap={24}>
      <Flex align="center" justify="center">
        <Radio.Group
          className="custom-radio-group"
          size="small"
          onChange={(e) => onSizeChange(e?.target?.value)}
          value={size}
        >
          {SIZES_OPTIONS.map(({ value, icon }) => (
            <Radio.Button value={value} key={value}>
              {icon}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Flex>
      <div className="preview-frame-wrapper">{children}</div>
    </Flex>
  );
};

const PlaceholderPreviewModal = ({ open, onCancel }) => {
  const [size, setSize] = useState(SIZES.DESKTOP);
  const width = useMemo(
    () => SIZES_OPTIONS.find(({ value }) => value === size)?.width,
    [size]
  );
  const handleSizeChange = (value) => setSize(value);
  const { loadingPlaceholderPreview } = usePagePreview();

  return (
    <ModalComponent
      className="page-preview-modal"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={width}
    >
      <PreviewModalContent size={size} onSizeChange={handleSizeChange}>
        {loadingPlaceholderPreview && (
          <div className="loading">
            <Spin />
          </div>
        )}
        <Preview allowChange={open} />
      </PreviewModalContent>
    </ModalComponent>
  );
};

const PreviewModal = ({ open, onCancel }) => {
  const [size, setSize] = useState(SIZES.DESKTOP);
  const width = useMemo(
    () => SIZES_OPTIONS.find(({ value }) => value === size)?.width,
    [size]
  );
  const handleSizeChange = (value) => setSize(value);
  const { loading, onLoad, url } = usePagePreview();

  return (
    <ModalComponent
      className="page-preview-modal"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={width}
      destroyOnClose
    >
      <PreviewModalContent size={size} onSizeChange={handleSizeChange}>
        {loading && (
          <div className="loading">
            <Spin />
          </div>
        )}
        <PreviewFrame loading={loading} onLoad={onLoad} url={url} />
      </PreviewModalContent>
    </ModalComponent>
  );
};

const PreviewWrapper = () => {
  const { loadingPlaceholderPreview } = usePagePreview();
  const [showPlaceholderPreview, setShowPlaceholderPreview] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const handleShowPreview = () => setShowPreview(true);
  const handlePreviewClose = () => setShowPreview(false);
  const handleShowPlaceholderPreview = () => {
    setShowPlaceholderPreview(true);
  };
  const handlePlaceholderPreviewClose = () => setShowPlaceholderPreview(false);
  return (
    <>
      <PlaceholderPreviewModal
        open={showPlaceholderPreview}
        onCancel={handlePlaceholderPreviewClose}
      />
      <PreviewModal open={showPreview} onCancel={handlePreviewClose} />
      <Space className="d-flex mb-8 justify-between">
        <Typography.Text>Preview</Typography.Text>
        <Space align="center" size={0}>
          <Tooltip title="Preview">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<ArrowSquareOut size={22} />}
              onClick={handleShowPreview}
            />
          </Tooltip>
          <Tooltip
            title="Placeholder Data Preview"
            placement="topLeft"
            arrow={false}
          >
            <Button
              type="text"
              icon={<Eye size={20} />}
              onClick={handleShowPlaceholderPreview}
            />
          </Tooltip>
        </Space>
      </Space>
      <div className="preview-frame-wrapper">
        {loadingPlaceholderPreview && (
          <div className="loading">
            <Spin />
          </div>
        )}
        <Preview allowChange />
      </div>
    </>
  );
};

export default PreviewWrapper;
