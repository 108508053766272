import { gql } from '@apollo/client';

export const CREATE_ARTICLE = gql`
  mutation CreateArticle($data: CreateArticleInput!) {
    createArticle(data: $data) {
      message
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $where: ArticleWhereUniqueInput!
    $data: UpdateArticleInput!
  ) {
    updateArticle(where: $where, data: $data) {
      message
    }
  }
`;
