import { useMutation } from '@apollo/client';
import { Modal, Table, Typography } from 'antd';
import React from 'react';
import { VERIFY_APP_CUSTOM_DOMAIN } from '../../app/components/sidebar/graphql/Mutation';

const { Text } = Typography;

const DomainRecordsModal = ({
  data = [],
  openModal,
  setOpenModal,
  appId,
  refetch
}) => {
  const columns = [
    {
      title: 'Record type',
      dataIndex: 'type',
      ellipsis: true,
      render: (value) => {
        return <b className="module-name">{value}</b>;
      }
    },
    {
      title: 'Host',
      dataIndex: 'name',
      ellipsis: true,
      render: (value) => {
        return <b className="module-name">{value}</b>;
      }
    },
    {
      title: 'Value',
      dataIndex: 'value',
      ellipsis: true,
      width: '50%',
      render: (value) => {
        return (
          <b className="module-name">
            <Text title={value} copyable={{ text: value }}>
              {value}
            </Text>
          </b>
        );
      }
    }
  ];

  const [verifyAppCustomDomain, { loading }] = useMutation(
    VERIFY_APP_CUSTOM_DOMAIN,
    {
      onError() {}
    }
  );

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title="Domain records"
      width={850}
      okText="Verify"
      closable={false}
      onOk={() => {
        verifyAppCustomDomain({
          variables: {
            where: {
              id: appId
            }
          }
        }).then(() => {
          refetch({
            variables: {
              where: { id: appId }
            }
          });
          setOpenModal(false);
        });
      }}
      confirmLoading={loading}
    >
      <Table
        rowKey={(obj) => obj?.name}
        columns={columns}
        bordered={false}
        dataSource={data}
        pagination={false}
      />
    </Modal>
  );
};

export default DomainRecordsModal;
