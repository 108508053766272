import { gql } from '@apollo/client';

export const GET_ADMIN_USERS = gql`
  query AdminUsers($filter: AdminUsersFilter, $sort: AdminUsersSort) {
    adminUsers(filter: $filter, sort: $sort) {
      count
      users {
        id
        email
        firstName
        lastName
        isActive
        permissions
        roles
        profileImage
        createdAt
      }
    }
  }
`;

export const GET_ADMIN_USER = gql`
  query adminUser($where: AdminUserUniqueInput!) {
    adminUser(where: $where) {
      firstName
      lastName
      email
      permissions
      roles
      contactNumber
      countryCode
    }
  }
`;
