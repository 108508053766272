import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Flex, Tooltip } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import videoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  LIST_TYPES,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { getImageUrl } from '../../common/utils';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { DELETE_PRAYER } from './graphql/Mutations';
import { GET_PRAYERS } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ prayersAdmin }) => ({
  data: prayersAdmin?.prayers ?? [],
  count: prayersAdmin?.count ?? 0
});

const Actions = ({ id, isViewOnly, isDeleteAllowed, removeItem, filters }) => {
  const history = useHistory();

  const [deletePrayer] = useMutation(DELETE_PRAYER, {
    onError() {},
    onCompleted() {
      if (removeItem) {
        removeItem(id);
      }
    }
  });

  const handleEdit = () => {
    history.push(`${ROUTES?.CONTENTS_PRAYERS}/${id}/edit`, {
      filters
    });
  };

  const handleDelete = () => {
    if (isDeleteAllowed)
      modalContext?.confirm({
        title: 'Are you sure, you want to delete this slide?',
        centered: true,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          deletePrayer({
            variables: {
              where: { id }
            }
          });
        }
      });
  };

  return (
    <Flex align="center">
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Slide`}>
        <Button type="text" className="text-btn" onClick={handleEdit}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {isDeleteAllowed && (
        <Tooltip title="Delete Slide">
          <Button type="text" className="text-btn" onClick={handleDelete}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};

const columns = ({
  showPreview,
  isAddEditAllowed,
  isViewOnly,
  isDeleteAllowed,
  removeItem,
  filters
}) => [
  {
    title: 'Main Image',
    dataIndex: 'image',
    render: (value, { title }) => (
      <Image
        className="group-img-table"
        blurHash={value?.blurhash}
        src={
          getImageUrl(value?.url, { height: 50, width: 80 }) ?? videoPlaceholder
        }
        alt={title}
        onClick={() => {
          if (showPreview) {
            showPreview({
              title: value.title,
              url: value.url,
              type: ASSET_CATEGORY.IMAGE
            });
          }
        }}
      />
    ),
    width: 130
  },
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  {
    title: 'Views',
    dataIndex: 'viewCount',
    width: 100
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render(value) {
      return (
        <Actions
          id={value}
          isDeleteAllowed={isDeleteAllowed}
          isViewOnly={isViewOnly}
          isAddEditAllowed={isAddEditAllowed}
          removeItem={removeItem}
          filters={filters}
        />
      );
    },
    width: 130
  }
];

const Prayers = ({ history }) => {
  const filterProps = useFilterBar();

  const prayersBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    { label: MODULES?.PRAYERS }
  ].filter(Boolean);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const isLabelAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.CONTENTS_PRAYERS}/add`, {
      filters: filterProps?.filters
    });
  };

  return (
    <>
      <PageHeader
        menu={prayersBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed && isAssetAllowed && isLabelAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search by prayer name' }}
        show={{
          listModes: false
        }}
      />
      <PageList
        filters={filterProps.filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_PRAYERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isAddEditAllowed,
              isViewOnly,
              isDeleteAllowed,
              filters: filterProps?.filters
            })
        }}
      />
    </>
  );
};

export default Prayers;
