import { Flex } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Count({ icon, value, href }) {
  const text = (
    <>
      {icon} {value ?? 0}
    </>
  );

  return (
    <Flex className="count-wrapper" align="center" gap={4}>
      {href ? <Link to={href}>{text}</Link> : text}
    </Flex>
  );
}
