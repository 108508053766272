import { gql } from '@apollo/client';

export const CREATE_AD = gql`
  mutation CreateAd($data: CreateAdInput!) {
    createAd(data: $data) {
      message
    }
  }
`;

export const UPDATE_AD = gql`
  mutation UpdateAd($where: AdWhereInput!, $data: UpdateAdInput!) {
    updateAd(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_AD = gql`
  mutation DeleteAd($where: AdWhereInput!) {
    deleteAd(where: $where) {
      message
    }
  }
`;
