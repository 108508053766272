import { Input } from 'antd';
import React from 'react';

export const ColorPicker = ({ onChange, value, data }) => {
  return (
    <div className="d-flex">
      {data?.map(({ color, key }) => (
        <div
          key={key}
          // Used inline style as its dynamic value
          style={{
            backgroundColor: color
          }}
          className={`color-list ${value === key ? 'active-color' : ''}`}
          onClick={() => onChange(key)}
        />
      ))}
    </div>
  );
};

export const SlugInput = ({
  value,
  onChange,
  placeholder = 'Enter Slug',
  ...rest
}) => {
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        const newSlugValue = val.startsWith('/') ? val : `/${val}`;
        onChange(newSlugValue?.toLowerCase());
      }}
      {...rest}
    />
  );
};
