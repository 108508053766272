import { gql } from '@apollo/client';

export const CREATE_PRAYER = gql`
  mutation CreatePrayer($data: CreatePrayerInput!) {
    createPrayer(data: $data) {
      message
    }
  }
`;

export const UPDATE_PRAYER = gql`
  mutation UpdatePrayer($where: PrayerWhereInput!, $data: UpdatePrayerInput!) {
    updatePrayer(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_PRAYER = gql`
  mutation DeletePrayer($where: PrayerWhereInput!) {
    deletePrayer(where: $where) {
      message
    }
  }
`;
