import { Carousel } from 'antd';
import React from 'react';
import FeatureArticleCard from './FeatureArticleCard';

function ArticleCarousel({
  responsive,
  data = [],
  centerMode = true,
  sectionTitle,
  showIndicator,
  showCTA
}) {
  const responsivePadding = responsive ? '0' : '9%';
  const settings = {
    dots: !!responsive && !!showIndicator,
    arrows: !responsive,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    draggable: true,
    loop: true,
    centerPadding: centerMode ? responsivePadding : 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: '0',
          arrows: false,
          variableHeight: true,
          fade: true
        }
      }
    ]
  };
  return (
    <section
      className={`featured-carousel ${centerMode ? '' : 'center-false'} ${
        responsive ? 'responsive-view' : ''
      }`}
    >
      {sectionTitle ? (
        <div className="display-h4 mb-8">{sectionTitle}</div>
      ) : null}

      <Carousel {...settings}>
        {data?.map((item) => {
          return (
            <FeatureArticleCard
              key={item?.id}
              title={item?.title}
              topicName={item?.topicName}
              topicBackground={item?.topicBackground}
              heading={item?.heading}
              subHeading={item?.subHeading}
              date={item?.date}
              description={item?.description}
              tagData={item?.tagData}
              src={item?.src}
              author={item?.author}
              blurHash={item.blurHash}
              showCTA={showCTA}
              collections={item?.collections}
              commentCount={item?.commentCount}
              prayerCount={item?.prayerCount}
            />
          );
        })}
      </Carousel>
    </section>
  );
}
export default ArticleCarousel;
