import { gql } from '@apollo/client';

export const GET_COLLECTIONS = gql`
  query collectionsAdmin($filter: CollectionsFilter, $sort: CollectionsSort) {
    collectionsAdmin(filter: $filter, sort: $sort) {
      count
      collections {
        id
        title
        description
        itemsCount
        thumbnail {
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query collectionAdmin($id: ID!, $filter: CollectionItemsFilter) {
    collectionAdmin(where: { id: $id }) {
      id
      title
      description
      slug
      status
      primaryColor
      seo
      metaHeader
      metaFooter
      isSubCollection
      thumbnail {
        id
        url
      }
    }
    collectionItemsAdmin(where: { id: $id }, filter: $filter) {
      count
      collectionItems {
        type
        order
        sequence
        itemData {
          ... on Collection {
            id
            isSubCollection
            slug
            title
            status
            description
          }
          ... on Video {
            videoId: id
            title
            videoThumbnail {
              url
              id
            }
          }
          ... on Podcast {
            podcastId: id
            title
            imageThumbnail {
              url
              id
            }
          }
          ... on Article {
            articleId: id
            title
            imageThumbnail {
              id
              url
            }
          }
          ... on Form {
            formId: id
            title
          }
        }
        createdAt
      }
    }
  }
`;

export const GET_COLLECTION_ITEMS = gql`
  query CollectionItemsAdmin(
    $where: CollectionItemsUniqueInput!
    $filter: CollectionItemsFilter
  ) {
    collectionItemsAdmin(where: $where, filter: $filter) {
      count
      collectionItems {
        type
        order
        sequence
        itemData {
          ... on Collection {
            id
            isSubCollection
            slug
            title
            status
            description
          }
          ... on Video {
            videoId: id
            title
            videoThumbnail {
              url
              id
            }
          }
          ... on Podcast {
            podcastId: id
            title
            imageThumbnail {
              url
              id
            }
          }
          ... on Article {
            articleId: id
            title
            imageThumbnail {
              id
              url
            }
          }
          ... on Form {
            formId: id
            title
          }
        }
        createdAt
      }
    }
  }
`;
