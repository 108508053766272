import { Carousel } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import PodcastCard from './PodcastCard';

function PodcastCarousel({
  sectionHeading,
  headerLink,
  headerDescription,
  responsive,
  data = []
}) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    cssEase: 'linear',
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
    loop: true,
    arrows: !responsive,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section
      className={`video-carousel ${responsive ? 'responsive-view' : ' '}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel">
        <div className="w-full video-carousel shadow-arrow">
          <Carousel {...settings}>
            {data?.map((item) => {
              return (
                <div key={item?.id}>
                  <PodcastCard
                    src={item?.src}
                    blurHash={item.blurHash}
                    defaultValue={item?.defaultValue}
                    time={item?.time}
                    category={item?.category}
                    collection={item?.collection}
                    title={item?.title}
                    date={item?.date}
                    speaker={item?.speaker}
                    tags={item?.tags}
                    tagBackground={item?.tagBackground}
                    tagTextColor={item?.tagTextColor}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
export default PodcastCarousel;
