import { gql } from '@apollo/client';

export const GET_CONTACT_INQUIRY_KEYS = gql`
  query dataCollectionKeys {
    dataCollectionKeys(data: { type: CONTACT_INQUIRY })
  }
`;

export const GET_CONTACT_INQUIRIES = gql`
  query contactInquiries($filter: ContactInquiriesFilter) {
    contactInquiries(filters: $filter) {
      count
      contactInquiries {
        id
        email
        firstName
        lastName
        key
        inquiry
        status
      }
    }
  }
`;
