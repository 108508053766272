import { gql } from '@apollo/client';

export const GET_ADS = gql`
  query AdsAdmin($filter: AdsFilter, $sort: AdsSort) {
    adsAdmin(filter: $filter, sort: $sort) {
      count
      ads {
        id
        createdAt
        url
        permissions
        slug
        startDate
        title
        updatedAt
        endDate
        description
        image {
          id
          url
          serviceImageThumbnail
          title
          blurhash
        }
        contributors {
          id
          firstName
          lastName
          image {
            url
          }
          primaryColor
        }
        allowedPlans {
          key
          expiredAt
        }
        advertisers {
          id
          name
          email
        }
      }
    }
  }
`;

export const GET_AD = gql`
  query AdAdmin($where: AdWhereInput!) {
    adAdmin(where: $where) {
      id
      order
      permissions
      slug
      startDate
      title
      updatedAt
      url
      createdAt
      description
      endDate
      allowedPlans {
        key
        expiredAt
      }
      advertisers {
        id
        email
        name
      }
      contributors {
        id
        firstName
        lastName
        image {
          url
        }
      }
      config {
        mobile {
          layout
          images {
            type
            asset {
              id
              url
            }
          }
        }
        web {
          layout
          images {
            type
            asset {
              id
              url
            }
          }
        }
      }
    }
  }
`;
