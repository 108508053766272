import { gql } from '@apollo/client';

export const CREATE_ADVERTISER = gql`
  mutation CreateAdvertiser($data: CreateAdvertiserInput!) {
    createAdvertiser(data: $data) {
      message
    }
  }
`;

export const UPDATE_ADVERTISER = gql`
  mutation UpdateAdvertiser(
    $where: AdvertiserWhereInput!
    $data: UpdateAdvertiserInput!
  ) {
    updateAdvertiser(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_ADVERTISER = gql`
  mutation DeleteAdvertiser($where: AdvertiserWhereInput!) {
    deleteAdvertiser(where: $where) {
      message
    }
  }
`;
