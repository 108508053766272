import { gql } from '@apollo/client';

export const CREATE_SOURCE = gql`
  mutation CreateSource($data: CreateSourceInput!) {
    createSource(data: $data) {
      message
    }
  }
`;

export const UPDATE_SOURCE = gql`
  mutation UpdateSource(
    $where: SourceWhereUniqueInput!
    $data: UpdateSourceInput!
  ) {
    updateSource(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_SOURCE = gql`
  mutation DeleteSource($where: SourceWhereUniqueInput!) {
    deleteSource(where: $where) {
      message
    }
  }
`;
