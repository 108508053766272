import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowArticle } from '../../../assets/svg/arrow-article.svg';
import { ReactComponent as Star } from '../../../assets/svg/star.svg';

const ArticleListV2 = ({ sectionHeading, data = [] }) => {
  return (
    <section className="article-v2">
      <div className="container">
        {sectionHeading && <h2>{sectionHeading}</h2>}
        <ul>
          {data?.map((item) => {
            return (
              <li key={item?.id}>
                <Link
                  className="d-flex align-center justify-between gap-6"
                  href={item?.href}
                >
                  <div className="heading">
                    <Star fill="var(--tq-neutral-800)" /> {item?.title}
                  </div>
                  <ArrowArticle />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ArticleListV2;
