import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Select, Space, Tooltip } from 'antd';
import moment from 'moment';
import {
  ArrowClockwise,
  ArrowSquareOut,
  FileArrowUp,
  FileDotted
} from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApp } from '../../AppContext';
import {
  FULL_DATE_TIME_FORMAT,
  LIST_TYPES,
  MODULES,
  ORDER,
  PAGES_SORT_FIELD,
  PAGE_TYPE_LABELS,
  ROUTES,
  STATUS_TYPES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import PreviewModal from './component/pageModules/Preview/PreviewModal';
import {
  DELETE_PAGE,
  FORCE_PURGE_PAGE_CACHE,
  UPDATE_PAGE_STATUS
} from './graphql/Mutations';
import { GET_PAGES, GET_WORKSPACE_APP } from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  isDefault
}) => ({
  filter: {
    limit,
    skip: offset,
    search,
    isDefault
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ pagesAdmin }) => ({
  data: pagesAdmin?.pages || [],
  count: pagesAdmin?.count || 0
});

const PAGE_TYPE_OPTIONS = [
  { label: 'Custom', value: false },
  { label: 'Default', value: true }
];

const Action = ({
  status,
  id,
  refresh,
  removeItem,
  isDefault,
  allowDelete,
  slug,
  type,
  isDeleteAllowed,
  isAddEditAllowed,
  setPageId,
  filters
}) => {
  const [updatePageStatus] = useMutation(UPDATE_PAGE_STATUS);
  const [deletePage] = useMutation(DELETE_PAGE);
  const [purgePageCache, { loading: isPurging }] = useMutation(
    FORCE_PURGE_PAGE_CACHE
  );
  const newStatus =
    status === STATUS_TYPES.DRAFT ? STATUS_TYPES.PUBLISHED : STATUS_TYPES.DRAFT;

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const title = {
    [STATUS_TYPES.PUBLISHED]: 'Publish this page',
    [STATUS_TYPES.DRAFT]: 'Draft this page'
  }[newStatus];

  const Icon = {
    [STATUS_TYPES.PUBLISHED]: FileArrowUp,
    [STATUS_TYPES.DRAFT]: FileDotted
  }[newStatus];

  const isDeleteDisable =
    (isDefault || !allowDelete) && status === STATUS_TYPES.PUBLISHED;

  const handleDelete = () => {
    if (!isDeleteDisable)
      modalContext?.confirm({
        title: 'Are you sure, you want to delete this page?',
        centered: true,
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          deletePage({
            variables: { id }
          })
            .then(({ errors }) => {
              if (removeItem && !errors) {
                removeItem(id);
              }
            })
            .catch();
        }
      });
  };

  const handleShowPreview = (e, pageId) => {
    e.preventDefault();
    setPageId(pageId);
  };

  const handleUpdateStatus = (modalTitle) => {
    modalContext?.confirm({
      title: modalTitle,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',

      onOk() {
        updatePageStatus({
          variables: { id, status: newStatus }
        })
          .then(({ errors }) => {
            if (refresh && !errors) {
              refresh();
            }
          })
          .catch();
      }
    });
  };

  const forcePurge = () => {
    if (slug && type) {
      purgePageCache({
        variables: {
          slug,
          type
        }
      });
    }
  };

  const renderTitle = () => {
    if (status === STATUS_TYPES.PUBLISHED) return 'Draft this page to edit';
    return 'Edit page';
  };

  return (
    <div className="d-flex align-center">
      {isAddEditAllowed && (
        <>
          {!isDefault && (
            <Tooltip title="Open in web">
              <Button
                className="text-btn d-flex align-center justify-center"
                type="text"
                icon={<ArrowSquareOut size={22} />}
                disabled={status !== STATUS_TYPES.PUBLISHED}
                onClick={(e) => {
                  handleShowPreview(e, id);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title={title}>
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              onClick={() => {
                handleUpdateStatus(
                  `Are you sure, you want to ${title?.toLowerCase()}?`
                );
              }}
            >
              <Icon size={22} />
            </Button>
          </Tooltip>
          <Tooltip title="Reset Cache">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<ArrowClockwise size={22} />}
              onClick={forcePurge}
              loading={isPurging}
              disabled={isPurging || !slug}
            />
          </Tooltip>
        </>
      )}
      <Tooltip title="Preview">
        <Button
          className="text-btn d-flex align-center justify-center"
          type="text"
          onClick={(e) => {
            handleShowPreview(e, id);
          }}
        >
          <EyeOutlined size={20} />
        </Button>
      </Tooltip>
      {!isViewOnly && (
        <Tooltip title={renderTitle()}>
          <Link
            to={{
              pathname: `${ROUTES?.UI_CONFIG_PAGES}/${id}/edit`,
              state: {
                filters
              }
            }}
            disabled={status === STATUS_TYPES.PUBLISHED}
            className="text-btn black-btn"
            type="text"
            onClick={(e) => {
              if (status === STATUS_TYPES.PUBLISHED) {
                e.preventDefault();
              }
            }}
          >
            <EditOutlined />
          </Link>
        </Tooltip>
      )}

      {isDeleteAllowed && (
        <Tooltip
          title={
            isDeleteDisable ? 'You can not delete this page' : 'Delete page'
          }
        >
          <Button
            disabled={isDeleteDisable}
            className="text-btn"
            type="text"
            onClick={handleDelete}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export const Status = ({ status }) => {
  return (
    <div>
      {status === STATUS_TYPES.DRAFT && (
        <Badge className="status" count={status} color="#5E5957" />
      )}
      {status === STATUS_TYPES.PUBLISHED && (
        <Badge className="status" count={status} color="#52c41a" />
      )}
      {status === STATUS_TYPES.UNPUBLISHED && (
        <Badge className="status" count={status} />
      )}
    </div>
  );
};

const tableColumns = (
  { refresh, removeItem },
  isDefault,
  isAddEditAllowed,
  isDeleteAllowed,
  setPageId,
  webUrl,
  filters
) => [
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    width: '12%'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '12%',
    render: (text, record) => (
      <Space>
        <span>{PAGE_TYPE_LABELS[text]}</span>
        <span>
          <b>{record?.isDefault ? 'Default' : null}</b>
        </span>
      </Space>
    )
  },
  ...(!isDefault
    ? [
        {
          title: 'Slug',
          dataIndex: 'slug',
          key: 'slug'
        }
      ]
    : []),
  {
    title: 'Last modified',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '22%',
    render: (_, record) => (
      <span>{moment(record?.updatedAt).format(FULL_DATE_TIME_FORMAT)}</span>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '22%',
    render: (text, record) => <Status status={text} {...record} />
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    render: (_, record) => (
      <Action
        {...record}
        refresh={refresh}
        removeItem={removeItem}
        isDeleteAllowed={isDeleteAllowed}
        isAddEditAllowed={isAddEditAllowed}
        setPageId={setPageId}
        webUrl={webUrl}
        filters={filters}
      />
    )
  }
];

function Pages({ history, location }) {
  const { state } = useApp();
  const { onAddEditFilters } = location?.state || {};

  const pagesBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    { label: MODULES?.PAGES }
  ];

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const getInitialPageActive = () => {
    if (onAddEditFilters?.isDefault === true) {
      return true;
    }
    if (onAddEditFilters?.isDefault === false) {
      return false;
    }
    return false;
  };

  const [isDefault, setIsDefault] = useState(getInitialPageActive);

  const [pageId, setPageId] = useState(null);
  const [purgeAllPageCache, { loading: isPurging }] = useMutation(
    FORCE_PURGE_PAGE_CACHE
  );

  const { data } = useQuery(GET_WORKSPACE_APP, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: state?.app?.id
      }
    }
  });

  const webUrl = data
    ? data?.workspaceApp?.url || process.env.REACT_APP_WEB_URL
    : '';

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: PAGES_SORT_FIELD,
      order: ORDER[1].value,
      field: PAGES_SORT_FIELD[0].value
    }
  });

  const filters = useMemo(() => ({ ...filterProps.filters, isDefault }), [
    filterProps.filters,
    isDefault
  ]);

  const columns = useCallback(
    (props) =>
      tableColumns(
        props,
        isDefault,
        isAddEditAllowed,
        isDeleteAllowed,
        setPageId,
        webUrl,
        filters
      )?.filter((item) => item !== false),
    [isDefault, webUrl]
  );

  const handleShowModal = () => {
    history?.push(`${ROUTES?.UI_CONFIG_PAGES}/add`, {
      isUpdate: false,
      filters
    });
  };

  const handleIsDefaultChange = (val) => setIsDefault(val);

  const forcePurge = () => {
    purgeAllPageCache({
      variables: {
        all: true
      }
    });
  };

  const handleCancel = () => {
    setPageId(null);
  };

  return (
    <>
      <PreviewModal open={pageId} handleCancel={handleCancel} pageId={pageId} />
      <PageHeader
        menu={pagesBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Pages' }}
        show={{ listModes: false }}
      >
        <Select
          value={isDefault}
          options={PAGE_TYPE_OPTIONS}
          onChange={handleIsDefaultChange}
        />
        <Tooltip title="Force purge all page cache" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={forcePurge}
            loading={isPurging}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_PAGES}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={30}
      />
    </>
  );
}

export default Pages;
