import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Input, Space } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as urlSlug from 'url-slug';
import { GET_WORKSPACES_APPS } from '../../../app/components/sidebar/graphql/Queries';
import {
  CONFIG_METHOD_OPTIONS,
  CONFIG_METHOD_TYPES,
  MAX_LENGTHS,
  MODULES,
  PAGE_TYPES,
  PAGE_TYPES_OPTIONS,
  ROUTES
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import SeoInput, { defaultSeoValues } from '../../../components/SeoInput';
import history from '../../../historyData';
import { SlugInput } from '../../labels/topics/components/FormInputs';
import { GET_SLUGS } from '../../menus/graphql/Queries';
import { Select } from '../../videos/components/FormInputs';
import { CREATE_PAGE } from '../graphql/Mutations';
import { Permissions } from './pageModules/moduleForms/FormInputs';

const { required } = formValidatorRules;

const OPTIONS = PAGE_TYPES_OPTIONS.filter(
  ({ value }) => value !== PAGE_TYPES.STATIC
);

const variablesSelector = ({ limit, skip, search }) => ({
  filter: {
    limit,
    skip,
    search
  }
});

const initialValues = {
  apps: [],
  description: '',
  metaFooter: '',
  metaHeader: '',
  permissions: [],
  slug: '',
  title: '',
  type: PAGE_TYPES.CUSTOM,
  generationType: CONFIG_METHOD_TYPES.MANUAL,
  seo: defaultSeoValues
};

const AddPage = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { filters } = location?.state || {};

  const type = Form.useWatch('type', form) ?? PAGE_TYPES.CUSTOM;

  const addPageBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    {
      label: MODULES?.PAGES,
      route: ROUTES?.UI_CONFIG_PAGES,
      addEditFilter: { filters }
    },
    { label: 'Add' }
  ];
  const [buttonLoading, setButtonLoading] = useState(false);

  const [createPageMutate] = useMutation(CREATE_PAGE, {
    onError() {
      setButtonLoading(false);
    }
  });

  const handleCancel = () => {
    history?.replace(ROUTES?.UI_CONFIG_PAGES, {
      onAddEditFilters: filters
    });
  };

  const onFinish = async ({ apps, page: _page, ...values }) => {
    setButtonLoading(true);
    try {
      const formData = {
        ...values,
        permissions: values?.permissions?.map(({ value }) => value),
        slug: values.slug.startsWith('/')
          ? values.slug.substring(1)
          : values.slug,
        appIds: apps?.map(({ value }) => value)
      };
      const response = await createPageMutate({
        variables: {
          data: {
            ...formData
          }
        }
      });
      if (response?.data?.createPage) {
        setButtonLoading(false);

        history?.replace(
          `${ROUTES?.UI_CONFIG_PAGES}/${response?.data?.createPage?.page?.id}/edit`,
          {
            onAddEditFilters: filters
          }
        );
      }
    } catch (error) {
      setButtonLoading(false);
      return error;
    }
  };

  const handleNameChange = (e) => {
    if (type === PAGE_TYPES.CUSTOM) {
      form?.setFieldValue('slug', `/${urlSlug?.convert(e?.target?.value)}`);
    }
  };

  return (
    <>
      <PageHeader menu={addPageBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            name="create-page"
            layout="vertical"
            className="add-edit-form"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[
                formValidatorRules?.required('Please enter title!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input
                placeholder="Enter title"
                disabled={buttonLoading}
                onChange={handleNameChange}
              />
            </Form.Item>

            <Form.Item name="type" label="Type">
              <AntdSelect
                options={OPTIONS}
                onChange={() => {
                  form.resetFields(['slug', 'page']);
                }}
              />
            </Form.Item>

            {type === PAGE_TYPES.CUSTOM ? (
              <>
                <Form.Item label="Apps" name="apps">
                  <Select
                    multiple
                    placeholder="Select apps"
                    query={GET_WORKSPACES_APPS}
                    variablesSelector={variablesSelector}
                    dataSelector={(data) => {
                      return (
                        data?.workspaceApps?.workspaceApps?.map(
                          ({ id, name }) => ({
                            label: name,
                            value: id
                          })
                        ) ?? []
                      );
                    }}
                    keys={{
                      data: 'workspaceApps',
                      records: 'workspaceApps',
                      count: 'count'
                    }}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item label="Page" name="page">
                <Select
                  placeholder="Select"
                  query={GET_SLUGS}
                  variablesSelector={(filter) => ({
                    filter: {
                      ...filter,
                      pageType: type
                    }
                  })}
                  dataSelector={(data) => {
                    return (
                      data?.slugs?.slugs?.map(({ slug }) => ({
                        label: `/${slug}`,
                        value: slug
                      })) ?? []
                    );
                  }}
                  keys={{
                    data: 'slugs',
                    records: 'slugs',
                    count: 'count'
                  }}
                  onChange={({ value }) => {
                    form.setFieldValue(['slug'], `/${value}`);
                  }}
                />
              </Form.Item>
            )}

            <Form.Item
              name="slug"
              label="Page Slug"
              rules={[
                { required, message: 'Please enter slug!' },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>

            <Form.Item
              name="description"
              label="Page Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input placeholder="Enter description" disabled={buttonLoading} />
            </Form.Item>

            <Form.Item
              name="generationType"
              label="Generation Type"
              rules={[
                { required: true, message: 'Please select generation type!' }
              ]}
            >
              <AntdSelect
                options={CONFIG_METHOD_OPTIONS?.filter(
                  ({ value }) => value !== CONFIG_METHOD_TYPES.AUTO_GENERATED
                )}
                placeholder="Select method"
              />
            </Form.Item>

            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>

            <Permissions />

            <SeoInput form={form} />

            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={buttonLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={buttonLoading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddPage;
