import { Spin, Typography } from 'antd';
import React, { memo } from 'react';
import PermissionCard from './PermissionCard';

const { Title } = Typography;

const LeftPanelPermissions = ({
  permissions,
  moduleKey,
  setModuleKey,
  form,
  loading,
  permissionsLoading
}) => {
  const Module = memo(({ data, active }) => {
    const handleClick = () => {
      setModuleKey(data?.group);
      form?.resetFields();
    };

    return (
      <PermissionCard
        title={data?.group}
        active={active}
        onClick={handleClick}
        disabled={permissionsLoading}
      />
    );
  });

  return (
    <>
      <div className="module-wrapper">
        <div className="text-center mt-8">
          <Title level={3}>Modules</Title>
          {loading ? (
            <Spin spinning={loading} />
          ) : (
            permissions?.map((module) => (
              <React.Fragment key={module?.id}>
                <Module data={module} active={module?.group === moduleKey} />
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default LeftPanelPermissions;
