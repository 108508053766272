import { Col, Row } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CollectionCard from './CollectionCard';

function CollectionGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  responsive,
  data = [],
  collectionMd,
  collectionLg
}) {
  return (
    <section
      className={`collection-grid ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: responsive ? 8 : 16, lg: responsive ? 8 : 16 },
            {
              xs: 16,
              sm: 16,
              md: responsive ? 16 : 32,
              lg: responsive ? 16 : 32
            }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: responsive ? 12 : 8 }}
                lg={{ span: responsive ? 12 : 6 }}
                xxl={{ span: responsive ? 12 : 4 }}
              >
                <div className=" ">
                  <CollectionCard
                    href={item?.href}
                    videoCount={item?.videoCount}
                    title={item?.title}
                    description={item?.description}
                    coverImg={item?.coverImg}
                    blurHash={item?.blurHash}
                    collectionMd={collectionMd}
                    collectionLg={collectionLg}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
export default CollectionGrid;
