import { gql } from '@apollo/client';

export const CREATE_WORKSPACE_ROLE = gql`
  mutation CreateWorkspaceRole($data: CreateWorkspaceRoleInput!) {
    createWorkspaceRole(data: $data) {
      message
    }
  }
`;

export const UPDATE_WORKSPACE_ROLE = gql`
  mutation UpdateWorkspaceRole(
    $where: WorkspaceRoleUniqueInput!
    $data: UpdateWorkspaceRoleInput!
  ) {
    updateWorkspaceRole(where: $where, data: $data) {
      message
    }
  }
`;
