import { Button, ConfigProvider, Typography } from 'antd';
import React from 'react';
import Image from '../../../components/Image';
import webStyle from '../../../styles/style-web.json';

const { Title } = Typography;
function CtaCard({
  date,
  location,
  ctaMarketing = false,
  primary,
  secondary,
  contentlight,
  title,
  description,
  img,
  blurHash,
  background = 'bg-blue',
  responsive
}) {
  return (
    <ConfigProvider theme={webStyle}>
      <section>
        <div className="container-rnp">
          <div
            className={`ctacard  ${background} ${
              contentlight ? 'text-white-90' : 'text-black-90'
            } ${ctaMarketing && `ctaMarketing`} ${
              responsive ? 'responsive-view' : ''
            }`}
          >
            {img ? (
              <div className="ctaCard-img relative">
                <Image blurHash={blurHash} src={img} alt={title} />
              </div>
            ) : null}

            <div className="ctacard-dec ">
              {title ? (
                <Title
                  className={`${
                    contentlight ? 'text-white-90' : 'text-black-90'
                  }`}
                  level={3}
                >
                  {title}
                </Title>
              ) : null}

              {date && (
                <div
                  className={`date ${
                    contentlight ? 'text-white-90' : 'text-black-90'
                  }`}
                >
                  {date}
                </div>
              )}
              {location && (
                <div
                  className={`date ${
                    contentlight ? 'text-white-90' : 'text-black-90'
                  }`}
                >
                  {location}
                </div>
              )}

              {description ? (
                <p
                  className={`paragraph ${
                    contentlight ? 'text-white-90' : 'text-black-90'
                  }`}
                >
                  {description}
                </p>
              ) : null}

              <div className="cta-button-wrapper">
                {secondary ? (
                  <Button
                    className={`button-l cs-button b-0 b-solid ${
                      contentlight
                        ? 'bg-ivory text-n-900 hbg-n-200 text-n-900 hc-black-80 hb-ivory b-transparent hb-n-200'
                        : 'bg-ivory text-n-900 hbg-n-200 text-n-900 hc-black-80 hb-ivory b-transparent hb-n-200'
                    }`}
                    size="large"
                  >
                    {secondary}
                  </Button>
                ) : null}
                {primary ? (
                  <Button
                    className={`button-l  cs-button   ${
                      // eslint-disable-next-line no-nested-ternary
                      contentlight
                        ? ctaMarketing
                          ? 'bg-n-100 b-n-100 hbg-transparent hc-n-100 hb-n-100'
                          : 'bg-ivory text-n-900 hbg-neutral-300 text-n-900 hc-black-80 hb-ivory b-transparent'
                        : ' text-white-90 bg-n-900 hbg-n-800 hc-white-70 hb-n-800'
                    }`}
                    size="large"
                  >
                    {primary}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default CtaCard;
