import React from 'react';
import { Link } from 'react-router-dom';
import DefaultCollectionImg from '../../../assets/images/collection.png';
import Image from '../../../components/Image';

function CtaButtonTypeCard({
  href,
  grid,
  lending,
  topic,
  textColor = 'text-n-1000',
  responsive,
  onDelete,
  image,
  ...rest
}) {
  return (
    <Link
      className={`category-card  cta-btn-bg-black  ${textColor} ${
        grid ? 'category-grid' : ''
      } ${responsive ? 'responsive-view' : ''}`}
      to={href}
      {...rest}
    >
      <Image
        src={image || DefaultCollectionImg}
        className="cta-btn-image"
        alt={topic}
      />
      <div className="category-title">
        {topic ? <div className="topic">{topic}</div> : null}
      </div>
    </Link>
  );
}
export default CtaButtonTypeCard;
