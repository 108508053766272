import { Flex } from 'antd';
import React from 'react';

const ModerationTags = ({ data = [] }) => {
  return (
    <Flex className="mb-8" gap={4}>
      {data?.map(({ class: name, score }) => (
        <span className="moderation-tag danger" key={name}>
          {score * 100}% {name}
        </span>
      ))}
    </Flex>
  );
};

export default ModerationTags;
