import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditVideo from './AddEditVideo';
import ImportVideo from './ImportVideo';
import Videos from './Videos';

const VideosWrapper = () => (
  <Switch>
    <Route
      path={ROUTES?.ASSETS_VIDEOS}
      exact
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <Videos {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.ASSETS_VIDEOS}/import`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            },
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <ImportVideo {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.ASSETS_VIDEOS}/add`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            },
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddEditVideo {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.ASSETS_VIDEOS}/:id/edit`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            },
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddEditVideo {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.ASSETS_VIDEOS}/:id`}
      render={() => <Redirect to={ROUTES?.ASSETS_VIDEOS} />}
    />
  </Switch>
);

export default VideosWrapper;
