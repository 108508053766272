import { useMutation } from '@apollo/client';
import { Button, Form, Space } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  CONFIG_METHOD_TYPES,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions } from './FormInputs';

const MODULE_KEYS = {
  [MODULE_TYPES.FEATURED_AD_INSERT]: 'featuredAdInsertModule'
};

const getInitialValues = () => ({
  title: '',
  description: '',
  permissions: [],
  status: STATUS_TYPES.PUBLISHED
});

const AdForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const config = defaultValues?.moduleData?.config;
      const values = {
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        config
      };
      form.setFieldsValue(values);
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues, type]);

  const handleSubmit = (values) => {
    const { permissions, ...rest } = values ?? {};
    const key = MODULE_KEYS[type];
    if (!key) return;
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        config: {
          method: CONFIG_METHOD_TYPES.AUTO_GENERATED
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ModuleFields />
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </>
  );
};

export default AdForm;
