import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../common/constants';
import PageHeader from '../../../components/PageHeader';
import CommonVideoForm from './CommonVideoForm';

const AddEditVideo = () => {
  const params = useParams();
  const location = useLocation();
  const { filters, listMode } = location?.state || {};

  const addEditVideoBreadcrumbs = [
    { label: MODULES?.ASSETS },
    {
      label: MODULES?.VIDEOS,
      route: ROUTES?.ASSETS_VIDEOS,
      addEditFilter: { filters, listMode }
    },
    params?.id && { label: params?.id },
    { label: params?.id ? 'Edit' : 'Add' }
  ].filter(Boolean);

  return (
    <>
      <PageHeader menu={addEditVideoBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <CommonVideoForm />
        </div>
      </div>
    </>
  );
};

export default AddEditVideo;
