import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Space, Upload } from 'antd';
import { isArray, isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import { MODULES } from '../../common/constants';
import { fileUpload, formValidatorRules, getBase64 } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import PreviewModal from '../../components/PreviewModal';
import ProgressBar from '../../components/ProgressBar';
import ChangePasswordModal from '../auth/ChangePassword';
import { UPDATE_CURRENT_USER_ADMIN } from '../auth/graphql/Mutations';
import {
  GET_CURRENT_USER,
  GET_PROFILE_IMAGE_SIGNED_URL_ADMIN
} from '../auth/graphql/Queries';

const { required, name, email } = formValidatorRules;

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  profileImage: null
};

function Profile() {
  const { dispatch, initializeAuth, getToken } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [form] = Form?.useForm();
  const history = useHistory();
  const idToken = getToken();
  const profileImage = Form?.useWatch('profileImage', form);

  const profileBreadcrumbs = [{ label: MODULES?.PROFILE }];

  const [getSignedUrlAdmin] = useLazyQuery(GET_PROFILE_IMAGE_SIGNED_URL_ADMIN, {
    fetchPolicy: 'network-only'
  });
  const { data: userData, loading: fetchingDetails } = useQuery(
    GET_CURRENT_USER,
    {
      client: authClient,
      fetchPolicy: 'network-only',
      onCompleted: ({ currentUser }) => {
        form.setFieldsValue({
          firstName: currentUser?.firstName ?? '',
          lastName: currentUser?.lastName ?? '',
          email: currentUser?.email ?? '',
          profileImage: currentUser?.profileImage
            ? [
                {
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: currentUser?.profileImage
                }
              ]
            : []
        });
      }
    }
  );
  const showProgress = progress !== 0 && progress !== 100;

  const [updateCurrentUserAdmin] = useMutation(UPDATE_CURRENT_USER_ADMIN, {
    onCompleted: (res) => {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.updateCurrentUser?.data
      });
      initializeAuth(idToken, res?.updateCurrentUser?.data);
    },
    onError: () => {
      form?.setFieldsValue(userData);
      setLoading(false);
    }
  });

  const uploadButton = (
    <div>
      {loading && <LoadingOutlined />}
      <div className="mt-8">
        {!isEmpty(profileImage) ? 'Change' : 'Upload'} Photo
      </div>
    </div>
  );

  const handleBack = () => {
    history?.goBack();
  };

  const handleShowPrompt = () => {
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: true
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false
    });
    try {
      const payload = {
        firstName: values?.firstName?.trim(),
        lastName: values?.lastName?.trim()
      };
      if (values?.profileImage?.length) {
        if (values?.profileImage?.[0]?.originFileObj) {
          const file = values?.profileImage?.[0]?.originFileObj;

          const { data, error } = await getSignedUrlAdmin({
            variables: {
              data: {
                fileName: file?.name?.replace(/\s/g, '_'),
                contentType: file?.type
              }
            }
          });
          if (error) throw Error(error);
          if (data && data?.getAdminProfileImageUploadSignedUrl) {
            await fileUpload(
              data?.getAdminProfileImageUploadSignedUrl?.signedUrl,
              file,
              setProgress
            );
            payload.profileImage =
              data?.getAdminProfileImageUploadSignedUrl?.key;
          }
        }
      } else {
        payload.profileImage = '';
      }

      await updateCurrentUserAdmin({
        variables: {
          data: payload
        }
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleShowModal = () => {
    setVisible(true);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewImage('');
    setPreviewOpen(false);
  };

  return (
    <>
      <ChangePasswordModal visible={visible} setVisible={setVisible} />
      <PageHeader menu={profileBreadcrumbs} />
      <PreviewModal
        show={previewOpen}
        url={previewImage}
        onClose={handlePreviewClose}
      />
      <div className="ant-body-scroll assets-wrapper">
        <Form
          form={form}
          className="sticky-action-form add-edit-form"
          onFieldsChange={handleShowPrompt}
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          disabled={fetchingDetails}
        >
          <div className="card-body-wrapper">
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item
                  name="profileImage"
                  label="Profile photo"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    if (isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={() => false}
                    maxCount={1}
                    accept=".jpg,.jpeg,.png"
                    onPreview={handlePreview}
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[required('Please enter first name'), name]}
                >
                  <Input placeholder="Enter first name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[required('Please enter last name'), name]}
                >
                  <Input placeholder="Enter last name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item
                  name="email"
                  label="Email Id"
                  rules={[required('Please enter email'), email]}
                >
                  <Input disabled placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24} xl={24}>
                <Form.Item>
                  <Button onClick={handleShowModal}>
                    <LockOutlined /> Change Password
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
          {showProgress && (
            <Form.Item>
              <ProgressBar progress={progress} />
            </Form.Item>
          )}
          <Space>
            <Form.Item>
              <Button
                type="text"
                className="text-btn mr-8"
                loading={loading}
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="text"
                className="text-btn2"
                onClick={handleBack}
                disabled={loading}
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
          <Space />
        </Form>
      </div>
    </>
  );
}
export default Profile;
