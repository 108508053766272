import { Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { PreviewFrame, PreviewModalContent, SIZES, SIZES_OPTIONS } from '.';
import ModalComponent from '../../../../../components/ModalComponent';
import PreviewPageProvider, {
  PreviewPageContext
} from '../../../context/PreviewPageProvider';

const PreviewModal = ({ pageId, open = false, handleCancel }) => {
  const [size, setSize] = useState(SIZES.DESKTOP);
  const width = useMemo(
    () => SIZES_OPTIONS.find(({ value }) => value === size)?.width,
    [size]
  );
  const handleSizeChange = (value) => setSize(value);

  return (
    <ModalComponent
      className="page-preview-modal"
      destroyOnClose
      open={open}
      onCancel={handleCancel}
      footer={null}
      width={width}
    >
      <PreviewPageProvider pageId={pageId}>
        <PreviewPageContext.Consumer>
          {({ onLoad, loading, url }) => (
            <PreviewModalContent size={size} onSizeChange={handleSizeChange}>
              {loading && (
                <div className="loading">
                  <Spin />
                </div>
              )}
              <PreviewFrame loading={loading} onLoad={onLoad} url={url} />
            </PreviewModalContent>
          )}
        </PreviewPageContext.Consumer>
      </PreviewPageProvider>
    </ModalComponent>
  );
};

export default PreviewModal;
