import { gql } from '@apollo/client';

export const GET_ADVERTISERS = gql`
  query AdvertisersAdmin($filter: AdvertisersFilter, $sort: AdvertisersSort) {
    advertisersAdmin(filter: $filter, sort: $sort) {
      count
      advertisers {
        id
        email
        name
        updateAd
        contactNumber
        contactName
        countryCode
        disabledAt
      }
    }
  }
`;

export const GET_ADVERTISER = gql`
  query AdvertiserAdmin($where: AdvertiserWhereInput!) {
    advertiserAdmin(where: $where) {
      id
      name
      contactName
      countryCode
      contactNumber
      email
      disabledAt
      createdAt
      updateAd
    }
  }
`;
