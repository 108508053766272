import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { isArray, isObject } from 'lodash';
import React, { useMemo, useState } from 'react';
import { LIST_TYPES, MODULES } from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { GET_STATISTICS_DATA } from './graphql/Queries';

const { RangePicker } = DatePicker;

const STATISTIC_LABELS = {
  migratedUsers: 'Migrated Users',
  newContactInquiries: 'Contact Inquiries',
  newDonations: 'Donations',
  newTables: 'Conversations',
  newUsers: 'Users'
};

const variablesSelector = ({ after, before }) => ({
  filters: {
    dateRange: { after, before }
  }
});

const dataSelector = ({ statistics }) => {
  if (!isObject(statistics))
    return {
      data: [],
      count: 0
    };

  const data = Object.entries(statistics)
    .filter(([key]) => STATISTIC_LABELS[key])
    .map(([key, value]) => ({
      id: key,
      name: STATISTIC_LABELS[key],
      value
    }));

  return {
    data,
    count: data.length
  };
};

const columns = [
  {
    title: 'Title',
    dataIndex: 'name'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    width: '80%'
  }
];

const Dashboard = () => {
  const [range, setRange] = useState([dayjs().startOf('w'), dayjs()]);

  const dashboardBreadcrumbs = [{ label: MODULES?.DASHBOARD }];

  const filters = useMemo(() => {
    const dateRange = {
      before: null,
      after: null
    };

    if (isArray(range)) {
      const [startDate, endDate] = range;
      if (startDate) {
        dateRange.after = dayjs(startDate).startOf('d').format();
      }
      if (endDate) {
        dateRange.before = dayjs(endDate).endOf('d').format();
      }
    }

    return dateRange;
  }, [range]);

  const handleRangeChange = (value) => {
    setRange(value);
  };

  return (
    <>
      <PageHeader menu={dashboardBreadcrumbs} />
      <div className="assets-filter-wrapper">
        <div className="asset-filter-left" />
        <div className="asset-filter-right">
          <div className="asset-filter">
            <RangePicker
              disabledDate={(day) => dayjs().isBefore(day)}
              value={range}
              onChange={handleRangeChange}
            />
          </div>
        </div>
      </div>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_STATISTICS_DATA}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
      />
    </>
  );
};

export default Dashboard;
