import { useQuery } from '@apollo/client';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApp } from '../../../AppContext';
import { GET_WORKSPACE_APP } from '../graphql/Queries';

const ctx = createContext();
export const PreviewPageContext = ctx;

export const usePagePreview = () => useContext(ctx);

const PreviewPageProvider = ({ pageId, children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPlaceholderPreview, setLoadingPlaceholderPreview] = useState(
    true
  );

  const { id: pageParamId } = useParams();
  const { state } = useApp();
  const authToken = state?.authToken;
  const id = pageId || pageParamId;

  const { data, loading: loadingWorkspaceApp } = useQuery(GET_WORKSPACE_APP, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: state?.app?.id
      }
    },
    onCompleted(response) {
      if (!response?.workspaceApp?.url && !process.env.REACT_APP_WEB_URL) {
        setLoading(false);
        setLoadingPlaceholderPreview(false);
      }
    }
  });

  const webUrl = data
    ? data?.workspaceApp?.url || process.env.REACT_APP_WEB_URL
    : '';

  const appUUID = state?.app?.value;
  const workspaceUUID = state?.workspace?.value;
  const appId = state?.app?.id;
  const workspaceId = state?.workspace?.id;

  const defaultUrl =
    id &&
    authToken &&
    webUrl &&
    appUUID &&
    workspaceUUID &&
    appId &&
    workspaceId &&
    !loadingWorkspaceApp
      ? `${webUrl}`
      : null;

  const url = webUrl
    ? `${defaultUrl}/preview?pageId=${id}&token=${authToken}&appUUID=${appUUID}&workspaceUUID=${workspaceUUID}&appId=${appId}&workspaceId=${workspaceId}`
    : null;

  const placeholderPreviewUrl = webUrl
    ? `${defaultUrl}/placeholder-preview?appId=${appId}&workspaceId=${workspaceId}`
    : null;

  const onLoad = useCallback(() => {
    if (url) {
      setLoading(false);
    }
  }, [url]);

  const onPlaceholderPreviewLoad = useCallback(() => {
    if (placeholderPreviewUrl) {
      setLoadingPlaceholderPreview(false);
    }
  }, [url]);

  return (
    <ctx.Provider
      value={{
        onLoad,
        loading,
        url,
        placeholderPreviewUrl,
        loadingPlaceholderPreview,
        onPlaceholderPreviewLoad
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export default PreviewPageProvider;
