import { gql } from '@apollo/client';

export const GET_WORKSPACE_ROLES = gql`
  query WorkspaceRoles(
    $filter: WorkspaceRolesFilterInput
    $sort: WorkspaceRolesSortInput
  ) {
    workspaceRoles(filter: $filter, sort: $sort) {
      count
      workspaceRoles {
        key
        name
        id
        creator {
          id
          firstName
          lastName
          email
        }
        isDefault
        permissions {
          key
          level
        }
        createdAt
      }
    }
  }
`;

export const ROLES_PERMISSIONS = gql`
  query WorkspaceRolePermissions {
    workspaceRolePermissions {
      name
      key
      config
    }
  }
`;

export const GET_WORKSPACE_ROLE = gql`
  query WorkspaceRole($where: WorkspaceRoleUniqueInput!) {
    workspaceRole(where: $where) {
      id
      key
      name
      permissions {
        key
        level
      }
    }
  }
`;
