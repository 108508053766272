import { Collapse, Input, Space, Spin, Typography } from 'antd';
import {
  filter,
  groupBy,
  isEmpty,
  lowerCase,
  map,
  trim,
  uniqBy,
  uniqueId
} from 'lodash';
import { CaretDown, CaretUp, MagnifyingGlass } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { FORM_TYPES, useEditPage } from '../../context/EditPageProvider';

const AddModule = () => {
  const {
    pageModules,
    loadingPageModules: loading,
    fetchPageModules,
    addModule,
    setForm,
    setSelectionType,
    addIndex
  } = useEditPage();
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    fetchPageModules();
  }, []);

  const handleClick = (key) => {
    const data = {
      id: uniqueId(),
      moduleData: null,
      type: key
    };

    if (data) {
      setForm({ type: FORM_TYPES.ADD, index: addIndex });
      addModule(data);
      setSelectionType(data.type);
    }
  };

  const filteredModules = useMemo(() => {
    const getUpdatedModules = filter(pageModules, (item) => {
      if (lowerCase(item?.name)?.includes(lowerCase(trim(searchValue)))) {
        return item;
      }
    });
    return trim(searchValue) ? getUpdatedModules ?? [] : pageModules ?? [];
  }, [pageModules, searchValue]);

  const keys = map(uniqBy(pageModules, 'category'), ({ category }) => {
    return category;
  });

  return (
    <Space direction="vertical" size="large" className="w-full">
      <Input
        allowClear
        className="search-component"
        name="search"
        size="small"
        placeholder="Search module"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<MagnifyingGlass size={20} />}
      />
      <Typography.Text className="title">Add a module:</Typography.Text>
      {!loading && isEmpty(filteredModules) && <div>No module found</div>}
      {loading ? (
        <Spin />
      ) : (
        <Space className="module-list" direction="vertical">
          <Collapse
            {...(trim(searchValue) ? { activeKey: keys } : {})}
            className="module-collapse"
            ghost
            expandIcon={({ isActive }) =>
              isActive ? <CaretUp size={20} /> : <CaretDown size={20} />
            }
            items={map(groupBy(filteredModules, 'category'), (item, key) => {
              return {
                key,
                label: (
                  <Typography.Text
                    onClick={() => {
                      if (!item?.length) handleClick(item?.key);
                    }}
                    key={item?.key}
                  >
                    {key}
                  </Typography.Text>
                ),
                collapsible: 'header',
                children: map(item, ({ key: panelKey, name }) => (
                  <Typography.Text
                    onClick={() => handleClick(panelKey)}
                    key={panelKey}
                  >
                    {name}
                  </Typography.Text>
                ))
              };
            })}
          />
        </Space>
      )}
    </Space>
  );
};

export default AddModule;
