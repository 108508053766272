import React from 'react';
import useCheckPermission from '../hooks/useCheckPermission';
import NoAccess from './NoAccess';

const AccessControl = ({
  modulePermissions = [],
  children,
  showNoAccess = true,
  renderNoAccess = () => {
    return showNoAccess ? <NoAccess /> : null;
  }
}) => {
  const permitted = useCheckPermission(modulePermissions);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

export default AccessControl;
