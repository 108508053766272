import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_EXPORT_JOBS = gql`
  query exportJobs($filter: ExportJobsFilter!) {
    exportJobs(filter: $filter) {
      count
      data {
        id
        status
        filter
        fileUrl
        createdAt
      }
    }
  }
`;
