import { Button, ConfigProvider } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import webStyle from '../../../../styles/style-web.json';

function InformationBlock({
  title,
  description,
  imgSrc,
  reverse,
  background = 'bg-ivory',
  darkBackground,
  imgLink = '/',
  responsive,
  primary,
  secondary,
  size = 'LANDSCAPE'
}) {
  const renderSwitch = (param) => {
    switch (param) {
      case 'SQUARE':
        return 'square-img';
      case 'PORTRAIT':
        return 'portrait-img';

      default:
        return 'landscape-img';
    }
  };
  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`information-block ${background} ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        <div className="container">
          <div className={`information-wrapper ${reverse && 'info-rev'}`}>
            <div className="information-content">
              {title && (
                <h3
                  className={`display-h3 ${darkBackground ? 'text-n-0' : ''}`}
                >
                  {title}
                </h3>
              )}
              {description && (
                <p
                  className={`description  ${darkBackground ? 'text-n-0' : ''}`}
                >
                  {description}
                </p>
              )}
              <div className="button-wrapper">
                {secondary && (
                  <Button
                    className={`cs-button ${
                      darkBackground
                        ? 'b-n-100 bg-transparent text-n-100 hbg-n-900 hc-n-100 hb-n-900'
                        : 'bg-transparent hbg-n-900 hb-n-900 hc-n-100'
                    }`}
                  >
                    {secondary}
                  </Button>
                )}
                {primary && (
                  <Button
                    className={`cs-button  ${
                      darkBackground
                        ? 'bg-n-100 text-n-900 b-n-100 hbg-transparent hb-n-100 hc-n-100'
                        : 'bg-n-900 text-n-100 hbg-transparent hb-n-900 hc-n-900'
                    }`}
                  >
                    {primary}
                  </Button>
                )}
              </div>
            </div>
            {imgSrc && (
              <Link
                to={imgLink}
                className={`information-image relative ${renderSwitch(size)}`}
              >
                <img src={imgSrc} objectFit="cover" alt="information" />
              </Link>
            )}
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default InformationBlock;
