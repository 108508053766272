import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_DONATIONS = gql`
  query donationsAdmin($filter: DonationsAdminFilter, $sort: DonationsSort) {
    donationsAdmin(filter: $filter, sort: $sort) {
      count
      donations {
        id
        key
        user {
          firstName
          lastName
          profileImage
        }
        type
        transactionId
        amount
        paymentStatus
        subscriptionStatus
        subscriptionRenewalDate
        createdAt
      }
    }
  }
`;
