import { gql } from '@apollo/client';

export const CREATE_WORKSPACE_USER = gql`
  mutation createWorkspaceUser($data: CreateWorkspaceUserInput!) {
    createWorkspaceUser(data: $data) {
      message
    }
  }
`;

export const UPDATE_WORKSPACE_USER = gql`
  mutation updateWorkspaceUser(
    $data: UpdateWorkspaceUserInput!
    $where: WorkspaceUserUniqueInput!
  ) {
    updateWorkspaceUser(data: $data, where: $where) {
      message
    }
  }
`;

export const REQUEST_EXPORT_WORKSPACE_USERS = gql`
  mutation requestWorkspaceUsersExport(
    $filter: RequestWorkspaceUsersExportFilter
  ) {
    requestWorkspaceUsersExport(filter: $filter) {
      message
    }
  }
`;
