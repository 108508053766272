import { Col, Row } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import ArticleCard from './ArticleCard';

function ArticleList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  separator,
  responsive,
  showCTA
}) {
  return (
    <section
      className={`video-list-grid article-list-grid ${
        responsive ? 'responsive-view' : ''
      } ${!separator ? 'separatoroff' : ''}`}
    >
      {sectionHeading && (
        <div className="container">
          <SectionHeader
            heading={sectionHeading}
            headerLink={headerLink}
            headerDescription={headerDescription}
          />
        </div>
      )}

      <div className="container-rnp">
        <Row>
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <ArticleCard
                  videoList
                  src={item?.src}
                  defaultValue={item?.defaultValue}
                  time={item?.time}
                  category={item?.category}
                  collection={item?.collection}
                  title={item?.title}
                  date={item?.date}
                  speaker={item?.speaker}
                  tags={item?.tags}
                  tagBackground={item?.tagBackground}
                  tagTextColor={item?.tagTextColor}
                  blurHash={item?.blurHash}
                  description={item?.description}
                  showCTA={showCTA}
                  commentCount={item?.commentCount}
                  prayerCount={item?.prayerCount}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
export default ArticleList;
