import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import AddEditImage from './AddEditImage';
import Images from './Images';

const ImagesWrapper = () => (
  <Switch>
    <Route
      path={ROUTES?.ASSETS_IMAGES}
      exact
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <Images {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.ASSETS_IMAGES}/add`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            },
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddEditImage {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.ASSETS_IMAGES}/:id/edit`}
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            },
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PLATFORM_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddEditImage {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.ASSETS_IMAGES}/:id`}
      render={() => <Redirect to={ROUTES?.ASSETS_IMAGES} />}
    />
  </Switch>
);

export default ImagesWrapper;
