import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddEditLoop from './AddEditLoop';
import Loop from './Loop';

const LoopWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.LOOP} render={(props) => <Loop {...props} />} />
      <Route
        exact
        path={`${ROUTES.LOOP}/add`}
        render={(props) => <AddEditLoop {...props} />}
      />
      <Route
        exact
        path={`${ROUTES.LOOP}/:loopId/edit`}
        render={(props) => <AddEditLoop {...props} />}
      />
      <Route
        exact
        path={`${ROUTES.LOOP}/:loopId`}
        render={() => <Redirect to={ROUTES.LOOP} />}
      />
    </Switch>
  );
};

export default LoopWrapper;
