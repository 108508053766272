import { Carousel } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CtaButtonTypeCard from './CtaButtonTypeCard';
import { CTA_TYPE } from './CtaGrid';
import CtaGridCard from './CtaGridCard';

function CtaCarousel({
  sectionHeading,
  headerLink,
  headerDescription,
  collectionMd,
  collectionLg,
  responsive,
  data = [],
  displayType: type
}) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
    loop: true,
    arrows: !responsive,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToScroll: 1,
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section
      className={`collection-carousel ${responsive ? 'responsive-view' : ' '}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
      </div>
      <div className="one-side-carousel shadow-arrow">
        <Carousel {...settings}>
          {data?.map((item) => {
            return (
              <div key={item?.id}>
                {type === CTA_TYPE.CARD ? (
                  <CtaGridCard
                    href={item?.href}
                    videoCount={item?.videoCount}
                    title={item?.title}
                    description={item?.description}
                    coverImg={item?.img}
                    blurhash={item?.blurhash}
                    collectionMd={collectionMd}
                    collectionLg={collectionLg}
                  />
                ) : (
                  <CtaButtonTypeCard
                    key={item?.id}
                    list
                    href={item?.href}
                    topic={item?.title}
                    image={item?.img}
                    blurhash={item?.blurhash}
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
}
export default CtaCarousel;
