import { Select } from 'antd';
import React from 'react';

function ExternalLinkRelInput(props) {
  return (
    <Select
      {...props}
      placeholder="Select relationship"
      options={[
        { label: 'noopener', value: 'noopener' },
        { label: 'noreferrer', value: 'noreferrer' },
        { label: 'nofollow', value: 'nofollow' },
        { label: 'ugc (User Generated Content)', value: 'ugc' },
        { label: 'sponsored', value: 'sponsored' }
      ]}
    />
  );
}

export default ExternalLinkRelInput;
