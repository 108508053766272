import { useMutation, useQuery } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space
} from 'antd';
import { filter, map, some } from 'lodash';
import React, { useState } from 'react';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  REGEX,
  UI_LABEL_TYPES,
  UI_LABEL_TYPES_OPTIONS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoadEditor from '../../../components/LoadEditor';
import ModalComponent from '../../../components/ModalComponent';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SelectAsset from '../../assets/components/SelectAsset';
import { GET_LANGUAGES } from '../../assets/graphql/Queries';
import { CREATE_UI_LABEL } from '../../ui-labels/graphql/Mutations';

const initialValues = {
  type: UI_LABEL_TYPES.STRING,
  key: '',
  description: '',
  referenceImage: {
    id: '',
    url: ''
  },
  asset: null,
  languageArray: []
};

const AddPermissionModal = ({
  isPermissionModalOpen,
  setIsPermissionModalOpen,
  onCreate
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [form] = Form.useForm();
  const type = Form?.useWatch('type', form);

  const languageArrayValue = Form?.useWatch('languageArray', form);

  const { data: languagesData } = useQuery(GET_LANGUAGES, {
    fetchPolicy: 'network-only',
    onCompleted: ({ languages }) => {
      if (languages?.languages?.length > 0) {
        form?.setFieldsValue({
          languageArray: map(languages?.languages, (item) => {
            return {
              languageCode: { label: item?.name, value: item?.code },
              languageValue: ''
            };
          })
        });
      }
    }
  });

  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const [addUILabel, { loading }] = useMutation(CREATE_UI_LABEL);

  const hasAddedOneValue = some(
    languageArrayValue,
    (item) => item?.languageValue?.length
  );

  const assetInfo = {
    [UI_LABEL_TYPES.IMAGE]: {
      modalTitle: 'Select Image',
      categoryKey: ASSET_CATEGORY.IMAGE,
      btnText: 'Image',
      dataSelector: ({ id, url }) => ({
        id,
        url
      })
    },
    [UI_LABEL_TYPES.VIDEO]: {
      modalTitle: 'Select Video',
      categoryKey: ASSET_CATEGORY.VIDEO,
      btnText: 'Video',
      dataSelector: ({ id, serviceImageThumbnail, serviceVideoThumbnail }) => ({
        id,
        url: serviceVideoThumbnail || serviceImageThumbnail
      })
    }
  }[type];

  const handleSubmit = ({
    asset: _asset,
    type: uiLabelType,
    key,
    languageArray = [],
    languageValue,
    languageCode,
    ...rest
  }) => {
    if (!hasAddedOneValue) {
      setValidationTriggered(true);
      return;
    }
    addUILabel({
      variables: {
        data: {
          type: uiLabelType,
          key,
          value: map(
            filter(languageArray, (item) => item?.languageValue?.length),
            (item) => {
              return {
                languageCode: item?.languageCode?.value,
                value: item?.languageValue
              };
            }
          ),
          labelType: 'MESSAGE',
          ...rest
        }
      }
    }).then(() => {
      setIsPermissionModalOpen(false);
      form?.resetFields();
      onCreate();
    });
  };

  const handleCancel = () => {
    setIsPermissionModalOpen(false);
    form?.resetFields();
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <ModalComponent
      open={isPermissionModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        className="add-edit-form"
        layout="vertical"
        initialValues={initialValues}
        onFinishFailed={() => setValidationTriggered(true)}
        onFinish={handleSubmit}
        disabled={isViewOnly}
      >
        <Form.Item
          label="Key"
          name="key"
          required
          rules={[
            {
              validator(rule, value) {
                if (!value) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject('Please enter a key!');
                }
                if (!REGEX?.KEY_VALUE?.test(value)) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise?.reject('Should be a valid key');
                }
                return Promise?.resolve();
              }
            },
            // need to set 100 as per requirement
            formValidatorRules?.maxLength(100)
          ]}
        >
          <Input placeholder="Enter key" />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[
            {
              required: true,
              message: 'Please select type!'
            }
          ]}
        >
          <AntdSelect
            options={UI_LABEL_TYPES_OPTIONS}
            placeholder="Select type"
            onChange={() => {
              form?.setFieldsValue({
                languageArray: map(languageArrayValue, (item) => {
                  return {
                    ...item,
                    languageValue: ''
                  };
                }),
                asset: null
              });
            }}
          />
        </Form.Item>
        {type !== UI_LABEL_TYPES.STRING && assetInfo && (
          <Form.Item
            name="asset"
            rules={[
              {
                validator(rule, value) {
                  if (!value) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Please select asset!');
                  }
                  return Promise?.resolve();
                }
              }
            ]}
          >
            <SelectAsset
              allowClear={false}
              onChange={({ id }) => {
                if (id) {
                  form?.setFieldsValue({
                    languageArray: map(languageArrayValue, (item) => {
                      return {
                        ...item,
                        languageValue: id
                      };
                    })
                  });
                }
              }}
              isAssetEditAllowed={isAssetEditAllowed}
              isAssetViewAllowed={isAssetViewAllowed}
              {...assetInfo}
            />
          </Form.Item>
        )}
        <Form.List name="languageArray">
          {(fields) => (
            <Row gutter={[16, 0]}>
              {fields?.map((field) => (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={8}
                  key={field?.key}
                >
                  <Form.Item
                    label="Language Code"
                    hidden
                    name={[field?.name, 'languageCode']}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    required={!hasAddedOneValue}
                    label={`Value (${
                      languagesData?.languages?.languages?.[field?.key]?.name
                    })`}
                    name={[field?.name, 'languageValue']}
                    rules={
                      type === UI_LABEL_TYPES.HTML
                        ? []
                        : [
                            formValidatorRules?.maxLength(
                              MAX_LENGTHS.DESCRIPTION
                            ),
                            {
                              validator(rule, value) {
                                if (value?.length > 0 && !value.trim()) {
                                  return Promise?.reject(
                                    new Error('Please enter value!')
                                  );
                                }
                                return Promise?.resolve();
                              }
                            }
                          ]
                    }
                  >
                    {type === UI_LABEL_TYPES.HTML ? (
                      <LoadEditor disabled={isViewOnly} className="full-html" />
                    ) : (
                      <Input
                        placeholder="Enter value"
                        disabled={isViewOnly || type !== UI_LABEL_TYPES.STRING}
                        readOnly={type !== UI_LABEL_TYPES.STRING}
                      />
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          )}
        </Form.List>

        {type !== UI_LABEL_TYPES.STRING && assetInfo
          ? null
          : !hasAddedOneValue &&
            validationTriggered && (
              <span className="site-result-demo-error-icon">
                Please enter at least one language value!
              </span>
            )}
        <div className="d-flex button-section mb-8">
          <Space>
            {isAddEditAllowed && (
              <Button
                disabled={loading}
                loading={loading}
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
              >
                Save
              </Button>
            )}
            <Button
              disabled={loading}
              type="text"
              className="text-btn2"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Form>
    </ModalComponent>
  );
};

export default AddPermissionModal;
