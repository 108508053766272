import { Typography } from 'antd';
import { CaretRight } from 'phosphor-react';
import React from 'react';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;
function SectionHeader({ heading, headerLink, headerDescription }) {
  return (
    <>
      <div className="section-header">
        <div className="sh-top mb-4">
          {heading ? (
            <div className="sh-title text-black-90">{heading}</div>
          ) : null}
          <span className="flex-1" />
          {headerLink ? (
            <Link
              className="d-flex align-center text-black-90 button-m"
              to={headerLink}
            >
              ALL <CaretRight size={16} />
            </Link>
          ) : null}
        </div>
        {headerDescription ? (
          <Paragraph
            className="text-small text-black-80 vc-paragraph mb-16"
            ellipsis={{ rows: 2 }}
          >
            {headerDescription}
          </Paragraph>
        ) : null}
      </div>
    </>
  );
}
export default SectionHeader;
