import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditRole from './AddEditRole';
import Roles from './Roles';

const RolesWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.WS_ROLES}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Roles {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.WS_ROLES}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditRole {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.WS_ROLES}/:roleId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditRole {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.WS_ROLES}/:roleId`}
        render={() => <Redirect to={ROUTES?.WS_ROLES} />}
      />
    </Switch>
  );
};

export default RolesWrapper;
