import { gql } from '@apollo/client';

export const CREATE_TOPIC = gql`
  mutation createTopic($data: TopicCreateInput!) {
    createTopic(data: $data) {
      message
    }
  }
`;

export const UPDATE_TOPIC = gql`
  mutation updateTopic($data: TopicUpdateInput!, $id: ID!) {
    updateTopic(data: $data, where: { id: $id }) {
      message
    }
  }
`;

export const DELETE_TOPIC = gql`
  mutation deleteTopic($id: ID!) {
    deleteTopic(where: { id: $id }) {
      message
    }
  }
`;
