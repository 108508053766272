import { Col, Row } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import ContributorCard from './ContributorCard';

function ContributorGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive
}) {
  return (
    <section
      className={`contributorgrid ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            {
              xs: 8,
              sm: responsive ? 8 : 16,
              md: responsive ? 8 : 16,
              lg: responsive ? 8 : 16
            },
            {
              xs: 16,
              sm: 16,
              md: responsive ? 16 : 32,
              lg: responsive ? 16 : 32
            }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: responsive ? 12 : 8 }}
                lg={{ span: responsive ? 12 : 6 }}
                xxl={{ span: responsive ? 12 : 6 }}
              >
                <div className=" ">
                  <ContributorCard
                    href={item?.href}
                    contributor={item?.contributor}
                    videoCount={item?.videoCount}
                    image={item?.url}
                    blurHash={item.blurHash}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
export default ContributorGrid;
