import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Select, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { CurrencyCircleDollar, Export } from 'phosphor-react';
import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ResetPasswordIcon } from '../../assets/svg/resetPassword.svg';

import {
  DATE_FORMAT,
  LIST_TYPES,
  MODULES,
  ROUTES,
  USERS_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import ResetPasswordModal from './ResetPasswordModal';
import {
  CLEAR_SUBSCRIPTION_HISTORY,
  REQUEST_EXPORT_USERS
} from './graphql/Mutations';
import { GET_USERS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.users?.users ?? [],
  count: data?.users?.count ?? 0
});

const variablesSelector = ({ offset, sortField, sortOrder, ...rest }) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const Action = ({
  id,
  setResetPasswordModal,
  setEmail,
  email,
  isAddEditAllowed,
  handleClearSubscriptionHistory,
  filters
}) => {
  const history = useHistory();
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleEdit = () => {
    history.push(`${ROUTES?.USERS}/${id}/edit`, {
      filters
    });
  };

  return (
    <div className="d-flex align-center">
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} User`}>
        <Button type="text" className="text-btn" onClick={handleEdit}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {isAddEditAllowed && (
        <div className="d-flex align-center">
          <Tooltip title="Reset Password">
            <Button
              type="text"
              className="text-btn"
              onClick={() => {
                setResetPasswordModal(true);
                setEmail(email);
              }}
            >
              <ResetPasswordIcon width={28} height={25} className="mb-4" />
            </Button>
          </Tooltip>
          {process.env?.REACT_APP_ENV !== 'production' && (
            <Tooltip title="Clear Purchase History">
              <Button
                type="text"
                className="text-btn"
                onClick={() => {
                  handleClearSubscriptionHistory(id);
                }}
              >
                <CurrencyCircleDollar width={28} height={25} />
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

const columns = ({
  isAddEditAllowed,
  setResetPasswordModal,
  setEmail,
  handleClearSubscriptionHistory,
  filters
}) => {
  return [
    {
      title: 'UID',
      dataIndex: 'uid',
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'User',
      dataIndex: 'profileImage',
      width: '18%',
      render: (_, { firstName, lastName, profileImage }) => (
        <div className="d-flex align-center">
          <Avatar
            className="mr-8"
            src={profileImage}
            firstName={firstName}
            lastName={lastName}
          />
          <p className="m-0 ml-8">
            {firstName} {lastName}
          </p>
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: '8%',
      ellipsis: true,
      render: (value) => (value ? 'Active' : 'Inactive')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '18%',
      render: (value) => <a href={`mailto:${value}`}>{value}</a>
    },
    {
      title: 'Effective Plan',
      dataIndex: 'effectivePlan',
      width: '18%'
    },
    {
      title: 'Joined',
      dataIndex: 'createdAt',
      render: (value) => moment(value).format(DATE_FORMAT)
    },
    {
      title: 'Attributes',
      dataIndex: 'permissions',
      render: (value) => (!isEmpty(value) ? value?.join(',') : '-')
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (value, record) => (
        <Action
          id={value}
          email={record?.email}
          setResetPasswordModal={setResetPasswordModal}
          setEmail={setEmail}
          isAddEditAllowed={isAddEditAllowed}
          handleClearSubscriptionHistory={handleClearSubscriptionHistory}
          filters={filters}
        />
      )
    }
  ];
};

const USER_TYPES = {
  ALL: 'ALL',
  LEGACY: 'LEGACY',
  NON_LEGACY: 'NON_LEGACY',
  MIGRATED: 'MIGRATED',
  NOT_MIGRATED: 'NOT_MIGRATED'
};

const USER_TYPE_FILTERS = {
  [USER_TYPES.LEGACY]: { legacyUsers: true },
  [USER_TYPES.NON_LEGACY]: { legacyUsers: false },
  [USER_TYPES.MIGRATED]: { legacyUsers: true, migratedAccount: true },
  [USER_TYPES.NOT_MIGRATED]: { legacyUsers: true, migratedAccount: false }
};

const USER_TYPES_OPTIONS = [
  {
    label: 'All',
    value: USER_TYPES.ALL
  },
  {
    label: 'Legacy',
    value: USER_TYPES.LEGACY
  },
  {
    label: 'Non Legacy',
    value: USER_TYPES.NON_LEGACY
  },
  {
    label: 'Migrated',
    value: USER_TYPES.MIGRATED
  },
  {
    label: 'Not Migrated',
    value: USER_TYPES.NOT_MIGRATED
  }
];

const Users = ({ history, location }) => {
  const ref = useRef();
  const { onAddEditFilters } = location?.state || {};

  const getInitialUserActive = () => {
    if (onAddEditFilters?.legacyUsers === true) {
      return USER_TYPES.LEGACY;
    }
    if (onAddEditFilters?.legacyUsers === false) {
      return USER_TYPES.NON_LEGACY;
    }
    if (
      onAddEditFilters?.legacyUsers === true &&
      onAddEditFilters?.migratedAccount === true
    ) {
      return USER_TYPES.MIGRATED;
    }
    if (
      onAddEditFilters?.legacyUsers === true &&
      onAddEditFilters?.migratedAccount === false
    ) {
      return USER_TYPES.NOT_MIGRATED;
    }
    return USER_TYPES.ALL;
  };

  const [type, setType] = useState(getInitialUserActive || USER_TYPES.ALL);
  const [email, setEmail] = useState(null);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const usersBreadcrumbs = [{ label: MODULES?.USERS }];

  const [clearSubscriptionHistory] = useMutation(CLEAR_SUBSCRIPTION_HISTORY, {
    onError() {}
  });

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isExportAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: USERS_SORT_FIELD,
      field: USERS_SORT_FIELD[0].value
    }
  });

  const [exportUsers, { loading: isExporting }] = useMutation(
    REQUEST_EXPORT_USERS
  );

  const filters = useMemo(
    () => ({ ...filterProps.filters, ...USER_TYPE_FILTERS[type] }),
    [filterProps.filters, type]
  );

  const handleShowModal = () => {
    history.push(`${ROUTES?.USERS}/add`, {
      filters
    });
  };

  const handleTypeChange = (value) => setType(value);

  const handleClearSubscriptionHistory = async (id) => {
    modalContext?.confirm({
      title: 'Are you sure, you want to clear subscription history?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      async onOk() {
        clearSubscriptionHistory({
          variables: { where: { userId: id } }
        }).catch();
      }
    });
  };

  const handleExport = () => {
    const {
      sortField: _sortField,
      sortOrder: _sortOrder,
      ...payload
    } = filters;

    exportUsers({
      variables: {
        filter: payload
      }
    });
  };

  return (
    <>
      {resetPasswordModal && (
        <ResetPasswordModal
          openModal={resetPasswordModal}
          setEmail={setEmail}
          email={email}
          setOpenModal={setResetPasswordModal}
        />
      )}
      <PageHeader
        menu={usersBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Users' }}
      >
        <Select
          value={type}
          popupMatchSelectWidth={false}
          placeholder="Select type"
          options={USER_TYPES_OPTIONS}
          onChange={handleTypeChange}
        />
        {isExportAllowed && (
          <Tooltip title="Export Users" placement="left">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<Export size={24} />}
              onClick={handleExport}
              loading={isExporting}
            />
          </Tooltip>
        )}
      </FilterBar>
      <PageList
        ref={ref}
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_USERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isAddEditAllowed,
              setResetPasswordModal,
              setEmail,
              handleClearSubscriptionHistory,
              filters
            })?.filter((item) => item !== false)
        }}
        limit={20}
      />
    </>
  );
};

export default Users;
