import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React from 'react';
import { normalizeNegativeNumber } from '../../common/utils';

const OptionsModal = ({ open, name, onClose }) => {
  return (
    <Modal
      open={open}
      centered
      title={<p>Add Options</p>}
      onCancel={onClose}
      footer={
        <Button
          type="text"
          htmlType="submit"
          className="text-btn mr-8 d-flex justify-end align-center"
          size="middle"
          onClick={onClose}
        >
          Save
        </Button>
      }
      width={800}
    >
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <div>
            <Row gutter={[16, 0]}>
              {fields?.map((field, index) => (
                <div key={field?.key} className="d-flex full-width">
                  <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                    <Form.Item
                      colon={false}
                      label={index === 0 ? 'Order' : ''}
                      name={[field?.name, 'order']}
                      normalize={normalizeNegativeNumber}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter order!'
                        }
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Order"
                        className="number-input"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                    <Form.Item
                      label={index === 0 ? 'Label' : ''}
                      name={[field?.name, 'label']}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter label!'
                        },
                        {
                          validator(rule, value) {
                            if (value?.length > 0 && !value.trim()) {
                              return Promise?.reject(
                                new Error('Please enter label!')
                              );
                            }
                            return Promise?.resolve();
                          }
                        }
                      ]}
                    >
                      <Input placeholder="Enter Option label" />
                    </Form.Item>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Form.Item colon={false}>
                      <Button
                        disabled={fields?.length === 1}
                        className="text-btn"
                        type="text"
                        onClick={() => {
                          remove(field?.name);
                        }}
                        icon={<DeleteOutlined />}
                      />
                    </Form.Item>
                  </Col>
                </div>
              ))}
            </Row>
            <Button
              onClick={() => add({ label: '', order: null })}
              icon={<PlusOutlined />}
            >
              Add Option
            </Button>
          </div>
        )}
      </Form.List>
    </Modal>
  );
};

export default OptionsModal;
