import React, { useEffect, useRef } from 'react';

const InView = ({ onChange }) => {
  const ref = useRef();

  const onChangeRef = useRef();
  onChangeRef.current = onChange;

  useEffect(() => {
    const node = ref.current;
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      onChangeRef.current({ inView: isIntersecting });
      if (isIntersecting) {
        observer.unobserve(node);
      }
    });

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) observer.unobserve(node);
    };
  }, [onChangeRef]);

  return <div style={{ minHeight: 1 }} ref={ref} />;
};

export default InView;
