import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_STATISTICS_DATA = gql`
  query statistics($filters: StatisticsFilter) {
    statistics(filters: $filters) {
      newUsers
      newDonations
      newTables
      newContactInquiries
      migratedUsers
    }
  }
`;
