import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Col, Form, Input, Row, Select, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { filter, isEmpty, isObject, map, omit } from 'lodash';
import { ArrowsOutCardinal } from 'phosphor-react';
import React, { forwardRef, useEffect, useState } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  CTA_ACTION_TYPES,
  FORM_CTA_TYPES,
  FORM_FIELD_TYPES,
  FORM_MESSAGE_TYPES,
  FORM_TYPES,
  FORM_TYPE_OPTIONS,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  STATUS_TYPES,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import AllowedPlansTable from '../../components/AllowedPlansTable';
import HtmlEditor from '../../components/HtmlEditor';
import PageHeader from '../../components/PageHeader';
import SeoInput, {
  defaultSeoValues,
  getSeoFormFields
} from '../../components/SeoInput';
import useCheckPermission from '../../hooks/useCheckPermission';
import SelectAsset, { initialAsset } from '../assets/components/SelectAsset';
import { SlugInput } from '../labels/topics/components/FormInputs';
import {
  Permissions,
  Switch
} from '../pages/component/pageModules/moduleForms/FormInputs';
import OptionsModal from './OptionsModal';
import { CREATE_FORM, UPDATE_FORM } from './graphql/Mutations';
import { GET_FORM, GET_FORM_TEMPLATE } from './graphql/Queries';

const initialValues = {
  title: '',
  description: '',
  html: '',
  slug: '/',
  status: STATUS_TYPES.DRAFT,
  type: null,
  metaHeader: '',
  metaFooter: '',
  permissions: [],
  fields: [{ required: false, label: '', type: null }],
  messages: [{}],
  seo: defaultSeoValues
};

let messageTypeSelected = [];
let ctaTypeSelected = [];

const messageColumns = [
  {
    title: 'Type',
    key: 'messageType',
    render: (value, row) => {
      return (
        <Form.Item
          {...row}
          name={[row?.name, 'type']}
          key={[row?.key, 'type']}
          className="mb-0"
          rules={[
            {
              required: true,
              message: 'Please select type!'
            },
            ({ getFieldValue }) => ({
              validator(_, messageValue) {
                if (!value) {
                  return Promise.resolve();
                }
                messageTypeSelected = filter(getFieldValue('messages'), (e) => {
                  return e?.type === messageValue;
                });
                if (messageTypeSelected?.length >= 2) {
                  return Promise.reject(new Error('Must Not Be Repeated'));
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Select
            options={FORM_MESSAGE_TYPES}
            placeholder="Select Message Type"
          />
        </Form.Item>
      );
    }
  },
  {
    title: 'Text',
    key: 'message',
    render: (_, row) => {
      return (
        <Form.Item
          {...row}
          name={[row?.name, 'message']}
          key={[row?.key, 'message']}
          className="mb-0"
          rules={[
            {
              required: true,
              message: 'Please enter text!'
            },
            {
              validator(rule, value) {
                if (value?.length > 0 && !value.trim()) {
                  return Promise?.reject(new Error('Please enter text!'));
                }
                return Promise?.resolve();
              }
            }
          ]}
        >
          <Input placeholder="Enter Message text" />
        </Form.Item>
      );
    }
  }
];

const TableRow = forwardRef(({ children, ...rest }, ref) => (
  <tr ref={ref} {...rest}>
    {children}
  </tr>
));

const Draggable = ({ id, children, className, style: defaultStyles }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging
  } = useSortable({
    id,
    strategy: verticalListSortingStrategy
  });

  const [firstChild, ...restChildren] = children;

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners
  };

  return (
    <TableRow
      className={className}
      style={{ ...style, ...defaultStyles }}
      ref={setNodeRef}
    >
      {React.cloneElement(firstChild, {
        render: firstChild?.props?.render?.bind(null, handlers)
      })}
      {restChildren}
    </TableRow>
  );
};

const DropdownButton = ({ name, form }) => {
  const [open, setOpen] = useState(false);
  const options = Form?.useWatch(['fields', name, 'options'], form) ?? [];

  return (
    <>
      <OptionsModal
        open={open}
        form={form}
        name={[name, 'options']}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Button
        htmlType="button"
        className="table-view-btn"
        onClick={() => setOpen(true)}
      >
        {options?.length > 0 ? 'Edit' : 'Add'} Options
      </Button>
    </>
  );
};

export const DraggableRow = ({ className, children, style, ...rest }) => {
  const id = rest['data-row-key'] + 1;
  if (!id)
    return (
      <TableRow className={className} style={style}>
        {children}
      </TableRow>
    );
  return (
    <Draggable id={id} className={className} style={style}>
      {children}
    </Draggable>
  );
};

const AddEditForm = ({ history, location, match: { params } }) => {
  const { filters } = location?.state || {};
  const [form] = Form.useForm();
  const { formId } = params;
  const isEdit = !!formId;
  const formType = Form.useWatch('type', form);
  const ctas = Form.useWatch('ctas', form);
  const getFields = Form.useWatch('fields', form);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const addEditFormsBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    {
      label: MODULES?.FORMS,
      route: ROUTES?.CONTENTS_FORMS,
      addEditFilter: { filters }
    },
    formId && { label: formId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAssetEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isAssetViewAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const fieldColumns = [
    {
      title: ' ',
      key: 'id',
      width: 30,
      render: (handlers) => {
        return (
          <div className="d-flex align-center justify-center">
            <ArrowsOutCardinal
              {...handlers}
              className="grab drag-icon"
              size={16}
            />
          </div>
        );
      }
    },
    {
      title: 'Required',
      key: 'required',
      render: (_, row, index) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'required']}
            valuePropName="checked"
            key={[row?.key, 'required']}
            className="align-center d-flex mb-0"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  const fields = getFieldValue('fields');
                  if (!fields?.some((field) => field?.required)) {
                    if (fields?.length - 1 === index)
                      return Promise.reject(
                        new Error('At least one field is type of required!')
                      );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Switch
              onChange={() => {
                const fields = form?.getFieldValue('fields');
                fields.forEach((field, fieldIndex) => {
                  form.validateFields([['fields', fieldIndex, 'required']]);
                });
              }}
              disabled={formType === FORM_TYPES.POLL}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Type',
      key: 'fieldType',
      render: (value, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'type']}
            key={[row?.key, 'type']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please select type!'
              }
            ]}
          >
            <Select
              options={FORM_FIELD_TYPES}
              placeholder="Select Field Type"
              disabled={formType === FORM_TYPES.POLL}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Label',
      key: 'fieldLabel',
      render: (labelValue, row, index) => {
        const getType = getFields?.[index]?.type;
        return (
          <div className="d-flex justify-between align-center gap-6">
            <Form.Item
              {...row}
              name={[row?.name, 'label']}
              key={[row?.key, 'label']}
              className="mb-0 width-percent-80"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!labelValue) {
                      return Promise?.resolve();
                    }
                    if (!value || value?.trim()?.length <= 0) {
                      return Promise?.reject(new Error('Please enter label!'));
                    }
                    const fields = getFieldValue('fields');
                    if (fields?.length > 0) {
                      const isPresent = fields
                        ?.filter((field, i) => i !== row?.name)
                        ?.find(
                          (field) =>
                            field?.label?.toLowerCase() === value?.toLowerCase()
                        );
                      if (isPresent) {
                        return Promise?.reject(
                          new Error('Please provide unique label!')
                        );
                      }
                    }
                    return Promise?.resolve();
                  }
                })
              ]}
            >
              <Input placeholder="Enter Field label" />
            </Form.Item>
            {['dropdown', 'radio'].includes(getType) && (
              <DropdownButton form={form} name={row?.name} />
            )}
          </div>
        );
      }
    }
  ];

  const ctaColumns = [
    {
      title: 'Type',
      key: 'ctaType',
      render: (value, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'type']}
            key={[row?.key, 'type']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please select type!'
              },
              ({ getFieldValue }) => ({
                validator() {
                  if (!value) {
                    return Promise.resolve();
                  }
                  ctaTypeSelected = filter(getFieldValue('ctas'), (e) => {
                    return e?.type === 'PRIMARY';
                  });
                  if (ctaTypeSelected?.length >= 2) {
                    return Promise.reject(
                      new Error('Should be only one PRIMARY')
                    );
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Select options={FORM_CTA_TYPES} placeholder="Select CTA Type" />
          </Form.Item>
        );
      }
    },
    {
      title: 'Label',
      key: 'ctaLabel',
      render: (_, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'label']}
            key={[row?.key, 'label']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please enter label!'
              },
              {
                validator(rule, value) {
                  if (value?.length > 0 && !value.trim()) {
                    return Promise?.reject(new Error('Please enter label!'));
                  }
                  return Promise?.resolve();
                }
              }
            ]}
          >
            <Input placeholder="Enter CTA label" />
          </Form.Item>
        );
      }
    },
    {
      title: 'Action Type',
      key: 'actionType',
      render: (value, row, index) => {
        const getAction = ctas?.[index]?.action;
        return (
          <div className="d-flex align-center">
            <Form.Item
              {...row}
              name={[row?.name, 'action']}
              key={[row?.key, 'action']}
              className="mr-12 mb-0"
              rules={[
                {
                  required: true,
                  message: 'Please select action type!'
                }
              ]}
            >
              <Select
                options={CTA_ACTION_TYPES}
                placeholder="Select Action Type"
              />
            </Form.Item>
            {getAction && getAction !== 'SUBMIT' && (
              <Form.Item
                {...row}
                name={[row?.name, 'actionUrl']}
                key={[row?.key, 'actionUrl']}
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Action Url!'
                  }
                ]}
              >
                <Input placeholder="Enter Action Url" />
              </Form.Item>
            )}
          </div>
        );
      }
    },
    {
      title: 'Icon',
      key: 'icon',
      render: (value, row) => {
        return (
          <Form.Item
            {...row}
            name={[row?.name, 'iconId']}
            key={[row?.key, 'iconId']}
            className="mb-0"
          >
            <SelectAsset
              disabled={isViewOnly}
              modalTitle="Select Icon"
              categoryKey={ASSET_CATEGORY.ICON}
              btnText="Icon"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
              IsTableView
              isAssetEditAllowed={isAssetEditAllowed}
              isAssetViewAllowed={isAssetViewAllowed}
            />
          </Form.Item>
        );
      }
    }
  ];

  const { loading } = useQuery(GET_FORM_TEMPLATE, {
    fetchPolicy: 'network-only',
    skip:
      isEdit ||
      (!isEdit &&
        (!formType || [FORM_TYPES.POLL, FORM_TYPES.OTHER].includes(formType))),
    variables: {
      where: {
        type: formType
      }
    },
    onCompleted(res) {
      if (res?.formTemplateAdmin?.fields) {
        const fields = res?.formTemplateAdmin?.fields || [];
        const sortedFields = fields?.sort(function (a, b) {
          return a?.order - b?.order;
        });
        form?.setFieldsValue({ fields: sortedFields });
      }
      if (res?.formTemplateAdmin?.messages) {
        const messages = res?.formTemplateAdmin?.messages || [];
        form?.setFieldsValue({ messages });
      }
      if (res?.formTemplateAdmin?.ctas) {
        const cta = res?.formTemplateAdmin?.ctas || [];
        form?.setFieldsValue({ ctas: cta });
      }
    },
    onError() {
      form?.setFieldsValue({ fields: [{}], ctas: [{}], messages: [{}] });
    }
  });

  const [getForm, { loading: fetchingDetails }] = useLazyQuery(GET_FORM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      form.setFieldsValue({
        ...res?.formAdmin,
        html: res?.formAdmin?.html ?? '',
        slug: res?.formAdmin?.slug?.startsWith('/')
          ? res?.formAdmin?.slug
          : `/${res?.formAdmin?.slug}`,
        ctas: map(res?.formAdmin?.ctas, (cta) => {
          return omit(
            {
              ...cta,
              iconId: cta?.icon
                ? {
                    id: cta?.icon?.id,
                    url: cta?.icon?.url
                  }
                : {
                    ...initialAsset
                  }
            },
            ['icon']
          );
        }),
        allowedPlans:
          res?.formAdmin?.allowedPlans?.map((item) => ({
            ...item,
            key: { label: item?.key, value: item?.key },
            expiredAt: item?.expiredAt ? dayjs(item?.expiredAt) : null
          })) ?? [],
        seo: getSeoFormFields(res?.formAdmin?.seo)
      });
    },
    onError() {}
  });

  const [createUpdateForm, { loading: addUpdateLoading }] = useMutation(
    isEdit ? UPDATE_FORM : CREATE_FORM,
    {
      onError() {}
    }
  );

  useEffect(() => {
    if (formId) {
      getForm({
        variables: {
          where: {
            id: formId
          }
        }
      });
    }
  }, [formId]);

  const handleCancel = () => {
    history.replace(ROUTES?.CONTENTS_FORMS, {
      onAddEditFilters: filters
    });
  };

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug?.convert(e.target.value)}`);
  };

  const handleDragEnd = ({ over, active }) => {
    if (!over) return;

    const source = active?.id - 1;
    const destination = over?.id - 1;
    if (source !== destination) {
      form.setFieldsValue({
        fields: arrayMove(form.getFieldValue('fields'), source, destination)
      });
    }
  };

  const handleSubmit = async (values) => {
    const fields = map(values?.fields, (field, index) => {
      return omit(
        {
          ...field,
          required: field?.required || false,
          order: index + 1,
          options: !isEmpty(field?.options)
            ? field.options?.map(({ label, order }) => ({
                label,
                order: order ? Number(order) : 0
              })) ?? []
            : null
        },
        ['__typename', 'key']
      );
    });
    const messages = map(values?.messages, (message) => {
      return omit(
        {
          ...message
        },
        ['__typename']
      );
    });
    const updatedCtas = map(values?.ctas, (cta) => {
      return omit(
        {
          ...cta,
          iconId: cta?.iconId?.id || null
        },
        ['__typename', 'icon']
      );
    });
    const payload = {
      ...values,
      fields,
      messages,
      ctas: updatedCtas,
      slug: values?.slug?.startsWith('/')
        ? values?.slug?.substring(1)
        : values?.slug,
      permissions: values?.permissions?.map(({ value }) => value),
      allowedPlans: values?.allowedPlans?.map(({ key, expiredAt }) => ({
        key: isObject(key) ? key?.value : key,
        expiredAt: expiredAt ? dayjs(expiredAt) : null
      }))
    };

    createUpdateForm({
      variables: {
        data: isEdit ? omit(payload, ['type']) : payload,
        ...(isEdit && {
          where: {
            id: formId
          }
        })
      }
    }).then((res) => {
      if (res?.data) {
        history.push(ROUTES?.CONTENTS_FORMS, {
          onAddEditFilters: filters
        });
      }
    });
  };

  return (
    <>
      <PageHeader menu={addEditFormsBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            form={form}
            disabled={isViewOnly || fetchingDetails || addUpdateLoading}
            className="add-edit-form"
            layout="vertical"
            initialValues={initialValues}
            validateTrigger={['onChange', 'onSubmit']}
            onFinish={handleSubmit}
            onValuesChange={(changedValues, allValues) => {
              const messageTypes = allValues?.messages?.map(
                (message) => message?.type
              );
              if (messageTypes)
                messageTypes.forEach((message, index) => {
                  if (messageTypes[index])
                    form.validateFields([['messages', index, 'type']]);
                });

              const fieldLabels = allValues?.fields?.map(
                (field) => field?.label
              );
              if (fieldLabels)
                fieldLabels.forEach((field, index) => {
                  if (fieldLabels[index])
                    form.validateFields([['fields', index, 'label']]);
                });
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  required
                  rules={[
                    formValidatorRules?.required('Please enter title'),
                    formValidatorRules?.maxLength(MAX_LENGTHS?.TITLE)
                  ]}
                >
                  <Input
                    placeholder="Enter title"
                    onChange={handleTitleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Slug"
                  name="slug"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter slug!'
                    },
                    formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                  ]}
                >
                  <SlugInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Publish Status" name="status">
                  <Select
                    options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
                      ({ name, value }) => ({
                        label: name,
                        value
                      })
                    )}
                    placeholder="Select status"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="metaHeader"
                  label="Meta Header"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta header" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="metaFooter"
                  label="Meta Footer"
                  rules={[
                    formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)
                  ]}
                >
                  <Input.TextArea rows={5} placeholder="Enter meta footer" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Permissions />
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Form Type"
                  name="type"
                  rules={[
                    { required: true, message: 'Please select form type!' }
                  ]}
                >
                  <Select
                    options={FORM_TYPE_OPTIONS.map(({ label, value }) => ({
                      label,
                      value
                    }))}
                    placeholder="Select type"
                    disabled={isEdit}
                    onChange={(e) => {
                      if ([FORM_TYPES.POLL].includes(e)) {
                        form?.setFieldsValue({
                          fields: [
                            {
                              required: true,
                              type: 'radio',
                              label: ''
                            }
                          ],
                          ctas: [{}],
                          messages: [{}]
                        });
                      }
                      if ([FORM_TYPES.OTHER].includes(e)) {
                        form?.setFieldsValue({
                          fields: [{}],
                          ctas: [{}],
                          messages: [{}]
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {formType && (
              <>
                <fieldset className="mb-12">
                  <legend className="role-legend">Fields</legend>
                  <Form.List name={['fields']}>
                    {(fields, { add, remove }) => (
                      <div className="listview form-table nested-table">
                        <DndContext
                          sensors={sensors}
                          onDragEnd={handleDragEnd}
                          modifiers={[restrictToVerticalAxis]}
                        >
                          <SortableContext
                            strategy={verticalListSortingStrategy}
                            items={fields?.map(({ name }) => name + 1)}
                          >
                            <Table
                              dataSource={fields?.map((field) => ({
                                ...field
                              }))}
                              rowKey="name"
                              loading={loading}
                              pagination={false}
                              bordered={false}
                              footer={() => {
                                return (
                                  <Button
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                    disabled={formType === FORM_TYPES.POLL}
                                  >
                                    Add Field
                                  </Button>
                                );
                              }}
                              components={{
                                body: {
                                  row: DraggableRow
                                }
                              }}
                              columns={[
                                ...fieldColumns,
                                {
                                  title: 'Action',
                                  render: (value, row) => {
                                    return (
                                      <Button
                                        disabled={fields?.length === 1}
                                        className="text-btn"
                                        type="text"
                                        onClick={() => {
                                          remove(row?.name);
                                        }}
                                        icon={<DeleteOutlined />}
                                      />
                                    );
                                  }
                                }
                              ]}
                            />
                          </SortableContext>
                        </DndContext>
                      </div>
                    )}
                  </Form.List>
                </fieldset>
                <fieldset className="mb-12">
                  <legend className="role-legend">Messages</legend>
                  <Form.List name={['messages']}>
                    {(fields, { add, remove }) => (
                      <div className="listview form-table nested-table">
                        <Table
                          dataSource={fields?.map((field) => ({
                            ...field
                          }))}
                          rowKey="name"
                          loading={loading}
                          pagination={false}
                          bordered={false}
                          footer={() => {
                            return (
                              <Button
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Add Message
                              </Button>
                            );
                          }}
                          columns={[
                            ...messageColumns,
                            {
                              title: 'Action',
                              render: (value, row) => {
                                return (
                                  <Button
                                    disabled={fields?.length === 1}
                                    className="text-btn"
                                    type="text"
                                    onClick={() => {
                                      remove(row?.name);
                                    }}
                                    icon={<DeleteOutlined />}
                                  />
                                );
                              }
                            }
                          ]}
                        />
                      </div>
                    )}
                  </Form.List>
                </fieldset>
                <fieldset className="mb-12">
                  <legend className="role-legend">CTAs</legend>
                  <Form.List name={['ctas']}>
                    {(fields, { add, remove }) => (
                      <div className="listview form-table nested-table">
                        <Table
                          dataSource={fields?.map((field) => ({
                            ...field
                          }))}
                          rowKey="name"
                          loading={loading}
                          pagination={false}
                          bordered={false}
                          footer={() => {
                            return (
                              <Button
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Add CTA
                              </Button>
                            );
                          }}
                          columns={[
                            ...ctaColumns,
                            {
                              title: ' ',
                              render: (value, row) => {
                                return (
                                  <Button
                                    disabled={fields?.length === 1}
                                    className="text-btn"
                                    type="text"
                                    onClick={() => {
                                      remove(row?.name);
                                    }}
                                    icon={<DeleteOutlined />}
                                  />
                                );
                              }
                            }
                          ]}
                        />
                      </div>
                    )}
                  </Form.List>
                </fieldset>
              </>
            )}
            <fieldset className="mb-12">
              <legend className="role-legend">Allowed Plans</legend>
              <AllowedPlansTable
                loading={fetchingDetails}
                form={form}
                formKey="allowedPlans"
              />
            </fieldset>
            <SeoInput form={form} />
            <Col span={24}>
              <Form.Item className="full-html" label="Html" name="html">
                <HtmlEditor disabled={isViewOnly} className="full-html" />
              </Form.Item>
            </Col>

            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails || addUpdateLoading}
                    loading={addUpdateLoading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading || addUpdateLoading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditForm;
