import { gql } from '@apollo/client';

export const GET_PROVIDERS = gql`
  query providersAdmin($filter: ProvidersFilter!) {
    providersAdmin(filter: $filter) {
      providers {
        id
        name
        key
        config
      }
      count
    }
  }
`;

export const GET_PLATFORMS = gql`
  query workspacePlatforms(
    $filter: WorkspacePlatformFilter
    $sort: WorkspacePlatformSort
  ) {
    workspacePlatforms(filter: $filter, sort: $sort) {
      count
      workspacePlatforms {
        id
        name
        type
        provider {
          name
          id
          key
        }
        isActive
        isDefault
        description
        isEditable
      }
    }
  }
`;

export const GET_PLATFORM = gql`
  query workspacePlatform($where: WorkspacePlatformWhereUniqueInput!) {
    workspacePlatform(where: $where) {
      config
      id
      name
      description
      provider {
        id
        name
        key
        config
      }
      type
    }
  }
`;
