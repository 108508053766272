import { gql } from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation createCollection($data: CollectionCreateInput!) {
    createCollection(data: $data) {
      message
      collection {
        id
      }
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation updateCollection($data: CollectionUpdateInput!, $id: ID!) {
    updateCollection(data: $data, where: { id: $id }) {
      message
      collection {
        id
      }
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($id: ID!) {
    deleteCollection(where: { id: $id }) {
      message
    }
  }
`;

export const UPSERT_COLLECTION_ITEMS = gql`
  mutation UpsertCollectionItems(
    $where: CollectionUniqueInput!
    $data: UpsertCollectionItemsDataInput!
  ) {
    upsertCollectionItems(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_COLLECTION_ORDER = gql`
  mutation UpdateCollectionItemOrderInput(
    $where: CollectionUniqueInput!
    $data: UpdateCollectionItemOrderInput!
  ) {
    updateCollectionItemOrder(where: $where, data: $data) {
      message
    }
  }
`;
