import { Button } from 'antd';
import {
  CaretDoubleDown,
  CaretDoubleUp,
  CaretDown,
  CaretUp
} from 'phosphor-react';
import React from 'react';

function SectionWrapper({
  onMoveTop,
  onMoveUp,
  onMoveDown,
  onMoveBottom,
  children,
  isFirst,
  isLast,
  disableSort = false
}) {
  return (
    <div className="section-wrapper relative">
      <div className="section-action">
        <Button
          disabled={isFirst || disableSort}
          type="text"
          onClick={onMoveTop}
        >
          <CaretDoubleUp size={14} weight="bold" />
        </Button>
        <Button
          disabled={isFirst || disableSort}
          type="text"
          onClick={onMoveUp}
        >
          <CaretUp size={14} weight="bold" />
        </Button>
        <Button
          disabled={isLast || disableSort}
          type="text"
          onClick={onMoveDown}
        >
          <CaretDown size={14} weight="bold" />
        </Button>
        <Button
          disabled={isLast || disableSort}
          type="text"
          onClick={onMoveBottom}
        >
          <CaretDoubleDown size={14} weight="bold" />
        </Button>
      </div>
      <div className="section-block pt-4 pb-4">{children}</div>
    </div>
  );
}
export default SectionWrapper;
