import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Switch as AntdSwitch, Typography } from 'antd';
import { Article, Megaphone, Microphone, VideoCamera } from 'phosphor-react';
import React, { forwardRef, useId } from 'react';
import { CONTENT_SELECTION_KEYS } from '../../../common/constants';

const getIcon = (instanceType) => {
  switch (instanceType) {
    case CONTENT_SELECTION_KEYS.ARTICLE:
      return <Article />;
    case CONTENT_SELECTION_KEYS.PODCAST:
      return <Microphone />;
    case CONTENT_SELECTION_KEYS.AD:
      return <Megaphone />;
    default:
      return <VideoCamera />;
  }
};

const Preview = forwardRef(
  (
    {
      bgImg,
      children,
      onRemove,
      IsTableView,
      instanceType,
      onEdit,
      item,
      showIcon = false,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`preview-card ${IsTableView ? 'table-view-asset' : ''}`}
        ref={ref}
        {...rest}
      >
        <img alt="" src={bgImg} />
        {showIcon && instanceType && (
          <button type="button" className="show-icon">
            {getIcon(instanceType)}
          </button>
        )}
        {[CONTENT_SELECTION_KEYS.ARTICLE].includes(instanceType) && onEdit && (
          <button
            type="button"
            className="edit-icon"
            onClick={onEdit}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <EditOutlined />
          </button>
        )}
        {onRemove && (
          <button
            type="button"
            className="remove-icon"
            onClick={onRemove}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <CloseOutlined />
          </button>
        )}
        {children}
      </div>
    );
  }
);

Preview.Title = ({ className, color, children }) => {
  return (
    <Typography.Text
      title={children}
      className={`${className}`}
      style={{
        // used inline style as its dynamic value
        color
      }}
    >
      {children}
    </Typography.Text>
  );
};

Preview.Description = ({ className, color, children }) => {
  return (
    <Typography.Text
      title={children}
      className={`${className}`}
      style={{
        // used inline style as its dynamic value
        color
      }}
    >
      {children}
    </Typography.Text>
  );
};

const Switch = ({ color, label, id: id$, ...rest }) => {
  const reactId = useId();
  const id = id$ || reactId;
  return (
    <div
      className="featured-switch"
      style={{
        // used inline style as its dynamic value
        color
      }}
    >
      <AntdSwitch id={id} {...rest} />
      <label className="pointer" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Preview.Switch = Switch;

export default Preview;
