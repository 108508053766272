import { useMutation } from '@apollo/client';
import { Button, Form, Space, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  MODULE_TYPES,
  PAGE_TYPES,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { GET_POSTS_OPTIONS } from '../../../../moderation/posts/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true,
    postComment: true,
    postPray: true,
    postShare: true
  },
  config: {
    autoGenerate: true,
    autoGenerateById: null,
    post: null
  }
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.POST_CAROUSEL,
      MODULE_TYPES.POST_LIST,
      MODULE_TYPES.POST_DETAIL
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.POST_CAROUSEL,
      MODULE_TYPES.POST_LIST,
      MODULE_TYPES.POST_DETAIL
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [MODULE_TYPES.POST_CAROUSEL, MODULE_TYPES.POST_LIST]
  },
  {
    name: 'postComment',
    label: 'Post Comment Button',
    allowedTypes: [MODULE_TYPES.POST_CAROUSEL, MODULE_TYPES.POST_LIST]
  },
  {
    name: 'postPray',
    label: 'Post Pray Button',
    allowedTypes: [MODULE_TYPES.POST_CAROUSEL, MODULE_TYPES.POST_LIST]
  },
  {
    name: 'postShare',
    label: 'Post Share Button',
    allowedTypes: [MODULE_TYPES.POST_CAROUSEL, MODULE_TYPES.POST_LIST]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.POST_CAROUSEL]: 'postCarouselModule',
  [MODULE_TYPES.POST_LIST]: 'postListModule',
  [MODULE_TYPES.POST_DETAIL]: 'postDetailModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.POST_CAROUSEL]: 'Post Carousel Configs',
  [MODULE_TYPES.POST_LIST]: 'Post List Configs',
  [MODULE_TYPES.POST_DETAIL]: 'Post Detail Configs'
};

const { POST } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.POST_DETAIL]: [POST]
};

function PostForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const configProps = Form.useWatch(['config'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;

      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          post: defaultValues?.moduleData?.config?.post
            ? {
                label: defaultValues?.moduleData?.config?.post?.text,
                value: defaultValues?.moduleData?.config?.post?.id
              }
            : null,
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateByType:
            defaultValues?.moduleData?.config?.postAutoGenerateByType || null,
          autoGenerateById: idm
            ? TEMPLATE_OPTION
            : getAutoGenerateByDataValue[
                defaultValues?.moduleData?.config?.postAutoGenerateByType
              ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ?? null
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    isDefaultModule: isDefaultPageModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && isDefaultPageModule;

    const {
      autoGenerate: autoGenerateValue,
      autoGenerateById,
      post,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...(type === MODULE_TYPES.POST_DETAIL
            ? {
                postId: isDefault ? '*' : post?.value
              }
            : {
                autoGenerate: autoGenerateValue,
                autoGenerateById: autoGenerateById?.value,
                ...restConfig
              })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          {type === MODULE_TYPES.POST_DETAIL ? (
            <>
              {allowedTemplateConfig && pageType === POST && (
                <>
                  <Typography.Text>Template Config</Typography.Text>
                  <Form.Item
                    className="m-0"
                    name="isDefaultModule"
                    valuePropName="checked"
                  >
                    <Switch label="Use Template Data" />
                  </Form.Item>
                </>
              )}
              {(!allowedTemplateConfig ||
                (allowedTemplateConfig && !isDefaultModule)) && (
                <>
                  <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                  <div>
                    <Form.Item label="Post" name={['config', 'post']}>
                      <Select
                        placeholder="Select Post"
                        query={GET_POSTS_OPTIONS}
                        variablesSelector={({ skip, limit }) => ({
                          filter: { skip, limit }
                        })}
                        dataSelector={(data) =>
                          data?.postsAdmin?.posts?.map(({ id, text }) => ({
                            label: text,
                            value: id
                          })) ?? []
                        }
                        keys={{
                          data: 'postsAdmin',
                          records: 'posts',
                          count: 'count'
                        }}
                        searchable={false}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
              <div>
                <Form.Item
                  className="mb-32"
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                  help="Only auto generated posts are allowed."
                >
                  <Switch disabled label="Auto Generated Posts" />
                </Form.Item>
                {autoGenerate && (
                  <>
                    {autoGenerateByType && autoGenerateByIdProps && (
                      <Form.Item
                        label="Auto Generated By"
                        name={['config', 'autoGenerateById']}
                      >
                        <Select
                          placeholder="Select"
                          query={autoGenerateByIdProps?.query}
                          variablesSelector={(filter) => ({
                            filter
                          })}
                          dataSelector={autoGenerateByIdProps?.dataSelector}
                          keys={autoGenerateByIdProps?.keys}
                          {...(allowedTemplateConfig && {
                            includeOptions
                          })}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
}

export default PostForm;
