import { Avatar, Button } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';

function CtaButtonList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive
}) {
  return (
    <section className={`category-list ${responsive ? 'responsive-view' : ''}`}>
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />

        {data?.map((item) => {
          return (
            <Button
              key={item?.id}
              className={`cs-button mb-8 d-flex justify-around ${
                item?.buttonType === 'PRIMARY'
                  ? 'text-white-90 bg-n-900 hbg-n-800 hc-white-90 hb-n-800'
                  : 'b-0 b-solid bg-ivory text-n-900 hbg-n-200 text-n-900 hc-black-80 hb-ivory b-transparent hb-n-200'
              }}`}
              block
            >
              {item?.title}
              {item?.img ? (
                <Avatar className="ml-8" src={item?.img} size={22} />
              ) : (
                ''
              )}
            </Button>
          );
        })}
      </div>
    </section>
  );
}
export default CtaButtonList;
