import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import ContributorCard from './ContributorCard';

function ContributorList({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive,
  separator
}) {
  return (
    <section
      className={`contributorlist-section ${
        responsive ? 'responsive-view' : ''
      } ${!separator ? 'separatoroff' : ''}`}
    >
      <div className="container-rnp">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />

        {data?.map((item) => {
          return (
            <ContributorCard
              key={item?.id}
              listCard
              contributor={item?.contributor}
              videoCount={item?.videoCount}
              image={item?.url}
              blurHash={item?.blurHash}
              description={item?.description}
            />
          );
        })}
      </div>
    </section>
  );
}
export default ContributorList;
