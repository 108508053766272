import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ROUTES } from '../../common/constants';
import AddEditWorkspace from './AddEditWorkspace';

export default function WorkspacesWrapper() {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.WORKSPACES}/add`}
        render={(props) => <AddEditWorkspace {...props} />}
      />
      <Route
        exact
        path={`${ROUTES?.WORKSPACES}/:id/edit`}
        render={(props) => <AddEditWorkspace {...props} />}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
}
