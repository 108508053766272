import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditUILabel from './AddEditUILabel';
import UILabels from './UILabels';

const UILabelsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.UI_CONFIG_LABELS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <UILabels {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.UI_CONFIG_LABELS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditUILabel {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.UI_CONFIG_LABELS}/:uiLabelId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditUILabel {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.UI_CONFIG_LABELS}/:uiLabelId`}
        render={() => <Redirect to={ROUTES?.UI_CONFIG_LABELS} />}
      />
    </Switch>
  );
};

export default UILabelsWrapper;
