import { Button, ConfigProvider, Form, Input } from 'antd';
import { CaretRight } from 'phosphor-react';
import React from 'react';
import webStyle from '../../../../styles/style-web.json';

const { TextArea } = Input;
function Contact({ title, subtitle, responsive }) {
  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`login-wrapper contact-page ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        <div className="container">
          <div className="loginblock">
            <div className="login-title text-center mb-32">
              <h3 className="display-h3 mb-4">{title}</h3>
              <p>{subtitle}</p>
            </div>
            <Form layout="vertical">
              <div className="form-group-two-grid">
                <div className="form-group">
                  <Form.Item name="firstName" label="First Name">
                    <Input placeholder="First name" />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item name="lastName" label="Last Name">
                    <Input placeholder="Last name" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-group">
                <Form.Item name="email" label="Email">
                  <Input type="email" placeholder="username@gmail.com" />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item name="textarea" label="How can we help?">
                  <TextArea />
                </Form.Item>
              </div>
              <div className="login-btn">
                <Button
                  className="cs-button between bg-n-900 text-n-100 d-flex justify-between hbg-black-80 hc-n-100 hb-black-80"
                  size="large"
                  block
                >
                  Submit <CaretRight size={24} />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default Contact;
