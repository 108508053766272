import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import {
  ALIGNMENTS,
  ASSET_CATEGORY,
  CONFIG_METHOD_TYPES,
  MAX_LENGTHS,
  MODULE_TYPES,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import SelectAsset from '../../../../assets/components/SelectAsset';
import {
  DatePicker,
  SelectContributor
} from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ACTION_TYPES,
  ActionButton,
  getActionData,
  initialActionValues,
  initialAssetValues
} from './CTAForm';
import { ModuleFields, Permissions, ShowFields } from './FormInputs';

const ALIGNMENT_OPTIONS = [
  {
    label: 'Left',
    value: ALIGNMENTS.LEFT
  },
  {
    label: 'Center',
    value: ALIGNMENTS.CENTER
  }
];

const initialValues = {
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    date: true,
    contributor: true,
    textFields: true,
    primaryAction: true,
    secondaryAction: true
  },
  config: {
    asset: {
      ...initialAssetValues
    },
    date: null,
    text: '',
    contributor: {
      id: '',
      firstName: '',
      lastName: '',
      imageURL: '',
      color: ''
    },
    primaryAction: {
      ...initialActionValues
    },
    secondaryAction: {
      ...initialActionValues
    },
    alignment: ALIGNMENTS.LEFT
  }
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.COPY_HEAVY, MODULE_TYPES.COPY_HEAVY_HERO]
  },
  {
    name: 'textFields',
    label: 'Text Fields',
    allowedTypes: [MODULE_TYPES.COPY_HEAVY, MODULE_TYPES.COPY_HEAVY_HERO]
  },
  {
    name: 'contributor',
    label: 'Contributor',
    allowedTypes: [MODULE_TYPES.COPY_HEAVY, MODULE_TYPES.COPY_HEAVY_HERO]
  },
  {
    name: 'date',
    label: 'Date',
    allowedTypes: [MODULE_TYPES.COPY_HEAVY, MODULE_TYPES.COPY_HEAVY_HERO]
  },
  {
    name: 'primaryAction',
    label: 'Primary Action',
    allowedTypes: [MODULE_TYPES.COPY_HEAVY, MODULE_TYPES.COPY_HEAVY_HERO]
  },
  {
    name: 'secondaryAction',
    label: 'Secondary Action',
    allowedTypes: [MODULE_TYPES.COPY_HEAVY, MODULE_TYPES.COPY_HEAVY_HERO]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.COPY_HEAVY]: 'copyHeavyModule',
  [MODULE_TYPES.COPY_HEAVY_HERO]: 'copyHeavyHeroModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.COPY_HEAVY]: 'Copy Heavy Configs',
  [MODULE_TYPES.COPY_HEAVY_HERO]: 'Copy Heavy Hero Configs'
};

const CopyHeavyForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const settingsProps = Form.useWatch(['settings'], form);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          ...(MODULE_TYPES.COPY_HEAVY_HERO === type && {
            asset: defaultValues?.moduleData?.config?.asset ?? {
              ...initialAssetValues
            }
          }),
          text: defaultValues?.moduleData?.config?.text ?? '',
          alignment:
            defaultValues?.moduleData?.config?.alignment ?? ALIGNMENTS.LEFT,
          date: defaultValues?.moduleData?.config?.date
            ? moment(defaultValues?.moduleData?.config?.date).format(
                'YYYY-MM-DD'
              )
            : null,
          primaryAction: getActionData(
            defaultValues?.moduleData?.config?.primaryAction,
            false
          ),
          secondaryAction: getActionData(
            defaultValues?.moduleData?.config?.secondaryAction,
            false
          ),
          contributor: {
            id: defaultValues?.moduleData?.config?.contributor?.id ?? '',
            firstName:
              defaultValues?.moduleData?.config?.contributor?.firstName ?? '',
            lastName:
              defaultValues?.moduleData?.config?.contributor?.lastName ?? '',
            color:
              defaultValues?.moduleData?.config?.contributor?.primaryColor ??
              '',
            imageURL:
              defaultValues?.moduleData?.config?.contributor?.image?.url ?? ''
          }
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    // eslint-disable-next-line no-shadow
    const {
      asset,
      contributor,
      primaryAction,
      secondaryAction,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          method: CONFIG_METHOD_TYPES.MANUAL,
          contributorId: contributor?.id ?? null,
          primaryAction: {
            title: primaryAction?.title ?? '',
            type: primaryAction?.type ?? ACTION_TYPES.EXTERNAL,
            url: primaryAction?.url ?? '',
            internalPageType: primaryAction?.internalPageType,
            rel: primaryAction?.rel ?? null
          },
          secondaryAction: {
            title: secondaryAction?.title ?? '',
            type: secondaryAction?.type ?? ACTION_TYPES.EXTERNAL,
            url: secondaryAction?.url ?? '',
            internalPageType: secondaryAction?.internalPageType,
            rel: secondaryAction?.rel ?? null
          },
          ...(MODULE_TYPES.COPY_HEAVY_HERO === type && {
            assetId: asset?.id ?? null
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Space className="w-full" direction="vertical">
          <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
          <Space className="w-full" direction="vertical">
            <div>
              <Form.Item
                label="Text"
                name={['config', 'text']}
                rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
              >
                <Input.TextArea rows={5} placeholder="Enter text" />
              </Form.Item>
              <Form.Item label="Date" name={['config', 'date']}>
                <DatePicker />
              </Form.Item>
              <Form.Item label="Alignment" name={['config', 'alignment']}>
                <AntdSelect options={ALIGNMENT_OPTIONS} />
              </Form.Item>
              <Form.Item
                label="Select Contributor"
                name={['config', 'contributor']}
              >
                <SelectContributor multiple={false} />
              </Form.Item>
              {MODULE_TYPES.COPY_HEAVY_HERO === type && (
                <Form.Item label="Background Image" name={['config', 'asset']}>
                  <SelectAsset
                    modalTitle="Select Background Image"
                    categoryKey={ASSET_CATEGORY.IMAGE}
                    btnText="Background Image"
                    dataSelector={({ id, url }) => ({
                      id,
                      url
                    })}
                  />
                </Form.Item>
              )}
              <ActionButton
                name="primaryAction"
                label="Primary Action"
                form={form}
              />
              <ActionButton
                name="secondaryAction"
                label="Secondary Action"
                form={form}
              />
            </div>
          </Space>
        </Space>
      </Space>
      <Permissions />
      <div className="d-flex button-section">
        <Space>
          <Form.Item>
            <Button
              type="text"
              htmlType="submit"
              className="text-btn mr-8"
              size="middle"
              disabled={loading}
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              onClick={onCancel}
              type="text"
              className="text-btn2"
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};

export default CopyHeavyForm;
