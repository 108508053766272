import moment from 'moment-timezone';
import { ListBullets, SquaresFour } from 'phosphor-react';
import React from 'react';
import Placeholder from '../assets/images/imagePlaceholder.png';
import { GET_ADS } from '../modules/ads/graphql/Queries';
import { GET_ARTICLES_MODULE } from '../modules/articles/graphql/Queries';
import { GET_FORMS } from '../modules/form/graphql/Queries';
import {
  GET_AUTOGENERATE_BY_COLLECTIONS,
  GET_AUTOGENERATE_BY_CONTRIBUTORS,
  GET_AUTOGENERATE_BY_TAGS,
  GET_AUTOGENERATE_BY_TOPIC,
  GET_AUTO_GENERATED_BY_SOURCE
} from '../modules/pages/graphql/Queries';
import { GET_PODCASTS_MODULE } from '../modules/podcast/graphql/Queries';
import { GET_VIDEOS_MODULES } from '../modules/videos/graphql/Queries';
/* ROUTERS  */
export const ROUTES = {
  // authentication routes
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  PROFILE: '/profile',
  ADMINS: '/admins',

  // dashboard routes
  DASHBOARD_LANDING: '/dashboard',
  DASHBOARD: '/dashboard-analytics',

  // ui configuration routes
  UI_CONFIG_PAGES: '/ui-config/pages',
  UI_CONFIG_MENUS: '/ui-config/menus',
  UI_CONFIG_LABELS: '/ui-config/labels',

  // content unit routes
  CONTENTS_VIDEOS: '/contents/videos',
  CONTENTS_PODCASTS: '/contents/podcasts',
  CONTENTS_FORMS: '/contents/forms',
  CONTENTS_ARTICLES: '/contents/articles',
  CONTENTS_ADS: '/contents/ads',
  CONTENTS_PRAYERS: '/contents/prayers',

  // loop route
  LOOP: '/loop',

  // moderation routes
  MODERATION_COMMENTS: '/moderation/comments',
  MODERATION_POSTS: '/moderation/posts',

  // assets routes
  ASSETS_IMAGES: '/assets/images',
  ASSETS_VIDEOS: '/assets/videos',
  ASSETS_DOCUMENTS: '/assets/documents',
  ASSETS_PODCASTS: '/assets/podcasts',
  ASSETS_MEDIA_CAPTIONS: '/assets/media-captions',
  ASSETS_ICONS: '/assets/icons',

  // labels routes
  LABELS_CONTRIBUTORS: '/labels/contributors',
  LABELS_TOPICS: '/labels/topics',
  LABELS_COLLECTIONS: '/labels/collections',
  LABELS_TAGS: '/labels/tags',
  LABELS_SOURCES: '/labels/sources',
  LABELS_ADVERTISERS: '/labels/advertisers',

  // reports routes
  REPORTS_LEAD_GENS: '/reports/lead-gens',
  REPORTS_CONTACT_INQUIRIES: '/reports/contact-inquiries',
  REPORTS_EXPORT_JOBS: '/reports/export-jobs',
  REPORTS_IMPORT_JOBS: '/reports/import-jobs',

  // user route
  USERS: '/users',

  // donation route
  DONATIONS: '/donations',

  // subscription plan route
  SUBSCRIPTIONS: '/subscriptions',

  // workspace settings routes
  WS_ROLES: '/ws-setting/roles',
  WS_MEMBERS: '/ws-setting/members',
  WS_SERVICES: '/ws-setting/services',

  // other routes
  NO_ACCESS: '/no-access',
  COMPONENTS: '/components',
  APPS: '/apps',
  WORKSPACES: '/workspaces'
};

/*  Modules */
export const MODULES = {
  PROFILE: 'PROFILE',
  ASSETS: 'ASSETS',
  LABELS: 'LABELS',
  IMAGES: 'IMAGES',
  VIDEOS: 'VIDEOS',
  DOCUMENTS: 'DOCUMENTS',
  AUDIOS: 'AUDIOS',
  MEDIA_CAPTIONS: 'MEDIA CAPTIONS',
  ICONS: 'ICONS',
  CONTRIBUTORS: 'CONTRIBUTORS',
  TOPICS: 'TOPICS',
  PAGES: 'PAGES',
  COLLECTIONS: 'COLLECTIONS',
  TAGS: 'TAGS',
  MENUS: 'MENUS',
  UI_LABELS: 'UI LABELS',
  USERS: 'USERS',
  DONATIONS: 'DONATIONS',
  LEAD_GENS: 'LEAD GENS',
  CONTACT_INQUIRIES: 'CONTACT INQUIRIES',
  EXPORT_JOBS: 'EXPORT JOBS',
  IMPORT_JOBS: 'IMPORT JOBS',
  DASHBOARD: 'DASHBOARD',
  PODCASTS: 'PODCASTS',
  CONTENTS: 'CONTENTS',
  REPORTS: 'REPORTS',
  UI_CONFIGURATIONS: 'UI CONFIGURATIONS',
  ADMINS: 'ADMINS',
  ROLES: 'ROLES',
  SERVICES: 'SERVICES',
  MEMBERS: 'MEMBERS',
  FORMS: 'FORMS',
  ARTICLES: 'ARTICLES',
  SOURCES: 'SOURCES',
  APPS: 'APPS',
  COMMUNITIES: 'COMMUNITIES',
  WORKSPACES: 'WORKSPACES',
  MODERATION: 'MODERATION',
  POSTS: 'POSTS',
  COMMENTS: 'COMMENTS',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  PERMISSIONS: 'PERMISSIONS',
  APP_SETTINGS: 'APP SETTINGS',
  WS_SETTINGS: 'WS SETTINGS',
  ADS: 'ADS',
  ADVERTISERS: 'ADVERTISERS',
  PRAYERS: 'PRAYERS',
  LOOP: 'THE LOOP',
  DASHBOARD_LANDING: 'DASHBOARD',
  IMPORT: 'IMPORT'
};

export const ASSET_CATEGORY = {
  VIDEO: 'VIDEO',
  TEXT: 'TEXT',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  ICON: 'ICON'
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

/* Workspace */
export const WORKSPACE = 'WORKSPACE';
export const APP = 'APP';

export const MESSAGE = 'MESSAGE';
export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
  WORKSPACE_USER: 'WORKSPACE_USER'
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const defaultDateFormatWithTime = 'MM/DD/YYYY hh:mm:ss';

export const VIDEOS = [
  {
    id: 1,
    title: 'Elephant Dream',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/19189e03-b90f-4d0e-991d-4c8aee34a5e9/5f44e513-d398-4f93-8e8a-074d788fc0fc-master.m3u8'
  },
  {
    id: 2,
    title: 'Big Buck Bunny',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://peertubetv.s3.eu-central-1.wasabisys.com/streaming-playlists/hls/f37656eb-be47-4289-bda3-5851ca40a501/59755baf-d2b4-461d-9333-12faa7908924-master.m3u8'
  },
  {
    id: 3,
    title: 'For Bigger Escape',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/65fb42ad-7dd4-410f-8a28-63fc8f6111e7/2067a88e-baa9-4dfe-800e-3bb3ef8ae688-master.m3u8'
  },
  {
    id: 4,
    title: 'For Bigger Joyrides',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  },
  {
    id: 5,
    title: 'Elephant Dream',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  },
  {
    id: 6,
    title: 'Big Buck Bunny',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://peertubetv.s3.eu-central-1.wasabisys.com/streaming-playlists/hls/f37656eb-be47-4289-bda3-5851ca40a501/59755baf-d2b4-461d-9333-12faa7908924-master.m3u8'
  },
  {
    id: 7,
    title: 'For Bigger Escape',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/65fb42ad-7dd4-410f-8a28-63fc8f6111e7/2067a88e-baa9-4dfe-800e-3bb3ef8ae688-master.m3u8'
  },
  {
    id: 8,
    title: 'For Bigger Joyrides',
    thumb: Placeholder,
    tracks: [
      {
        kind: 'subtitles',
        label: 'English',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/76487ab0-7499-419b-80af-03b662cdbc93-en.vtt',
        srcLang: 'en',
        default: false
      },
      {
        kind: 'subtitles',
        label: 'French',
        src:
          'https://video3.cappital.co/lazy-static/video-captions/91677363-5e15-44f4-bd50-76b6d9fc056c-de.vtt',
        srcLang: 'de',
        default: false
      },
      {
        kind: 'chapters',
        src:
          'https://s3.amazonaws.com/demo.jwplayer.com/text-tracks/assets/chapters.vtt',
        default: true
      }
    ],
    url:
      'https://video3cdn.cappital.co/streaming-playlists/hls/5f5f20b3-5f39-425e-bca5-d8af2a8c14d4/13338140-c8e1-4cf0-8641-7a9f23b9881d-master.m3u8'
  }
];
// export const VIDEO_URL =
//   'https://peertubetv.s3.eu-central-1.wasabisys.com/streaming-playlists/hls/f37656eb-be47-4289-bda3-5851ca40a501/59755baf-d2b4-461d-9333-12faa7908924-master.m3u8';

export const SHARE_URL = 'http://localhost:3000/video-player';

export const TRACK_KIND = {
  SUBTITLES: 'subtitles',
  CAPTIONS: 'captions',
  CHAPTERS: 'chapters'
};

export const TRACK_MODE = {
  SHOWING: 'showing',
  DISABLED: 'disabled'
};

export const PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const defaultSubtitle = 'English';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  KEY_VALUE: /^[A-Z0-9_]*$/,
  USERNAME: /^[a-z0-9]+$/,
  DOMAIN: /^[a-z-]+$/
};

export const LIMIT = 15;
export const SCROLL_PERCENT = 0.85;

export const SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Title', value: 'title' }
];
export const CONTRIBUTOR_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Firstname', value: 'firstName' },
  { name: 'Lastname', value: 'lastName' }
];
export const PAGES_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Title', value: 'title' },
  { name: 'Status', value: 'status' }
];
export const USERS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'First Name', value: 'firstName' },
  { name: 'Last Name', value: 'lastName' },
  { name: 'Email', value: 'email' }
];
export const VIDEO_PLATFORMS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Name', value: 'name' }
];

export const POSTS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Like Count', value: 'likeCount' }
];

export const COMMENTS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Like Count', value: 'likeCount' },
  { name: 'Star Count', value: 'starsCount' }
];

export const TAGS_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Name', value: 'name' }
];
export const TOPICS_SORT_FIELD = [
  ...TAGS_SORT_FIELD,
  { name: 'Key', value: 'key' }
];

export const ROLES_SORT_FIELD = [{ name: 'Created At', value: 'createdAt' }];

export const SUBSCRIPTION_SORT_FIELD = [
  ...TOPICS_SORT_FIELD,
  { name: 'Order', value: 'order' }
];

export const ADVERTISER_SORT_FIELD = [
  { name: 'Created At', value: 'createdAt' },
  { name: 'Name', value: 'name' },
  { name: 'Contact Name', value: 'contactName' },
  { name: 'Email', value: 'email' }
];

export const ORDER = [
  { name: 'Ascending', value: 'ASC' },
  { name: 'Descending', value: 'DESC' }
];
export const CREDIT_TYPE = [
  { name: 'Cast', value: 'CAST' },
  { name: 'Crew', value: 'CREW' }
];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' }
];

export const USER_ROLES = [
  {
    label: 'User',
    value: 'USER'
  },
  {
    label: 'Admin',
    value: 'ADMIN'
  },
  {
    label: 'Super Admin',
    value: 'SUPER_ADMIN'
  },
  {
    label: 'Workspace User',
    value: 'WORKSPACE_USER'
  }
];

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200
};

export const STATUS_TYPES = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED'
};

export const STATUS_OPTIONS = [
  { name: 'Draft', value: STATUS_TYPES.DRAFT },
  { name: 'Published', value: STATUS_TYPES.PUBLISHED }
];

export const UNPUBLISHED_STATUS = {
  name: 'Unlisted',
  value: STATUS_TYPES.UNPUBLISHED
};

export const LIST_TYPES = {
  GRID: 'grid',
  TABLE: 'table'
};

export const LIST_OPTIONS = [
  {
    name: 'Grid view',
    icon: <SquaresFour size={32} />,
    value: LIST_TYPES.GRID
  },
  {
    name: 'List view',
    icon: <ListBullets size={32} />,
    value: LIST_TYPES.TABLE
  }
];

export const PAGE_MODULE_TYPES = {
  VIDEO_CONTINUE_WATCHING_CAROUSEL: 'VIDEO_CONTINUE_WATCHING_CAROUSEL',
  VIDEO_WATCH_LATER_CAROUSEL: 'VIDEO_WATCH_LATER_CAROUSEL',
  VIDEO_CAROUSEL: 'VIDEO_CAROUSEL',
  VIDEO_LIST: 'VIDEO_LIST',
  VIDEO_GRID: 'VIDEO_GRID',
  FEATURED_VIDEO: 'FEATURED_VIDEO',
  FEATURED_VIDEO_SLIDER: 'FEATURED_VIDEO_SLIDER',
  COLLECTION_CAROUSEL: 'COLLECTION_CAROUSEL',
  COLLECTION_LIST: 'COLLECTION_LIST',
  COLLECTION_GRID: 'COLLECTION_GRID',
  FEATURED_COLLECTION: 'FEATURED_COLLECTION'
};

export const AUTO_GENERATED_SORT_BY_TYPES = {
  RECENT: 'RECENT',
  RANDOM: 'RANDOM',
  VIEWS: 'VIEWS',
  FORM_COMPLETION: 'FORM_COMPLETION',
  PLAYS: 'PLAYS'
};

export const MODULE_TYPES = {
  SETTINGS: 'SETTINGS',
  ADD: 'ADD',
  VIDEO_CONTINUE_WATCHING_CAROUSEL: 'VIDEO_CONTINUE_WATCHING_CAROUSEL',
  VIDEO_WATCH_LATER_CAROUSEL: 'VIDEO_WATCH_LATER_CAROUSEL',
  VIDEO_CAROUSEL: 'VIDEO_CAROUSEL',
  VIDEO_LIST: 'VIDEO_LIST',
  VIDEO_GRID: 'VIDEO_GRID',
  VIDEO_PLAYER: 'VIDEO_PLAYER',
  VIDEO_PLAYER_V2: 'VIDEO_PLAYER_V2',
  FEATURED_VIDEO: 'FEATURED_VIDEO',
  FEATURED_VIDEO_SLIDER: 'FEATURED_VIDEO_SLIDER',
  PODCAST_CONTINUE_LISTENING_CAROUSEL: 'PODCAST_CONTINUE_LISTENING_CAROUSEL',
  PODCAST_LISTEN_LATER_CAROUSEL: 'PODCAST_LISTEN_LATER_CAROUSEL',
  PODCAST_CAROUSEL: 'PODCAST_CAROUSEL',
  PODCAST_LIST: 'PODCAST_LIST',
  PODCAST_GRID: 'PODCAST_GRID',
  PODCAST_PLAYER: 'PODCAST_PLAYER',
  FEATURED_PODCAST: 'FEATURED_PODCAST',
  FEATURED_PODCAST_SLIDER: 'FEATURED_PODCAST_SLIDER',
  COLLECTION_CAROUSEL: 'COLLECTION_CAROUSEL',
  COLLECTION_LIST: 'COLLECTION_LIST',
  COLLECTION_GRID: 'COLLECTION_GRID',
  FEATURED_COLLECTION: 'FEATURED_COLLECTION',
  FEATURED_COLLECTION_SLIDER: 'FEATURED_COLLECTION_SLIDER',
  CONTRIBUTOR_CAROUSEL: 'CONTRIBUTOR_CAROUSEL',
  CONTRIBUTOR_GRID: 'CONTRIBUTOR_GRID',
  CONTRIBUTOR_LIST: 'CONTRIBUTOR_LIST',
  FEATURED_CONTRIBUTOR: 'FEATURED_CONTRIBUTOR',
  TOPIC_CAROUSEL: 'TOPIC_CAROUSEL',
  TOPIC_GRID: 'TOPIC_GRID',
  TOPIC_LIST: 'TOPIC_LIST',
  FEATURED_TOPIC: 'FEATURED_TOPIC',
  TAG_CAROUSEL: 'TAG_CAROUSEL',
  TAG_CLOUD: 'TAG_CLOUD',
  FEATURED_TAG: 'FEATURED_TAG',
  CTA_BLOCK: 'CTA_BLOCK',
  CTA_BANNER: 'CTA_BANNER',
  CTA_GRID: 'CTA_GRID',
  CTA_CAROUSEL: 'CTA_CAROUSEL',
  CTA_BUTTON_LIST: 'CTA_BUTTON_LIST',
  DONATION: 'DONATION',
  CTA_BANNER_WITH_TEXT: 'CTA_BANNER_WITH_TEXT',
  COPY_HEAVY: 'COPY_HEAVY',
  COPY_HEAVY_HERO: 'COPY_HEAVY_HERO',
  QUOTE: 'QUOTE',
  HERO: 'HERO',
  INFORMATION: 'INFORMATION',
  LEAD_GEN: 'LEAD_GEN',
  FAQ: 'FAQ',
  CONTACT_US: 'CONTACT_US',
  SPACER: 'SPACER',
  FORM_DISPLAY: 'FORM_DISPLAY',
  FORM_CAROUSEL: 'FORM_CAROUSEL',
  SOURCE_CAROUSEL: 'SOURCE_CAROUSEL',
  SOURCE_LIST: 'SOURCE_LIST',
  SOURCE_GRID: 'SOURCE_GRID',
  FEATURED_SOURCE: 'FEATURED_SOURCE',
  ARTICLE_LIST: 'ARTICLE_LIST',
  ARTICLE_LIST_V2: 'ARTICLE_LIST_V2',
  ARTICLE_CAROUSEL: 'ARTICLE_CAROUSEL',
  ARTICLE_CAROUSEL_V2: 'ARTICLE_CAROUSEL_V2',
  FEATURED_ARTICLE: 'FEATURED_ARTICLE',
  FEATURED_ARTICLE_V2: 'FEATURED_ARTICLE_V2',
  ARTICLE_FEATURED_CAROUSEL: 'ARTICLE_FEATURED_CAROUSEL',
  ARTICLE_GRID: 'ARTICLE_GRID',
  FEATURED_ARTICLE_SLIDER: 'FEATURED_ARTICLE_SLIDER',
  ARTICLE_DISPLAY: 'ARTICLE_DISPLAY',
  POST_CAROUSEL: 'POST_CAROUSEL',
  POST_LIST: 'POST_LIST',
  POST_DETAIL: 'POST_DETAIL',
  COLLECTION_ITEM_GRID: 'COLLECTION_ITEM_GRID',
  COLLECTION_ITEM_LIST: 'COLLECTION_ITEM_LIST',
  COLLECTION_ITEM_CAROUSEL: 'COLLECTION_ITEM_CAROUSEL',
  FEATURED_AD_INSERT: 'FEATURED_AD_INSERT',
  PRAYER_CAROUSEL: 'PRAYER_CAROUSEL',
  LOOP_ARTICLE_LIST: 'LOOP_ARTICLE_LIST',
  FEATURED_PRODUCT: 'FEATURED_PRODUCT',
  COLLECTION_DETAIL_LIST: 'COLLECTION_DETAIL_LIST',
  COLLECTION_DETAIL_GRID: 'COLLECTION_DETAIL_GRID'
};

export const DAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday'
};

export const TABLE_LOCATION_TYPES = {
  PHYSICAL: 'Physical',
  REMOTE: 'Online'
};

export const THEMES = {
  NEUTRAL_300: 'NEUTRAL_300',
  NEUTRAL_400: 'NEUTRAL_400',
  NEUTRAL_900: 'NEUTRAL_900',
  NEUTRAL_1000: 'NEUTRAL_1000',
  BLUE: 'BLUE',
  BLUE_SHADE: 'BLUE_SHADE',
  BLUE_TINT: 'BLUE_TINT',
  MOSS: 'MOSS',
  MOSS_TINT: 'MOSS_TINT',
  YELLOW: 'YELLOW'
};

export const THEME_PICKER_COLORS = {
  [THEMES.NEUTRAL_300]: {
    backgroundColor: 'bg-n-300',
    textLight: false
  },
  [THEMES.NEUTRAL_400]: {
    backgroundColor: 'bg-n-400',
    textLight: false
  },
  [THEMES.NEUTRAL_900]: {
    backgroundColor: 'bg-n-900',
    textLight: true
  },
  [THEMES.NEUTRAL_1000]: {
    backgroundColor: 'bg-n-1000',
    textLight: true
  },
  [THEMES.BLUE]: {
    backgroundColor: 'bg-blue-2',
    textLight: false
  },
  [THEMES.BLUE_SHADE]: {
    backgroundColor: 'bg-blue-3',
    textLight: true
  },
  [THEMES.BLUE_TINT]: {
    backgroundColor: 'bg-blue',
    textLight: false
  },
  [THEMES.MOSS]: {
    backgroundColor: 'bg-moss-2',
    textLight: false
  },
  [THEMES.MOSS_TINT]: {
    backgroundColor: 'bg-moss',
    textLight: false
  },
  [THEMES.YELLOW]: {
    backgroundColor: 'bg-yellow-2',
    textLight: false
  }
};

export const ALIGNMENTS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};

export const PAYMENT_STATUS = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED'
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  PAYMENT_DUE: 'PAYMENT_DUE'
};

export const DONATION_TYPES = {
  ONE_TIME: 'ONE_TIME',
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'ANNUALLY'
};

export const STATUS_COLORS = {
  PENDING: '#0958D9',
  PROCESSING: '#FAAD14',
  PAYMENT_DUE: '#FAAD14',
  EXPORTING: '#FAAD14',
  ERRORED: '#FF4D4F',
  FAILED: '#FF4D4F',
  CANCELLED: '#FF4D4F',
  PARTIALLY_FAILED: '#FF4D4F',
  READY: '#52C41A',
  SUCCESS: '#52C41A',
  ACTIVE: '#52C41A',
  COMPLETED: '#52C41A'
};

export const DATA_COLLECTION_TYPE_KEYS = {
  NEWS_LETTER: 'NEWS_LETTER',
  CONTACT_INQUIRY: 'CONTACT_INQUIRY'
};

export const STATIC_PAGES = [
  { label: 'Videos', value: 'videos' },
  { label: 'Podcasts', value: 'podcasts' },
  { label: 'Articles', value: 'articles' },
  { label: 'Collections', value: 'collections' },
  { label: 'Contributors', value: 'contributors' },
  { label: 'Topics', value: 'topics' },
  { label: 'Tags', value: 'tags' },
  { label: 'Forms', value: 'forms' },
  { label: 'Login', value: 'login' },
  { label: 'Signup', value: 'signup' },
  { label: 'Forgot Password', value: 'forgot-password' },
  { label: 'Terms & Conditions', value: 'terms-and-conditions' },
  { label: 'Privacy Policy', value: 'privacy-policy' },
  { label: 'More', value: 'more' },
  { label: 'Account Settings', value: 'settings' },
  { label: 'Change Password', value: 'change-password' },
  { label: 'About', value: 'about' },
  { label: 'Notifications', value: 'notifications' },
  { label: 'My Library', value: 'my-library' },
  { label: 'Prayers', value: 'prayers' },
  { label: 'Spiritual Profile', value: 'spiritual-profile' }
];

export const RATIOS = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

export const SPACER_OPTIONS = [
  { label: '0px', value: 's-0' },
  { label: '5px', value: 's-5' },
  { label: '10px', value: 's-10' },
  { label: '15px', value: 's-15' },
  { label: '20px', value: 's-20' },
  { label: '25px', value: 's-25' },
  { label: '30px', value: 's-30' },
  { label: '35px', value: 's-35' },
  { label: '40px', value: 's-40' },
  { label: '45px', value: 's-45' },
  { label: '50px', value: 's-50' }
];

export const WEB_ROUTES = {
  CONTRIBUTOR: '/contributors',
  COLLECTION: '/collections',
  TAG: '/tags',
  TOPIC: '/topics',
  CUSTOM: '',
  VIDEO: '/videos',
  ARTICLE: '/articles',
  FORM: '/forms',
  PODCAST: '/podcasts'
};

export const TEMPLATE_OPTION = {
  label: '*',
  value: '*'
};

export const DATE_FORMAT = 'M/D/YY';
export const FULL_DATE_TIME_FORMAT = 'LT L';
export const IMPORT_VIDEO_DATE_FORMAT = 'll';

export const EXPORT_JOB_TYPES = {
  USER: 'USER',
  ADMIN_USER: 'ADMIN_USER',
  FORM_RESPONSE: 'FORM_RESPONSE',
  UI_LABEL: 'UI_LABEL'
};

export const TEXT_ALIGNMENTS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT',
  JUSTIFY: 'JUSTIFY'
};

export const TEXT_ALIGNMENTS_CLASS = {
  [TEXT_ALIGNMENTS.LEFT]: 'text-left',
  [TEXT_ALIGNMENTS.CENTER]: 'text-center',
  [TEXT_ALIGNMENTS.RIGHT]: 'text-right',
  [TEXT_ALIGNMENTS.JUSTIFY]: 'text-justify'
};

export const GRID_TYPE = {
  BUTTON: 'BUTTON',
  CARD: 'CARD'
};

export const PAGE_TYPES = {
  CONTRIBUTOR: 'CONTRIBUTOR',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC',
  VIDEO: 'VIDEO',
  CUSTOM: 'CUSTOM',
  PODCAST: 'PODCAST',
  FORM: 'FORM',
  ARTICLE: 'ARTICLE',
  STATIC: 'STATIC',
  POST: 'POST'
};

export const PAGE_TYPES_OPTIONS = [
  { label: 'Contributor', value: PAGE_TYPES.CONTRIBUTOR },
  { label: 'Collection', value: PAGE_TYPES.COLLECTION },
  { label: 'Tag', value: PAGE_TYPES.TAG },
  { label: 'Topic', value: PAGE_TYPES.TOPIC },
  { label: 'Video', value: PAGE_TYPES.VIDEO },
  { label: 'Custom', value: PAGE_TYPES.CUSTOM },
  { label: 'Static', value: PAGE_TYPES.STATIC },
  { label: 'Podcast', value: PAGE_TYPES.PODCAST },
  { label: 'Article', value: PAGE_TYPES.ARTICLE },
  { label: 'Form', value: PAGE_TYPES.FORM }
];

export const CLOUD_FRONT_CACHE_KEYS = {
  MENU: 'MENU',
  UI_LABELS: 'UI_LABELS',
  OG_IMAGES: 'OG_IMAGES'
};

export const EDITOR_MODULES = {
  toolbar: [
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'link'],
    [
      { align: [] },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ]
  ]
};

export const VIDEO_RESOURCES_TYPES = {
  QUESTIONS: 'questions',
  QUOTES: 'quotes',
  LINKS: 'links',
  SCRIPTURE: 'scripture'
};

export const PAGE_TYPE_LABELS = {
  CONTRIBUTOR: 'CONTRIBUTOR',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC',
  VIDEO: 'VIDEO',
  CUSTOM: 'CUSTOM',
  PODCAST: 'PODCAST',
  FORM: 'FORM',
  ARTICLE: 'ARTICLE',
  POST: 'POST'
};

export const UI_LABEL_TYPES = {
  STRING: 'STRING',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  HTML: 'HTML',
  JSON: 'JSON'
};

export const UI_LABEL_TYPES_OPTIONS = [
  {
    label: 'String',
    value: UI_LABEL_TYPES.STRING
  },
  { label: 'Image', value: UI_LABEL_TYPES.IMAGE },
  { label: 'Video', value: UI_LABEL_TYPES.VIDEO },
  { label: 'Html', value: UI_LABEL_TYPES.HTML },
  { label: 'JSON', value: UI_LABEL_TYPES.JSON }
];

export const AUDIO_SERVICE_TYPES = {
  MANUAL: 'MANUAL',
  PEERTUBE: 'PEERTUBE'
};

export const allowedAudio = ['mp3'];
export const allowedImagesAll = ['jpg', 'jpeg', 'png', 'heic', 'gif'];
export const allowedImages = ['jpg', 'jpeg', 'png'];

export const AUTO_GENERATED_TYPES = {
  TOPIC: 'TOPIC',
  TAG: 'TAG',
  CONTRIBUTOR: 'CONTRIBUTOR',
  COLLECTION: 'COLLECTION',
  SOURCE: 'SOURCE',
  SHOW: 'SHOW',
  PODCAST: 'PODCAST',
  MUSIC: 'MUSIC',
  NEWS: 'NEWS',
  LIFESTYLE: 'LIFESTYLE',
  ARTICLE: 'ARTICLE'
};

export const AUTO_GENERATED_OPTIONS = [
  {
    label: 'Topic',
    value: AUTO_GENERATED_TYPES.TOPIC
  },
  {
    label: 'Tag',
    value: AUTO_GENERATED_TYPES.TAG
  },
  {
    label: 'Contributor',
    value: AUTO_GENERATED_TYPES.CONTRIBUTOR
  },
  {
    label: 'Collection',
    value: AUTO_GENERATED_TYPES.COLLECTION
  },
  {
    label: 'Source',
    value: AUTO_GENERATED_TYPES.SOURCE
  }
];

export const AUTO_GENERATED_SORT_BY_OPTIONS = [
  { label: 'Newest First', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random Selection', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Most Viewed', value: AUTO_GENERATED_SORT_BY_TYPES.VIEWS }
];

export const getAutoGeneratedByIdProps = {
  [AUTO_GENERATED_TYPES.TOPIC]: {
    query: GET_AUTOGENERATE_BY_TOPIC,
    dataSelector: (data) =>
      data?.topicsAdmin?.topics?.map(({ id, name }) => ({
        label: name,
        value: id
      })) || [],
    countSelector: (data) => data?.topicsAdmin?.count ?? 0,
    keys: {
      data: 'topicsAdmin',
      records: 'topics',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.TAG]: {
    query: GET_AUTOGENERATE_BY_TAGS,
    dataSelector: (data) =>
      data?.tagsAdmin?.tags?.map(({ id, name }) => ({
        label: name,
        value: id
      })) || [],
    countSelector: (data) => data?.tagsAdmin?.count ?? 0,
    keys: {
      data: 'tagsAdmin',
      records: 'tags',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.CONTRIBUTOR]: {
    query: GET_AUTOGENERATE_BY_CONTRIBUTORS,
    dataSelector: (data) =>
      data?.contributorsAdmin?.contributors?.map(
        ({ id, firstName, lastName }) => ({
          label: `${firstName} ${lastName}`,
          value: id
        })
      ) || [],
    countSelector: (data) => data?.contributorsAdmin?.count ?? 0,
    keys: {
      data: 'contributorsAdmin',
      records: 'contributors',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.COLLECTION]: {
    query: GET_AUTOGENERATE_BY_COLLECTIONS,
    dataSelector: (data) =>
      data?.collectionsAdmin?.collections?.map(({ id, title }) => ({
        label: title,
        value: id
      })) || [],
    countSelector: (data) => data?.collectionsAdmin?.count ?? 0,
    keys: {
      data: 'collectionsAdmin',
      records: 'collections',
      count: 'count'
    }
  },
  [AUTO_GENERATED_TYPES.SOURCE]: {
    query: GET_AUTO_GENERATED_BY_SOURCE,
    dataSelector: (data) =>
      data?.sourcesAdmin?.sources?.map(({ id, name }) => ({
        label: name,
        value: id
      })) || [],
    countSelector: (data) => data?.sourcesAdmin?.count ?? 0,
    keys: {
      data: 'sourcesAdmin',
      records: 'sources',
      count: 'count'
    }
  }
};

export const getAutoGenerateByDataValue = {
  [AUTO_GENERATED_TYPES.TOPIC]: ({ topicId, topicName }) => ({
    label: topicName,
    value: topicId
  }),
  [AUTO_GENERATED_TYPES.TAG]: ({ tagId, tagName }) => ({
    label: tagName,
    value: tagId
  }),
  [AUTO_GENERATED_TYPES.CONTRIBUTOR]: ({
    contributorId,
    contributorFirstName,
    contributorLastName
  }) => ({
    label: `${contributorFirstName} ${contributorLastName}`,
    value: contributorId
  }),
  [AUTO_GENERATED_TYPES.COLLECTION]: ({ collectionId, collectionTitle }) => ({
    label: collectionTitle,
    value: collectionId
  }),
  [AUTO_GENERATED_TYPES.SOURCE]: ({ sourceId, sourceName }) => ({
    label: sourceName,
    value: sourceId
  })
};

export const MAX_LENGTHS = {
  TITLE: 255,
  DESCRIPTION: 1000,
  NAME: 50,
  CTA: 25,
  KEY: 25,
  PHONE_NUMBER: 20,
  FORM_INPUT: 100,
  JSON: 5000
};

export const ASSET_TYPE = {
  IMAGE: 'Image',
  VIDEO: 'Video',
  TEXT: 'Text',
  AUDIO: 'Audio',
  DOCUMENT: 'Document',
  ICON: 'Icon'
};

export const WORKSPACE_ROLE_PERMISSION = {
  ASSET_MANAGEMENT: 'ASSET_MANAGEMENT', // assets
  LABEL_MANAGEMENT: 'LABEL_MANAGEMENT', // labels
  CONTENT_MANAGEMENT: 'CONTENT_MANAGEMENT', // content units
  PAGE_MANAGEMENT: 'PAGE_MANAGEMENT', // pages
  USER_MANAGEMENT: 'USER_MANAGEMENT', // users , donations
  APP_MANAGEMENT: 'APP_MANAGEMENT', // app
  ROLE_MANAGEMENT: 'ROLE_MANAGEMENT', // roles
  WORKSPACE_USER_MANAGEMENT: 'WORKSPACE_USER_MANAGEMENT', // workspace users
  REPORT_MANAGEMENT: 'REPORT_MANAGEMENT', // reports
  PLATFORM_MANAGEMENT: 'PLATFORM_MANAGEMENT', // platforms
  UI_CONFIG_MANAGEMENT: 'UI_CONFIG_MANAGEMENT', // menus and ui labels
  SUBSCRIPTION_MANAGEMENT: 'SUBSCRIPTION_MANAGEMENT' // subscription plans
};

export const WORKSPACE_ROLE_LEVEL = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  NONE: 'NONE'
};

export const PERMISSIONS = {
  NONE: {
    label: 'No Access',
    order: 1,
    isActive: true,
    isDefault: false
  },
  VIEW: {
    label: 'View',
    order: 2,
    isActive: true,
    isDefault: true
  },
  EDIT: {
    label: 'Add/Edit',
    order: 3,
    isActive: true,
    isDefault: false
  },
  DELETE: {
    label: 'Full Access',
    order: 4,
    isActive: true,
    isDefault: false
  }
};
export const PROVIDER_TYPES = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  STORAGE: 'STORAGE',
  EMAIL: 'EMAIL',
  AUTHENTICATION: 'AUTHENTICATION',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  PAYMENT: 'PAYMENT',
  SMS: 'SMS',
  SMS_OTP: 'SMS_OTP',
  MODERATION: 'MODERATION',
  USER_VIDEO: 'USER_VIDEO',
  USER_AUDIO: 'USER_AUDIO'
};

export const PROVIDER_TYPES_OPTIONS = [
  { label: 'Authentication', value: PROVIDER_TYPES.AUTHENTICATION },
  { label: 'Storage', value: PROVIDER_TYPES.STORAGE },
  { label: 'Video', value: PROVIDER_TYPES.VIDEO },
  { label: 'Audio', value: PROVIDER_TYPES.AUDIO },
  { label: 'Email', value: PROVIDER_TYPES.EMAIL },
  { label: 'Payment', value: PROVIDER_TYPES.PAYMENT },
  { label: 'SMS', value: PROVIDER_TYPES.SMS },
  { label: 'OTP', value: PROVIDER_TYPES.SMS_OTP },
  { label: 'Moderation', value: PROVIDER_TYPES.MODERATION },
  { label: 'User Video', value: PROVIDER_TYPES.USER_VIDEO },
  { label: 'User Audio', value: PROVIDER_TYPES.USER_AUDIO },
  { label: 'Push Notification', value: PROVIDER_TYPES.PUSH_NOTIFICATION }
];

export const VIDEO_UPLOAD_PLATFORMS = {
  BRIGHTCOVE: 'BRIGHTCOVE',
  BUNNY_STREAM: 'BUNNY_STREAM',
  MUX: 'MUX',
  PEERTUBE: 'PEERTUBE'
};

export const AUDIO_UPLOAD_PLATFORMS = {
  PEERTUBE: 'PEERTUBE'
};

export const PERMISSION_ROUTES = {
  ASSET_MANAGEMENT: ROUTES?.ASSETS_IMAGES,
  LABEL_MANAGEMENT: ROUTES?.LABELS_TOPICS,
  CONTENT_MANAGEMENT: ROUTES?.CONTENTS_VIDEOS,
  PAGE_MANAGEMENT: ROUTES?.UI_CONFIG_PAGES,
  USER_MANAGEMENT: ROUTES?.USERS,
  ROLE_MANAGEMENT: ROUTES?.WS_ROLES,
  REPORT_MANAGEMENT: ROUTES?.REPORTS_LEAD_GENS,
  PLATFORM_MANAGEMENT: ROUTES?.WS_SERVICES,
  UI_CONFIG_MANAGEMENT: ROUTES?.UI_CONFIG_MENUS
};

export const PERMISSIONS_ORDER = {
  CONTENT_MANAGEMENT: 1,
  ASSET_MANAGEMENT: 2,
  LABEL_MANAGEMENT: 3,
  PLATFORM_MANAGEMENT: 4,
  USER_MANAGEMENT: 5,
  ROLE_MANAGEMENT: 6,
  WORKSPACE_USER_MANAGEMENT: 7,
  REPORT_MANAGEMENT: 8,
  UI_CONFIG_MANAGEMENT: 9,
  PAGE_MANAGEMENT: 10
};

export const STATIC_DATA_KEYS = {
  MENU: 'menu-data',
  UI_LABELS: 'ui-labels-data',
  CONFIGS: 'configs-data'
};

export const FORM_TYPES = {
  PETITION: 'PETITION',
  CONTACT_US: 'CONTACT_US',
  OTHER: 'OTHER',
  POLL: 'POLL'
};

export const FORM_TYPE_OPTIONS = [
  {
    label: 'Petition',
    value: FORM_TYPES.PETITION
  },
  {
    label: 'Contact Us',
    value: FORM_TYPES.CONTACT_US
  },
  {
    label: 'Poll',
    value: FORM_TYPES.POLL
  },
  {
    label: 'Other',
    value: FORM_TYPES.OTHER
  }
];

export const FORM_FIELD_TYPES = [
  {
    label: 'String',
    value: 'string'
  },
  {
    label: 'Text',
    value: 'text'
  },
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Dropdown',
    value: 'dropdown'
  },
  {
    label: 'Radio',
    value: 'radio'
  }
];

export const FORM_MESSAGE_TYPES = [
  {
    label: 'Submitted',
    value: 'SUBMITTED'
  },
  {
    label: 'Success',
    value: 'SUCCESS'
  },
  {
    label: 'Failed',
    value: 'FAILED'
  }
];

export const FORM_CTA_TYPES = [
  {
    label: 'Primary',
    value: 'PRIMARY'
  },
  {
    label: 'Secondary',
    value: 'SECONDARY'
  }
];

export const CTA_ACTION_TYPES = [
  {
    label: 'External',
    value: 'EXTERNAL'
  },
  {
    label: 'Submit',
    value: 'SUBMIT'
  }
];

export const DEFAULT_APP_COLORS = {
  neutral0: '#fff8e8',
  neutral100: '#fff6e6',
  neutral200: '#fef3e3',
  neutral300: '#fcf1e1',
  neutral400: '#f9eede',
  neutral500: '#7f7f7f',
  neutral600: '#666666',
  neutral700: '#4c4c4c',
  neutral800: '#333333',
  neutral900: '#191919',
  neutral1000: '#000000',
  footerBg: '#000000',
  ivory: '#f9eede',
  white90: '#eeeeee',
  white80: '#ebebeb',
  white70: '#e9e9e9',
  black90: '#1a1a1a',
  black80: '#333333',
  black70: '#4d4d4d',
  red: '#ff7b4c',
  red2: '#F15B7F',
  red3: '#b02501',
  yellow: '#ffe546',
  yellow2: '#FF7B5C',
  yellow3: '#9a6f07',
  blue: '#e1fdff',
  blue2: '#47BFFF',
  blue3: '#6fa1a6',
  moss: '#d7c895',
  moss2: '#4BDFCE',
  moss3: '#655c3b',
  borderDark: '#ddd3c5',
  borderLight: '#e9e9e9',
  colorPrimary: '#1677FF',
  colorBgBase: '#FFFFFF',
  colorTextBase: '#000000',
  colorBgContainer: '#F5F5F5',
  colorPrimaryText: '#AAAAAA',
  colorBorder: '#E8E9EAe'
};

export const COLLECTION_ITEM_TYPES = [
  {
    label: 'Video',
    value: 'VIDEO'
  },
  {
    label: 'Podcast',
    value: 'PODCAST'
  },
  {
    label: 'Article',
    value: 'ARTICLE'
  },
  {
    label: 'Form',
    value: 'FORM'
  }
];

export const LOGO_SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE'
};

export const LOGO_SIZES_OPTIONS = [
  { label: 'Small', value: LOGO_SIZES.SMALL },
  { label: 'Medium', value: LOGO_SIZES.MEDIUM },
  { label: 'Large', value: LOGO_SIZES.LARGE }
];

export const LOGO_POSITIONS = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
};

export const LOGO_POSITIONS_OPTIONS = [
  { label: 'Left', value: LOGO_POSITIONS.LEFT },
  { label: 'Middle', value: LOGO_POSITIONS.CENTER },
  { label: 'Right', value: LOGO_POSITIONS.RIGHT }
];

export const SHOW_MENU = {
  YES: 'YES',
  NO: 'NO'
};

export const SHOW_MENU_OPTIONS = [
  { label: 'Yes', value: SHOW_MENU.YES },
  { label: 'No', value: SHOW_MENU.NO }
];

export const DOMAIN_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  ERRORED: 'ERRORED',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  READY: 'READY'
};

export const PLAN_FREQUENCY_OPTIONS = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Annually', value: 'ANNUALLY' }
];

export const PLAN_TYPE_KEYS = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING'
};

export const PLAN_TYPE_OPTIONS = [
  { label: 'One Time', value: PLAN_TYPE_KEYS.ONE_TIME },
  { label: 'Recurring', value: PLAN_TYPE_KEYS.RECURRING }
];

export const UI_LABEL_TYPE = [
  { label: 'UI', value: 'UI' },
  { label: 'Message', value: 'MESSAGE' }
];

export const COMMENTS_INSTANCE_TYPES = {
  POST: 'POST',
  VIDEO: 'VIDEO',
  PODCAST: 'PODCAST',
  ARTICLE: 'ARTICLE'
};

export const PERMISSION_TYPE = {
  READ: 'READ',
  WRITE: 'WRITE'
};

export const CONTENT_SELECTION_KEYS = {
  VIDEO: 'VIDEO',
  PODCAST: 'PODCAST',
  ARTICLE: 'ARTICLE',
  FORM: 'FORM',
  AD: 'AD'
};

export const CONTENT_SELECTION = [
  { label: 'Video', value: CONTENT_SELECTION_KEYS.VIDEO },
  { label: 'Podcast', value: CONTENT_SELECTION_KEYS.PODCAST },
  { label: 'Article', value: CONTENT_SELECTION_KEYS.ARTICLE },
  { label: 'Form', value: CONTENT_SELECTION_KEYS.FORM },
  { label: 'Ad', value: CONTENT_SELECTION_KEYS.AD }
];

export const getContentQueries = {
  [CONTENT_SELECTION_KEYS.VIDEO]: {
    query: GET_VIDEOS_MODULES,
    variablesSelector: (
      offset,
      limit,
      search,
      status = STATUS_TYPES.PUBLISHED
    ) => ({
      filter: { search, skip: offset, limit, status }
    }),
    dataSelector: (data) => data?.videosAdmin?.videos ?? [],
    keys: {
      data: 'videosAdmin',
      records: 'videos',
      count: 'count'
    }
  },
  [CONTENT_SELECTION_KEYS.PODCAST]: {
    query: GET_PODCASTS_MODULE,
    variablesSelector: (
      offset,
      limit,
      search,
      status = STATUS_TYPES.PUBLISHED
    ) => ({
      filter: { search, skip: offset, limit, status }
    }),
    dataSelector: (data) => data?.podcastsAdmin?.podcasts ?? [],
    keys: {
      data: 'podcastsAdmin',
      records: 'podcasts',
      count: 'count'
    }
  },
  [CONTENT_SELECTION_KEYS.ARTICLE]: {
    query: GET_ARTICLES_MODULE,
    variablesSelector: (
      offset,
      limit,
      search,
      status = STATUS_TYPES.PUBLISHED
    ) => ({
      filter: { search, skip: offset, limit, status }
    }),
    dataSelector: (data) => data?.articlesAdmin?.articles ?? [],
    keys: {
      data: 'articlesAdmin',
      records: 'articles',
      count: 'count'
    }
  },
  [CONTENT_SELECTION_KEYS.FORM]: {
    query: GET_FORMS,
    variablesSelector: (
      offset,
      limit,
      search,
      status = STATUS_TYPES.PUBLISHED
    ) => ({
      filter: { search, skip: offset, limit, status }
    }),
    dataSelector: (data) => data?.formsAdmin?.forms ?? [],
    keys: {
      data: 'formsAdmin',
      records: 'forms',
      count: 'count'
    }
  },
  [CONTENT_SELECTION_KEYS.AD]: {
    query: GET_ADS,
    variablesSelector: (offset, limit, search) => ({
      filter: { search, skip: offset, limit }
    }),
    dataSelector: (data) => data?.adsAdmin?.ads ?? [],
    keys: {
      data: 'adsAdmin',
      records: 'ads',
      count: 'count'
    }
  }
};

export const BASE64_IMAGES = {
  FORM:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAC0CAMAAACdQlHaAAAAJ1BMVEXv8PfR1d7Z3OTn6fDf4urj5e3V2eHr7fTd4OjZ3eXW2eLn6PDe4elPl96qAAAD2UlEQVR4nO2d7baqIBRFSUU07/s/780PcINWCnZk6Zq/clB7MAMUUFApQgghhBBCCCGEEEJuj6mej60828qcnd9UzHbdSVqfneU06p2+vXJ5dqYT0Pt9X/w7O9vx7K3QE8XZ+Y4mzhfYOFYY1tjl/zPXMZ5y//zyNWssr9mYxjuFlWqEMWInZLewqrCN9wtL4yeecYSw7KzUcMYxwtDGUcLSGK1WxwkDG0cKqxLVOFZYdaDG0cKoxvHCslY/cIwThGUZ4xinCKtynh+qu59l8ViShFWHZ5wmrMxcqUH6XInC0vhbjDxIFZbz2tVPcngwycLSGKFSpwsLY4QiPkB4NkZoxUcIz30ugDp9iLBLBrjPtlP4C82f5DmJY4UBpqopvE710fOCwuaj5wWFNxbxhYS3GV9JWJnq++3USwlvCUPh/KBwUhgK5weFk8JQOD9CYfGQzgbKIAyecPdObZ06CIMnvPfhWuOHoXB+UHgfQRg84Y0zGxb8k5bS7ZcHiSWtCcIACqeFoXB+UDgpDIXzg8JJYQCF73Ydvl1P63Z9aY6WKHwx4dvNad1u1jIxDIXzg8JJYSicHxROCkPh/KBwUhgK5weFk8JQOD8onBQGR/ggKJwfEZseYgtvXJC0EYDlpeV3ix0ALLZU7YG+AAWsjjTG8FWqa/bcIn0L/ja9hBBCCCGEpGCHi2fn48+g8NWh8NWh8NWh8NWh8DUpq+LxqAtt1oRN1ww3ZIpqucGw0U2/1VJRVBpoltbMN5j0QtjIh+Rrfx/HUk7iA2zxOOE99h9uYGmC24vzUiVl/D3FYEp4fZnDlLhcx+ReSGOK1V9kz5ulWe8T2+mHgS/AreER1wzrSosmOSZaqVqXpavAY2Od6/PrhPU656E0YVeGwxl4rqZDoj2DjQ3XfrX2fmcbNUoTtgU1rU8xnrAt8UnGtvb+v7GrXwB2o/UpghaopfD02d73NeJYljYUskL3yOuw/exWJ83/jnVHabkOm3HXAqWwDhMrl7hIQmHRsVoTdol6Ifz3OU7ktsK7qnQNXKVtG3YnLVl0H05aNgnupLV4DLYRwvIyJI+16ITAFXHjF5Xf8bD9rqDjYURSi2bsVkgPA/h55DQk2sNxhOSO5MGjGFuDgXnN9jze9V66OybKwYNbSj2oyXFyURQ1zmjpzSL4MXFteDi16MVTqecp7CTYC73ysr+cHXCjhTDppOxH4C37t1NYNjEsSHEdCuoGTCOWOX+dnbqgvIz8P/wXDntJUJdko9u6n7noC6lclFc/FfuYZnEXP5xmcAsN8o44QgghhBBCCCGEEEJ+zX+P7B5WzEA+PQAAAABJRU5ErkJggg==',
  ARTICLE:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAARVBMVEXx8vSYmZqVlpf19viRkpPp6uy7vL7Cw8XW19m+v8Hv7/Hw8PLs7O+2t7ji4+Xf4OKpqqvKy82hoqOkpabR0dOwsbKMjY4UPJs7AAACz0lEQVR4nO3bjXKiMBSGYcgBsVgVUff+L7UgCOEvWMmOc9L3mZ12ttXMnG8h5oRsFAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABA6CT1K5dPV/Rrck/8Mmd1IXwnsV/mS18GhgyqDEweHQ6PP/0X+7v982j0+9GLJTNKMzh4G01vBrm30cgghAyktmk07RlImhXFcb+pAuUZyCUxlaTYcmfozqCKoP18v24YTXcGabdWSi7vF6E6A7n068Xyr2Zw7DPY8FmpO4O7lcH326PpziCzeqf3R1OdQXTqemizcxUhuWuTRHcG1oTguhXkmCR3x69VZ1BvKD2ugvjk+neupw3HCkJ5BpHcrlUCX85LvVlILd8t2jOoWqZD7uyZ5DlpJEtdhfoM1pXdvJnOvyD4DKxp01znCw09A7lZW9ALlQaegZwGO9DzU0LgGUTlcBd+9l1hZyC70YMIU8zUGnQGsp88jzLZtNigMhivE9KZx1HJ9MliQBlItB/WJ9fZR3KT0cLJQNI4GeytDjpr6264j8sNJ4M8rjujsvv7zGTQ3g3jrcdgMmj3lMzxWUw+n0D9mlGTGUoG3S57cmuqkWL5+Xw5HC2QDKz1oEnrciRzHNPoL5bmzWFk0DeHTWckZ+dJlefF0ggjA3uTvdkscSXQXSzPd4eQwaA5fHRG95XDOqYMLYPJenB+ZTAIwdpZCyEDKcclv3Bky2qjA8hg0hy+pn8wpT+DxfXgWgbdzpr+DN4+q9i10eozWGgOX/Fso7VnsNAcvqgbQ3MGK+vBFW0brTyD5ebwtRAuTW+hOYPV9eBqCPWUoDqDrmHeINKdwbbJoL0QqilBcwZ5/Diguc2/i2jO4LT3QvF10BzW9kJrBnHmT6E1g+1TQU/j/2eKfAZQ23Lc+UPkvPMr+3RFb/A3H7Y+XRAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACA/+wH83QnC1gJfXYAAAAASUVORK5CYII=',
  OTHERS:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAARVBMVEXx8vSYmZqVlpf19viRkpPp6uy7vL7Cw8XW19m+v8Hv7/Hw8PLs7O+2t7ji4+Xf4OKpqqvKy82hoqOkpabR0dOwsbKMjY4UPJs7AAACz0lEQVR4nO3bjXKiMBSGYcgBsVgVUff+L7UgCOEvWMmOc9L3mZ12ttXMnG8h5oRsFAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABA6CT1K5dPV/Rrck/8Mmd1IXwnsV/mS18GhgyqDEweHQ6PP/0X+7v982j0+9GLJTNKMzh4G01vBrm30cgghAyktmk07RlImhXFcb+pAuUZyCUxlaTYcmfozqCKoP18v24YTXcGabdWSi7vF6E6A7n068Xyr2Zw7DPY8FmpO4O7lcH326PpziCzeqf3R1OdQXTqemizcxUhuWuTRHcG1oTguhXkmCR3x69VZ1BvKD2ugvjk+neupw3HCkJ5BpHcrlUCX85LvVlILd8t2jOoWqZD7uyZ5DlpJEtdhfoM1pXdvJnOvyD4DKxp01znCw09A7lZW9ALlQaegZwGO9DzU0LgGUTlcBd+9l1hZyC70YMIU8zUGnQGsp88jzLZtNigMhivE9KZx1HJ9MliQBlItB/WJ9fZR3KT0cLJQNI4GeytDjpr6264j8sNJ4M8rjujsvv7zGTQ3g3jrcdgMmj3lMzxWUw+n0D9mlGTGUoG3S57cmuqkWL5+Xw5HC2QDKz1oEnrciRzHNPoL5bmzWFk0DeHTWckZ+dJlefF0ggjA3uTvdkscSXQXSzPd4eQwaA5fHRG95XDOqYMLYPJenB+ZTAIwdpZCyEDKcclv3Bky2qjA8hg0hy+pn8wpT+DxfXgWgbdzpr+DN4+q9i10eozWGgOX/Fso7VnsNAcvqgbQ3MGK+vBFW0brTyD5ebwtRAuTW+hOYPV9eBqCPWUoDqDrmHeINKdwbbJoL0QqilBcwZ5/Diguc2/i2jO4LT3QvF10BzW9kJrBnHmT6E1g+1TQU/j/2eKfAZQ23Lc+UPkvPMr+3RFb/A3H7Y+XRAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACA/+wH83QnC1gJfXYAAAAASUVORK5CYII='
};

export const CONFIG_METHOD_TYPES = {
  MANUAL: 'MANUAL',
  AUTO_GENERATED: 'AUTO_GENERATED',
  RECOMMENDED: 'RECOMMENDED'
};

export const CONFIG_METHOD_OPTIONS = [
  {
    label: 'Manual',
    value: CONFIG_METHOD_TYPES.MANUAL
  },
  {
    label: 'Auto Generated',
    value: CONFIG_METHOD_TYPES.AUTO_GENERATED
  },
  {
    label: 'Recommended',
    value: CONFIG_METHOD_TYPES.RECOMMENDED
  }
];

export const DEFAULT_TIMEZONE = 'America/Chicago';

export const TIMEZONE_OPTIONS = moment.tz.names()?.map((tz) => ({
  label: tz,
  value: tz
}));

export const IMPORT_JOBS_STATIC_FILES_URL = {
  [EXPORT_JOB_TYPES.USER]: 'users.csv',
  [EXPORT_JOB_TYPES.UI_LABEL]: 'ui-labels.csv'
};

export const LAYOUT_TYPES = {
  BACKGROUND: 'BACKGROUND',
  SIDE: 'SIDE'
};

export const LAYOUT_TYPE_OPTIONS = [
  { label: 'Background', value: LAYOUT_TYPES.BACKGROUND },
  { label: 'Side', value: LAYOUT_TYPES.SIDE }
];

export const staticDataUrl = process.env.REACT_APP_STATIC_DATA_URL;

export const DEFAULT_COLOR = '#000000';

export const ASSET_CONTENT_TYPE = {
  PNG: 'PNG',
  SVG: 'SVG'
};

export const AD_LAYOUT_TYPE = {
  BANNER: 'BANNER',
  SQUARE: 'SQUARE',
  RECTANGLE: 'RECTANGLE'
};

export const AD_LAYOUT_TYPE_OPTIONS = [
  { label: 'Banner', value: AD_LAYOUT_TYPE.BANNER },
  { label: 'Square', value: AD_LAYOUT_TYPE.SQUARE },
  { label: 'Rectangle', value: AD_LAYOUT_TYPE.RECTANGLE }
];

export const AD_DEVICE_TYPE = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET'
};

export const AD_DEVICE_TYPE_OPTIONS = [
  { label: 'Desktop', value: AD_DEVICE_TYPE.DESKTOP },
  { label: 'Mobile', value: AD_DEVICE_TYPE.MOBILE },
  { label: 'Tablet', value: AD_DEVICE_TYPE.TABLET }
];

export const ACL_TYPES = {
  PUBLIC_READ: 'PUBLIC_READ',
  PRIVATE: 'PRIVATE'
};

export const ASSET_UPLOAD_ACL_TYPE = {
  PUBLIC_READ: 'public-read',
  PRIVATE: 'private'
};
