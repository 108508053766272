import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import DashboardLanding from './DashboardLanding';

const DashboardLandingWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.DASHBOARD_LANDING}
        component={DashboardLanding}
      />
    </Switch>
  );
};

export default DashboardLandingWrapper;
