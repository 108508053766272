import React from 'react';
import { ReactComponent as Star } from '../../../assets/svg/star.svg';

const FeaturedArticleCardV2 = ({ title, content }) => {
  return (
    <section className="featured-article-card">
      <div className="container">
        <div className="heading">
          <Star />
          {title}
        </div>
        <div
          className="cms-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </div>
    </section>
  );
};

export default FeaturedArticleCardV2;
