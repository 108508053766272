import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import DefaultCollectionImg from '../../../assets/images/collection.png';
import Image from '../../../components/Image';

const { Text, Paragraph } = Typography;
function CollectionCard({
  collectionMd,
  collectionLg,
  title,
  description,
  collectionList,
  videoCount,
  blurHash,
  coverImg,
  href = '#'
}) {
  return (
    <div
      className={`collection-card ${collectionMd ? 'collection-md' : null} ${
        collectionLg ? 'collection-lg' : false
      } ${collectionList ? 'collection-list' : false}`}
    >
      <Link to={href} className="collection-img relative">
        <Image
          blurHash={blurHash}
          src={coverImg || DefaultCollectionImg}
          alt={title}
        />
      </Link>
      <div className="collection-dec">
        {title ? <Text className="mb-4 block title">{title}</Text> : null}
        {videoCount !== undefined ? (
          <Text className="text-tiny-kicker text-n-800 mb-4 block video-count">
            {videoCount} Items
          </Text>
        ) : null}

        {collectionList ? (
          <Paragraph className="text-small collection-dec">
            {description}
          </Paragraph>
        ) : (
          <Text className="text-tiny text-n-800  block collection-dec">
            {description}
          </Text>
        )}
      </div>
    </div>
  );
}
export default CollectionCard;
