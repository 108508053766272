import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditAdvertiser from './AddEditAdvertiser';
import Advertisers from './Advertisers';

const AdvertiserWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.LABELS_ADVERTISERS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Advertisers {...props} />
          </AccessControl>
        )}
      />
      <Route
        path={`${ROUTES?.LABELS_ADVERTISERS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditAdvertiser {...props} />
          </AccessControl>
        )}
      />
      <Route
        path={`${ROUTES?.LABELS_ADVERTISERS}/:advertiserId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditAdvertiser {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.LABELS_ADVERTISERS}/:advertiserId`}
        render={() => <Redirect to={ROUTES?.LABELS_ADVERTISERS} />}
      />
    </Switch>
  );
};

export default AdvertiserWrapper;
