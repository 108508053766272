import { find, map } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../AppContext';

const useCheckPermission = (modulePermissions = []) => {
  const {
    state: { userPermissions }
  } = useContext(AppContext);

  if (modulePermissions?.length === 0) {
    return true;
  }
  const permittedArray = map(modulePermissions, (item) => {
    const findLevel = find(
      userPermissions,
      (module) => module?.key === item?.moduleKey
    );
    return item?.allowedPermissions?.includes(findLevel?.level);
  });

  return permittedArray?.every((item) => item);
};

export default useCheckPermission;
