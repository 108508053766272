import { CheckOutlined } from '@ant-design/icons';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Input, Modal, Select, Space, theme } from 'antd';
import { capitalize, findIndex, isEmpty } from 'lodash';
import React, { useState } from 'react';
import audioPlaceholder from '../../../../assets/images/audioPlaceholder.jpg';
import videoPlaceholder from '../../../../assets/images/imagePlaceholder.png';
import {
  CONTENT_SELECTION,
  CONTENT_SELECTION_KEYS,
  getContentQueries
} from '../../../../common/constants';
import { getImageUrl } from '../../../../common/utils';
import Image from '../../../../components/Image';
import SelectableModal from '../../../../components/SelectableModal';
import { GET_ARTICLES_MODULE } from '../../../articles/graphql/Queries';
import { GET_PODCASTS_MODULE } from '../../../podcast/graphql/Queries';
import { GET_PRAYERS_FOR_MODULES } from '../../../prayers/graphql/Queries';
import { useModal } from '../../../videos/components/FormInputs';
import Preview from '../../../videos/components/Preview';
import { GET_VIDEOS_MODULES } from '../../../videos/graphql/Queries';

const { TextArea } = Input;

export const VideoCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url || data?.image?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectVideos = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Videos' : 'Select Video'}
        open={isOpen}
        onClose={closeModal}
        query={GET_VIDEOS_MODULES}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.videosAdmin?.videos ?? []}
        keys={{
          data: 'videosAdmin',
          records: 'videos',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <VideoCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Videos' : 'Video'}
        </Button>
      </Space>
    </>
  );
};

const PodcastCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? audioPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

const ArticleCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.imageThumbnail?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectArticle = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Articles' : 'Select Article'}
        open={isOpen}
        onClose={closeModal}
        query={GET_ARTICLES_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.articlesAdmin?.articles ?? []}
        keys={{
          data: 'articlesAdmin',
          records: 'articles',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id)?.includes(item?.id)
            : value?.id === item?.id;
          return (
            <ArticleCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value?.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Articles' : 'Article'}
        </Button>
      </Space>
    </>
  );
};

export const SelectArticleWithFeatured = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handleCardSelect = (item) => {
    if (multiple) {
      const selectedItem = value?.find((items) => items?.id === item?.id);
      if (selectedItem) {
        if (selectedItem?.isFeatured) {
          onChange(
            value
              ?.filter((items) => items?.id !== item?.id)
              ?.map((items, index) => {
                if (index === 0) {
                  return { ...items, isFeatured: true };
                }
                return { ...items, isFeatured: false };
              })
          );
        } else {
          onChange(value?.filter((items) => items?.id !== item?.id));
        }
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title,
            isFeatured: value?.length === 0
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title,
      isFeatured: true
    });
    closeModal();
  };

  const handleFeaturedChange = (data, checked) => {
    if (multiple) {
      const newValue = value?.map((item) => {
        if (item?.id === data?.id) {
          return {
            ...item,
            isFeatured: checked
          };
        }
        return {
          ...item,
          isFeatured: false
        };
      });
      onChange(newValue);
    } else {
      onChange({
        ...data,
        isFeatured: checked
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Articles' : 'Select Article'}
        open={isOpen}
        onClose={closeModal}
        query={GET_ARTICLES_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.articlesAdmin?.articles ?? []}
        keys={{
          data: 'articlesAdmin',
          records: 'articles',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id)?.includes(item?.id)
            : value?.id === item?.id;
          return (
            <ArticleCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleCardSelect(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Switch
                    checked={item?.isFeatured}
                    disabled={item?.isFeatured}
                    onChange={(checked) => handleFeaturedChange(item, checked)}
                    color={colorText}
                    label="Featured?"
                  />
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={() => handleCardSelect(value)}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Switch
                  checked={value?.isFeatured}
                  disabled={value?.isFeatured}
                  onChange={(checked) => handleFeaturedChange(value, checked)}
                  color={colorText}
                  label="Featured?"
                />
                <Preview.Title color={colorText}>{value?.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Articles' : 'Article'}
        </Button>
      </Space>
    </>
  );
};

export const SelectPodcasts = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.imageThumbnail?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Podcasts' : 'Select Podcast'}
        open={isOpen}
        onClose={closeModal}
        query={GET_PODCASTS_MODULE}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.podcastsAdmin?.podcasts ?? []}
        keys={{
          data: 'podcastsAdmin',
          records: 'podcasts',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <PodcastCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || audioPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || audioPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.id ? 'Change' : 'Select'} {multiple ? 'Podcasts' : 'Podcast'}
        </Button>
      </Space>
    </>
  );
};

const PrayerCard = ({ onSelect, selected, data }) => {
  return (
    <Card
      className="selectable-modal-card cs-card"
      cover={
        <>
          {selected && (
            <span className="checked-icon">
              <CheckOutlined />
            </span>
          )}
          <Image
            className="label-poster"
            src={
              getImageUrl(data?.image?.url, {
                height: 200,
                width: 200
              }) ?? videoPlaceholder
            }
            alt=""
          />
        </>
      }
      onClick={() => onSelect(data)}
    >
      <Card.Meta title={<span className="label-title">{data?.title}</span>} />
    </Card>
  );
};

export const SelectPrayers = ({
  multiple = true,
  onChange,
  value,
  id: fieldId,
  isContentEditAllowed = true,
  disabled = false,
  IsTableView = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorText } = theme.useToken().token;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleCardSelect = (item) => {
    if (multiple) {
      const isPresent = value?.find((items) => items?.id === item?.id);
      if (isPresent) {
        onChange(value?.filter((items) => items?.id !== item?.id));
      } else {
        onChange([
          ...value,
          {
            id: item?.id,
            url: item?.image?.url ?? '',
            title: item?.title
          }
        ]);
      }
      return;
    }
    onChange({
      id: item?.id,
      url: item?.image?.url ?? '',
      title: item?.title
    });
    closeModal();
  };

  const handleRemove = (item) => {
    if (multiple) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else {
      onChange({
        id: '',
        url: '',
        title: ''
      });
    }
  };

  return (
    <>
      <SelectableModal
        title={multiple ? 'Select Prayers' : 'Select Prayer'}
        open={isOpen}
        onClose={closeModal}
        query={GET_PRAYERS_FOR_MODULES}
        variablesSelector={(offset, limit, search) => ({
          filter: { search, skip: offset, limit }
        })}
        dataSelector={(data) => data?.prayersAdmin?.prayers ?? []}
        keys={{
          data: 'prayersAdmin',
          records: 'prayers',
          count: 'count'
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <PrayerCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <Space wrap>
        {multiple ? (
          <>
            {value?.length > 0 &&
              value?.map((item) => (
                <Preview
                  onRemove={() => handleRemove(item)}
                  key={item?.id}
                  bgImg={
                    getImageUrl(item?.url, {
                      height: 200,
                      width: 200
                    }) || videoPlaceholder
                  }
                >
                  <Preview.Title color={colorText}>{item?.title}</Preview.Title>
                </Preview>
              ))}
          </>
        ) : (
          <>
            {!!value?.id && (
              <Preview
                onRemove={handleRemove}
                key={value?.id}
                bgImg={
                  getImageUrl(value?.url, {
                    height: 200,
                    width: 200
                  }) || videoPlaceholder
                }
              >
                <Preview.Title color={colorText}>{value.title}</Preview.Title>
              </Preview>
            )}
          </>
        )}

        <Button
          id={fieldId}
          htmlType="button"
          onClick={openModal}
          disabled={disabled || !isContentEditAllowed}
          className={IsTableView ? 'table-view-btn' : ''}
        >
          {value?.length > 0 ? 'Change' : 'Select'}{' '}
          {multiple ? 'Prayers' : 'Prayer'}
        </Button>
      </Space>
    </>
  );
};

const SummaryModal = ({
  data,
  setData,
  openModal,
  setOpenModal,
  value,
  onChange,
  disabled
}) => {
  const [summary, setSummary] = useState(data?.summary);
  const [ctaTitle, setCtaTitle] = useState(data?.config?.ctaTitle);
  const handleOk = () => {
    const findObj = findIndex(value, (item) => item?.id === data?.id);
    if (findObj !== -1) {
      if (!isEmpty(summary)) {
        const updatedValues = [...value];
        updatedValues[findObj] = { ...updatedValues[findObj], summary };
        onChange(updatedValues);
      }
      if (!isEmpty(ctaTitle)) {
        const updatedValues = [...value];
        updatedValues[findObj] = {
          ...updatedValues[findObj],
          config: { ctaTitle }
        };
        onChange(updatedValues);
      }
    }
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setData(null);
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title={
        data?.instanceType === CONTENT_SELECTION_KEYS.ARTICLE
          ? 'Summary'
          : 'CTA Title'
      }
      width={850}
      okText="Add"
      closable={false}
      onOk={handleOk}
      okButtonProps={{ disabled }}
    >
      <h2>{data?.title}</h2>
      {data?.instanceType === CONTENT_SELECTION_KEYS.ARTICLE && (
        <TextArea
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          rows={4}
          placeholder="Enter Summary"
        />
      )}
      {data?.instanceType === CONTENT_SELECTION_KEYS.AD && (
        <Input
          value={ctaTitle}
          placeholder="Enter CTA Title"
          onChange={(e) => setCtaTitle(e.target.value)}
        />
      )}
    </Modal>
  );
};

const Draggable = ({ allowDrag, colorText, onRemove, onEdit, ...item }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: item?.id,
    strategy: rectSortingStrategy,
    disabled: !allowDrag
  });
  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition
      }
    : {
        ...(allowDrag && { cursor: 'move' })
      };

  const handlers = {
    ...attributes,
    ...listeners
  };

  return (
    <Preview
      ref={setNodeRef}
      onRemove={() => onRemove(item)}
      key={item?.id}
      style={style}
      bgImg={
        getImageUrl(item?.url, {
          height: 200,
          width: 200
        }) || videoPlaceholder
      }
      item={item}
      instanceType={item?.instanceType || null}
      onEdit={() => onEdit(item)}
      showIcon
      {...handlers}
    >
      <Preview.Title color={colorText} className={item?.summary ? 'title' : ''}>
        {item?.title}
      </Preview.Title>
      <Preview.Description color={colorText}>
        {item?.summary}
      </Preview.Description>
    </Preview>
  );
};

export const SelectContentUnits = ({
  onChange,
  modalTitle,
  id: fieldId,
  value,
  btnText,
  allowDrag = false,
  disabled
}) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [data, setData] = useState({});
  const { colorText } = theme.useToken().token;
  const [selectedContent, setSelectedContent] = useState(
    CONTENT_SELECTION_KEYS.VIDEO
  );
  const { open, openModal, closeModal } = useModal();
  const handleCardSelect = (item) => {
    const isPresent = value?.find((items) => items.id === item.id);
    if (isPresent) {
      onChange(value?.filter((items) => items?.id !== item?.id));
    } else if (value?.length > 0) {
      onChange([
        ...value,
        {
          id: item?.id,
          url: item?.imageThumbnail?.url ?? item?.image?.url ?? '',
          title: item?.title,
          instanceType: selectedContent
        }
      ]);
    } else {
      onChange([
        {
          id: item?.id,
          url: item?.imageThumbnail?.url ?? item?.image?.url ?? '',
          title: item?.title,
          instanceType: selectedContent
        }
      ]);
    }
  };
  const handleContentChange = (e) => {
    setSelectedContent(e);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) return;

    const source = active.id;
    const destination = over.id;

    if (source === destination) return;

    const sourceIndex = value?.findIndex(({ id }) => id === source);
    const destinationIndex = value?.findIndex(({ id }) => id === destination);

    const newItems = [...value];
    const item = newItems.splice(sourceIndex, 1);
    if (!item.length) return;
    newItems.splice(destinationIndex, 0, item[0]);

    onChange(newItems);
  };

  const handleRemove = (item) => {
    onChange(value?.filter((items) => items?.id !== item?.id));
  };

  const handleEdit = (item) => {
    setData(item);
    setOpenSummaryModal(true);
  };

  return (
    <>
      {openSummaryModal && (
        <SummaryModal
          openModal={openSummaryModal}
          setOpenModal={setOpenSummaryModal}
          onChange={onChange}
          data={data}
          setData={setData}
          value={value}
          disabled={disabled}
        />
      )}
      <SelectableModal
        title={modalTitle ?? `Select ${capitalize(selectedContent)}`}
        open={open}
        onClose={() => {
          closeModal();
          setSelectedContent(CONTENT_SELECTION_KEYS.VIDEO);
        }}
        {...getContentQueries[selectedContent]}
        renderFilters={() => {
          return (
            <Select
              value={selectedContent}
              popupMatchSelectWidth={false}
              placeholder="Select Content Unit"
              options={CONTENT_SELECTION?.filter(
                ({ value: contentValue }) =>
                  contentValue !== CONTENT_SELECTION_KEYS.FORM
              )}
              onChange={handleContentChange}
              className="custom-content-select"
            />
          );
        }}
        renderItem={(item) => {
          const isSelected = Array.isArray(value)
            ? value?.map((val) => val?.id).includes(item?.id)
            : value?.id === item?.id;
          return (
            <VideoCard
              data={item}
              selected={isSelected}
              onSelect={handleCardSelect}
            />
          );
        }}
      />
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <Space wrap>
          <SortableContext
            strategy={rectSortingStrategy}
            items={Array?.isArray(value) ? value?.map(({ id }) => id) : []}
          >
            <Space wrap>
              {value?.length > 0 &&
                value?.map((item) => (
                  <Draggable
                    key={item?.id}
                    onRemove={handleRemove}
                    colorText={colorText}
                    allowDrag={allowDrag}
                    onEdit={handleEdit}
                    {...item}
                  />
                ))}
            </Space>
          </SortableContext>
          <Button htmlType="button" onClick={openModal} id={fieldId}>
            {value?.length > 0 ? 'Change' : 'Select'} {btnText}
          </Button>
        </Space>
      </DndContext>
    </>
  );
};
