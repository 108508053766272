import { gql } from '@apollo/client';

export const GET_WORKSPACE_USERS = gql`
  query WorkspaceUsers(
    $filter: WorkspaceUsersFilterInput
    $sort: WorkspaceUsersSortInput
  ) {
    workspaceUsers(filter: $filter, sort: $sort) {
      count
      workspaceUsers {
        id
        isActive
        createdAt
        creator {
          firstName
          lastName
        }
        roles {
          id
          key
          name
        }
        user {
          id
          email
          firstName
          lastName
          profileImage
          permissions
          isActive
          createdAt
        }
      }
    }
  }
`;

export const GET_WORKSPACE_USER = gql`
  query workspaceUser($where: WorkspaceUserUniqueInput!) {
    workspaceUser(where: $where) {
      id
      isActive
      createdAt
      creator {
        firstName
        lastName
      }
      roles {
        id
        key
        name
      }
      user {
        id
        email
        firstName
        lastName
        profileImage
        permissions
        isActive
        createdAt
      }
    }
  }
`;

export const GET_WORKSPACE_ROLES = gql`
  query workspaceRoles(
    $filter: WorkspaceRolesFilterInput
    $sort: WorkspaceRolesSortInput
  ) {
    workspaceRoles(filter: $filter, sort: $sort) {
      count
      workspaceRoles {
        id
        key
        name
      }
    }
  }
`;
