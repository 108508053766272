import { Carousel } from 'antd';
import { ArrowSquareOut } from 'phosphor-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Star } from '../../../assets/svg/star.svg';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  variableWidth: true,
  draggable: true,
  smooth: true,
  loop: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1150,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

function ArticleCarouselV2({ data = [], sectionHeading }) {
  return (
    <section className="cta-carousel-section">
      <div className="left-container">
        <h2>{sectionHeading}</h2>
        <Carousel {...settings}>
          {data?.map((article) => (
            <div key={article?.id} className="cta-carousel-card">
              <div className="heading">
                <Star />
                <div className="title">{article?.title}</div>
              </div>
              <div className="flex-1 d-flex justify-center">
                <div
                  className="description"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: article?.html
                  }}
                />
              </div>
              <Link to="/">
                Read More <ArrowSquareOut fill="#1E717B" />
              </Link>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
export default ArticleCarouselV2;
