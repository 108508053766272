import { gql } from '@apollo/client';

export const CREATE_PODCAST = gql`
  mutation CreatePodcast($data: CreatePodcastInput!) {
    createPodcast(data: $data) {
      message
    }
  }
`;

export const UPDATE_PODCAST = gql`
  mutation UpdatePodcast(
    $where: PodcastWhereUniqueInput!
    $data: UpdatePodcastInput!
  ) {
    updatePodcast(where: $where, data: $data) {
      message
    }
  }
`;
