import { gql } from '@apollo/client';

export const CREATE_LOOP = gql`
  mutation CreateLoop($data: CreateLoopInput!) {
    createLoop(data: $data) {
      message
    }
  }
`;

export const UPDATE_LOOP = gql`
  mutation UpdateLoop($where: LoopAdminWhereInput!, $data: UpdateLoopInput!) {
    updateLoop(where: $where, data: $data) {
      message
    }
  }
`;
