import { Col, Row } from 'antd';
import React from 'react';
import SectionHeader from '../SectionHeader/SectionHeader';
import CtaButtonTypeCard from './CtaButtonTypeCard';
import CtaGridCard from './CtaGridCard';

export const CTA_TYPE = {
  BUTTON: 'BUTTON',
  CARD: 'CARD'
};

function CtaGrid({
  sectionHeading,
  headerLink,
  headerDescription,
  data = [],
  responsive,
  displayType: type
}) {
  return (
    <section
      className={`collection-grid ${responsive ? 'responsive-view' : ''}`}
    >
      <div className="container">
        <SectionHeader
          heading={sectionHeading}
          headerLink={headerLink}
          headerDescription={headerDescription}
        />
        <Row
          gutter={[
            { xs: 8, sm: 8, md: responsive ? 8 : 16, lg: responsive ? 8 : 16 },
            {
              xs: 16,
              sm: 16,
              md: responsive ? 16 : 32,
              lg: responsive ? 16 : 32
            }
          ]}
        >
          {data?.map((item) => {
            return (
              <Col
                key={item?.id}
                xs={{ span: 12 }}
                md={{ span: responsive ? 12 : 8 }}
                lg={{ span: responsive ? 12 : 6 }}
              >
                {type === CTA_TYPE.CARD ? (
                  <CtaGridCard
                    src={item?.src}
                    blurHash={item.blurHash}
                    defaultValue={item?.defaultValue}
                    time={item?.time}
                    category={item?.category}
                    collection={item?.collection}
                    title={item?.title}
                    description={item?.description}
                    date={item?.date}
                    speaker={item?.speaker}
                    tags={item?.tags}
                    coverImg={item?.img}
                    tagBackground={item?.tagBackground}
                    tagTextColor={item?.tagTextColor}
                  />
                ) : (
                  <CtaButtonTypeCard
                    key={item?.id}
                    list
                    href={item?.href}
                    topic={item?.title}
                    image={item?.img}
                    blurhash={item?.blurhash}
                  />
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}
export default CtaGrid;
