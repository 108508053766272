import React, { Suspense } from 'react';

const HtmlEditor = React.lazy(() => import('./HtmlEditor'));

const LoadEditor = (props) => {
  return (
    <Suspense fallback="loading....">
      <HtmlEditor {...props} />
    </Suspense>
  );
};

export default LoadEditor;
