import { gql } from '@apollo/client';

export const CREATE_ADMIN_USER = gql`
  mutation createAdminUser($data: AdminUserCreateInput!) {
    createAdminUser(data: $data) {
      message
    }
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation updateAdminUser(
    $data: AdminUserUpdateInput!
    $where: AdminUserUniqueInput!
  ) {
    updateAdminUser(data: $data, where: $where) {
      message
    }
  }
`;

export const REQUEST_EXPORT_ADMIN_USERS = gql`
  mutation requestAdminUsersExport($filter: RequestAdminUsersExportFilter) {
    requestAdminUsersExport(filter: $filter) {
      message
    }
  }
`;
