import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, Modal, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { DownloadSimple, Eye } from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import videoPlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  IMPORT_VIDEO_DATE_FORMAT,
  MAX_LENGTHS,
  MODULES,
  PROVIDER_TYPES,
  ROUTES,
  SORT_FIELD
} from '../../../common/constants';
import {
  Blurhash,
  fileUpload,
  formValidatorRules,
  getFileFromUrl
} from '../../../common/utils';
import FilterBar, { useFilterBar } from '../../../components/FilterBar';
import PageHeader from '../../../components/PageHeader';
import PageList from '../../../components/PageList';
import ProgressBar from '../../../components/ProgressBar';
import history from '../../../historyData';
import CustomCard from '../../component/card/Cscard';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { SelectPlatForm } from '../../videos/components/FormInputs';
import {
  CREATE_ASSET,
  IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET
} from '../graphql/Mutations';
import {
  GET_UPLOAD_SIGNED_URL,
  GET_VIDEOS_BY_WORKSPACE_PLATFORM,
  GET_VIDEO_URL_BY_WORKSPACE_PLATFORM_VIDEO
} from '../graphql/Queries';
import { SelectLanguage } from './CommonVideoForm';

const { TextArea } = Input;

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  where
}) => ({
  filter: {
    limit,
    skip: offset,
    search
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  },
  where
});

const dataSelector = (data) => ({
  data: data?.getVideosByWorkspacePlatform?.platformVideos || [],
  count: data?.getVideosByWorkspacePlatform?.count || 0
});

const initialValues = {
  title: '',
  description: '',
  languageCode: {
    label: 'English',
    value: 'en'
  },
  importFromService: {
    image: false,
    title: false,
    description: false
  },
  platform: null
};

const ImportPlatformVideoModal = ({
  open,
  platform,
  videoPlatformData,
  onClose,
  filters,
  listMode
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [createImageAsset] = useMutation(CREATE_ASSET);
  const [getSignedUrl] = useLazyQuery(GET_UPLOAD_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });
  const [createPlatformVideoAssetMutate] = useMutation(
    IMPORT_PLATFORM_VIDEO_TO_WORKSPACE_ASSET
  );

  const importFromServiceImage =
    Form.useWatch(['importFromService', 'image'], form) ?? false;

  useEffect(() => {
    if (!open) {
      form?.resetFields();
    }
  }, [open]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const {
      importFromService: { image: importImage }
    } = values;
    const textData = {
      title: values?.title,
      description: values?.description,
      languageCode: values?.languageCode?.value
    };
    try {
      if (importImage && videoPlatformData?.imageThumbnailUrl) {
        const payload = {
          title: values?.title,
          description: values?.description ?? '',
          categoryKey: ASSET_CATEGORY.IMAGE,
          platformId: values?.platform?.value
        };

        const file = await getFileFromUrl(
          videoPlatformData?.imageThumbnailUrl,
          values?.title
        );
        if (file) {
          const { data, error } = await getSignedUrl({
            variables: {
              data: {
                fileName: file?.name?.replace(/\s/g, '_'),
                contentType: file?.type,
                assetType: ASSET_CATEGORY?.IMAGE,
                platformId: values?.platform?.value
              }
            }
          });
          if (error) throw Error(error);

          if (data?.getAssetUploadSignedUrl) {
            setUploading(true);
            await fileUpload(
              data?.getAssetUploadSignedUrl?.signedUrl,
              file,
              setProgress
            );
            payload.key = data?.getAssetUploadSignedUrl?.key;
            payload.contentType = file?.type;
            payload.blurhash = await Blurhash.encode(file);

            await createImageAsset({
              variables: {
                data: payload
              }
            });
            setUploading(false);
          }
        }
      }
      const response = await createPlatformVideoAssetMutate({
        variables: {
          where: {
            platformId: platform?.value,
            videoId: videoPlatformData?.id
          },
          data: {
            ...textData
          }
        }
      });
      if (response?.data?.importPlatformVideoToWorkspaceAsset) {
        history?.replace(ROUTES?.ASSETS_VIDEOS, {
          onAddEditFilters: filters,
          onAddEditListMode: listMode
        });
      }
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false);
      setUploading(false);
    }
  };
  return (
    <Modal
      destroyOnClose
      okButtonProps={{
        className: 'text-btn',
        form: 'import-video-form',
        htmlType: 'submit',
        loading,
        type: 'text'
      }}
      cancelButtonProps={{
        className: 'text-btn2',
        type: 'text'
      }}
      title="Import Video"
      open={open}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        name="create-asset"
        layout="vertical"
        id="import-video-form"
        className="add-edit-form"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[
            formValidatorRules?.required('Please enter title!'),
            formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
          ]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
        >
          <TextArea rows={2} placeholder="Enter description" />
        </Form.Item>
        <Form.Item name="languageCode" label="Language Code">
          <SelectLanguage />
        </Form.Item>
        <Typography>Import from service</Typography>
        <div className="d-flex align-center">
          <Form.Item
            className="m-0 mr-8"
            name={['importFromService', 'title']}
            valuePropName="checked"
          >
            <Switch
              label="Title"
              onChange={(checked) => {
                if (checked)
                  form.setFieldValue('title', videoPlatformData?.title ?? '');
                else form.resetFields(['title']);
              }}
            />
          </Form.Item>
          <Form.Item
            className="m-0 mr-8"
            name={['importFromService', 'description']}
            valuePropName="checked"
          >
            <Switch
              label="Description"
              onChange={(checked) => {
                if (checked)
                  form.setFieldValue(
                    'description',
                    videoPlatformData?.description ?? ''
                  );
                else form.resetFields(['description']);
              }}
            />
          </Form.Item>
          <Form.Item
            className="m-0"
            name={['importFromService', 'image']}
            valuePropName="checked"
          >
            <Switch label="Image" />
          </Form.Item>
        </div>
        {importFromServiceImage && (
          <Form.Item
            name="platform"
            label="Service Provider"
            className="mt-16"
            rules={[
              { required: true, message: 'Service Provider is required' }
            ]}
          >
            <SelectPlatForm
              placeholder="Select platform to store image"
              type={PROVIDER_TYPES.STORAGE}
              fullWidth
            />
          </Form.Item>
        )}
      </Form>
      {uploading && <ProgressBar progress={progress} />}
    </Modal>
  );
};

const ImportVideo = () => {
  const location = useLocation();
  const { filters: addEditFilter, listMode } = location?.state || {};
  const ref = useRef();
  const [platform, setPlatform] = useState(null);
  const [platformData, setPlatformData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: SORT_FIELD.slice(0, 1)
    }
  });

  const videosImportBreadcrumbs = [
    { label: MODULES?.ASSETS },
    {
      label: MODULES?.VIDEOS,
      route: ROUTES?.ASSETS_VIDEOS,
      addEditFilter: { filters: addEditFilter, listMode }
    },
    { label: MODULES?.IMPORT }
  ];

  const [getVideoUrl] = useLazyQuery(
    GET_VIDEO_URL_BY_WORKSPACE_PLATFORM_VIDEO,
    {
      fetchPolicy: 'network-only'
    }
  );
  const handlePlatformChange = (value) => {
    setPlatform(value);
  };

  const handleImport = (data) => {
    setIsOpen(true);
    setPlatformData(data);
  };

  const handleView = async (videoId) => {
    const { data } = await getVideoUrl({
      variables: {
        where: {
          videoId,
          platformId: platform?.value
        }
      }
    });
    if (
      data?.getVideoUrlByWorkspacePlatformAndVideoId &&
      ref.current.showPreview
    ) {
      ref.current.showPreview({
        title: data?.getVideoUrlByWorkspacePlatformAndVideoId.title,
        url: data?.getVideoUrlByWorkspacePlatformAndVideoId.url,
        type: ASSET_CATEGORY.VIDEO
      });
    }
  };

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      where: {
        id: platform ? platform?.value : '',
        type: PROVIDER_TYPES.VIDEO
      }
    }),
    [filterProps.filters, platform]
  );

  const columns = [
    {
      title: 'Video',
      dataIndex: 'imageThumbnailUrl',
      key: 'imageThumbnailUrl',
      editable: true,
      width: '12%',
      render: (text, record) => (
        <img
          className="group-img-table pointer"
          src={
            record?.videoThumbnailUrl ??
            record?.imageThumbnailUrl ??
            videoPlaceholder
          }
          alt={record?.title ?? ''}
        />
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '22%'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '12%',
      render: (text, record) => (
        <div>
          <Tooltip title="View Video">
            <EyeOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleView(record?.id);
              }}
            />
          </Tooltip>
          <span onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Import Video">
              <DownloadOutlined onClick={() => handleImport(record)} />
            </Tooltip>
          </span>
        </div>
      )
    }
  ];

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSuccess = () => {
    closeModal();
  };

  return (
    <>
      <ImportPlatformVideoModal
        open={isOpen}
        videoPlatformData={platformData}
        platform={platform}
        onClose={closeModal}
        onSuccess={handleSuccess}
        filters={addEditFilter}
        listMode={listMode}
      />
      <PageHeader menu={videosImportBreadcrumbs} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Videos' }}
        extraFilters={
          <SelectPlatForm
            type={PROVIDER_TYPES.VIDEO}
            onChange={handlePlatformChange}
            value={platform}
            queryOptions={{
              onCompleted: ({ workspacePlatforms }) => {
                const firstPlatform =
                  workspacePlatforms?.workspacePlatforms?.[0];
                if (firstPlatform) {
                  if (!platform)
                    setPlatform({
                      label: `${firstPlatform?.provider?.name} - ${firstPlatform?.name}`,
                      value: firstPlatform?.id
                    });
                }
              }
            }}
          />
        }
      />
      {platform && (
        <PageList
          ref={ref}
          filters={filters}
          listMode={filterProps.listMode}
          query={GET_VIDEOS_BY_WORKSPACE_PLATFORM}
          variablesSelector={variablesSelector}
          dataSelector={dataSelector}
          GridProps={{
            renderItem: (item) => (
              <CustomCard
                alt={item?.title}
                src={
                  item?.videoThumbnailUrl ??
                  item?.imageThumbnailUrl ??
                  videoPlaceholder
                }
                title={moment(item?.createdAt).format(IMPORT_VIDEO_DATE_FORMAT)}
                heading={item?.title}
                description={item?.description}
                primaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleView(item?.id);
                }}
                secondaryOnClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  handleImport(item);
                }}
                primary={
                  <>
                    <Eye size={16} />
                    View
                  </>
                }
                secondary={
                  <>
                    <DownloadSimple size={16} />
                    Import
                  </>
                }
              />
            )
          }}
          TableProps={{ columns }}
        />
      )}
    </>
  );
};

export default ImportVideo;
