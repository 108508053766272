import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import React, { useEffect } from 'react';
import * as urlSlug from 'url-slug';
import {
  ASSET_CATEGORY,
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import PageHeader from '../../../components/PageHeader';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SelectAsset from '../../assets/components/SelectAsset';
import { Switch } from '../../pages/component/pageModules/moduleForms/FormInputs';
import { SlugInput } from '../topics/components/FormInputs';
import { CREATE_SOURCE, UPDATE_SOURCE } from './graphql/Mutations';
import { GET_SOURCE } from './graphql/Queries';

const initialValues = {
  name: '',
  description: '',
  slug: '/',
  metaHeader: '',
  metaFooter: '',
  isActive: false
};

const AddEditSource = ({ history, location, match: { params } }) => {
  const [form] = Form.useForm();
  const { filters, listMode } = location?.state || {};
  const { sourceId } = params;
  const isEdit = !!sourceId;

  const addEditSourcesBreadcrumbs = [
    { label: MODULES?.LABELS },
    {
      label: MODULES?.SOURCES,
      route: ROUTES?.LABELS_SOURCES,
      addEditFilter: { filters, listMode }
    },
    isEdit && { label: sourceId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const [fetchSource, { loading: fetchingDetails }] = useLazyQuery(GET_SOURCE, {
    fetchPolicy: 'network-only'
  });

  const [addUpdateSource, { loading }] = useMutation(
    isEdit ? UPDATE_SOURCE : CREATE_SOURCE
  );

  useEffect(() => {
    if (isEdit && !!sourceId) {
      fetchSource({
        variables: {
          where: {
            id: sourceId
          }
        }
      })
        .then(({ data }) => {
          const source = data?.sourceAdmin;
          if (source) {
            const {
              name,
              description,
              slug,
              metaHeader,
              metaFooter,
              isActive,
              image
            } = source;
            form.setFieldsValue({
              name,
              description,
              slug: slug.startsWith('/') ? slug : `/${slug}`,
              metaHeader: metaHeader ?? '',
              metaFooter: metaFooter ?? '',
              isActive,
              imageId: {
                id: image?.id ?? '',
                url: image?.url ?? ''
              }
            });
          }
        })
        .catch();
    }
  }, [sourceId, isEdit, form]);

  const handleTitleChange = (e) => {
    form.setFieldValue('slug', `/${urlSlug.convert(e.target.value)}`);
  };

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      slug: values?.slug?.startsWith('/')
        ? values?.slug?.substring(1)
        : values?.slug,
      imageId: values?.imageId?.id || null
    };

    addUpdateSource({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: sourceId
          }
        })
      }
    }).then(() => {
      history.push(ROUTES?.LABELS_SOURCES, {
        onAddEditFilters: filters,
        onAddEditListMode: listMode
      });
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.LABELS_SOURCES, {
      onAddEditFilters: filters,
      onAddEditListMode: listMode
    });
  };

  return (
    <>
      <PageHeader menu={addEditSourcesBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            disabled={isViewOnly || fetchingDetails}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <Input placeholder="Enter name" onChange={handleTitleChange} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item
              label="Slug"
              name="slug"
              rules={[
                {
                  required: true,
                  message: 'Please enter slug!'
                },
                formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
              ]}
            >
              <SlugInput />
            </Form.Item>
            <Form.Item name="isActive" valuePropName="checked">
              <Switch label="Active" />
            </Form.Item>
            <Form.Item
              label="Image"
              name="imageId"
              extra="Recommended size  (2500 * 1242)"
            >
              <SelectAsset
                disabled={isViewOnly}
                modalTitle="Select Image"
                categoryKey={ASSET_CATEGORY.IMAGE}
                btnText="Image"
                dataSelector={({ id, url }) => ({
                  id,
                  url
                })}
              />
            </Form.Item>
            <Form.Item
              name="metaHeader"
              label="Meta Header"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta header" />
            </Form.Item>

            <Form.Item
              name="metaFooter"
              label="Meta Footer"
              rules={[formValidatorRules?.maxLength(MAX_LENGTHS.DESCRIPTION)]}
            >
              <Input.TextArea rows={5} placeholder="Enter meta footer" />
            </Form.Item>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || fetchingDetails}
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}
                <Button
                  disabled={loading}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditSource;
