import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import AddEditMenu from './AddEditMenu';
import Menus from './Menus';

const MenusWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.UI_CONFIG_MENUS}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <Menus {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.UI_CONFIG_MENUS}/add`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditMenu {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.UI_CONFIG_MENUS}/:menuId/edit`}
        render={(props) => (
          <AccessControl
            modulePermissions={[
              {
                moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
                allowedPermissions: [
                  WORKSPACE_ROLE_LEVEL.VIEW,
                  WORKSPACE_ROLE_LEVEL.EDIT,
                  WORKSPACE_ROLE_LEVEL.DELETE
                ]
              }
            ]}
          >
            <AddEditMenu {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.UI_CONFIG_MENUS}/:menuId`}
        render={() => <Redirect to={ROUTES?.UI_CONFIG_MENUS} />}
      />
    </Switch>
  );
};

export default MenusWrapper;
