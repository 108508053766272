import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COMMENTS = gql`
  query CommentThreadAdmin($filter: CommentThreadFilter!, $sort: CommentsSort) {
    commentThreadAdmin(filter: $filter, sort: $sort) {
      count
      comments {
        id
        comment
        createdBy {
          id
          profileImage
          firstName
          lastName
        }
        createdAt
        likesCount
        starsCount
        replyCount
        flagsCount
        moderationResponse
        mediaModerationResponse
        mediaType
        mediaUrl
        isAccepted
        isDeleted
        isHidden
        isHighlighted
        mediaType
        mediaUrl
      }
    }
  }
`;
