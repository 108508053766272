import { Divider, Form, Input } from 'antd';
import React from 'react';
import { ASSET_CATEGORY } from '../common/constants';
import SelectAsset from '../modules/assets/components/SelectAsset';
import { Switch } from '../modules/pages/component/pageModules/moduleForms/FormInputs';

export const defaultSeoValues = {
  isDefault: true,
  title: '',
  description: '',
  keywords: '',
  canonicalUrl: '',
  openGraph: {
    title: '',
    description: '',
    image: { id: '', url: '', title: '' }
  },
  twitter: {
    useOpenGraph: true,
    title: '',
    description: '',
    image: { id: '', url: '', title: '' }
  }
};

export const getSeoFormFields = (seo) =>
  !seo
    ? defaultSeoValues
    : {
        isDefault: seo?.isDefault ?? true,
        title: seo?.title ?? '',
        description: seo?.description ?? '',
        keywords: seo?.keywords ?? '',
        canonicalUrl: seo?.canonicalUrl ?? '',
        openGraph: seo?.openGraph
          ? {
              title: seo?.openGraph?.title ?? '',
              description: seo?.openGraph?.description ?? '',
              image: seo?.openGraph?.image ?? defaultSeoValues.openGraph.image
            }
          : defaultSeoValues.openGraph,
        twitter: seo?.twitter
          ? {
              useOpenGraph: seo?.twitter?.useOpenGraph ?? true,
              title: seo?.twitter?.title ?? '',
              description: seo?.twitter?.description ?? '',
              image: seo?.twitter?.image ?? defaultSeoValues.twitter.image
            }
          : defaultSeoValues.twitter
      };

function SeoInput({ form }) {
  const isDefault = Form.useWatch(['seo', 'isDefault'], form) ?? true;
  const useOpenGraph =
    Form.useWatch(['seo', 'twitter', 'useOpenGraph'], form) ?? true;

  return (
    <fieldset className="mb-12">
      <legend className="role-legend">SEO Settings</legend>
      <Form.Item
        className="mb-8"
        name={['seo', 'isDefault']}
        valuePropName="checked"
      >
        <Switch label="Use default settings" />
      </Form.Item>
      {!isDefault && (
        <>
          <Form.Item label="Page Title" name={['seo', 'title']}>
            <Input placeholder="Enter Page title" />
          </Form.Item>
          <Form.Item label="Meta Description" name={['seo', 'description']}>
            <Input.TextArea placeholder="Enter meta description" />
          </Form.Item>
          <Form.Item label="Keywords" name={['seo', 'keywords']}>
            <Input placeholder="Enter keywords, separated by commas" />
          </Form.Item>
          <Form.Item
            label="Canonical URL"
            name={['seo', 'canonicalUrl']}
            rules={[
              {
                type: 'url',
                message: 'Invalid URL'
              }
            ]}
          >
            <Input type="url" placeholder="Enter canonical url" />
          </Form.Item>
          <Divider>Open Graph Settings</Divider>
          <Form.Item
            label="Open Graph Title"
            name={['seo', 'openGraph', 'title']}
          >
            <Input placeholder="Enter open graph title" />
          </Form.Item>
          <Form.Item
            label="Open Graph Description"
            name={['seo', 'openGraph', 'description']}
          >
            <Input.TextArea placeholder="Enter open graph description" />
          </Form.Item>
          <Form.Item
            label="Open Graph Image"
            name={['seo', 'openGraph', 'image']}
          >
            <SelectAsset
              categoryKey={ASSET_CATEGORY.IMAGE}
              modalTitle="Select Image"
              btnText="Image"
              dataSelector={({ id, url }) => ({
                id,
                url
              })}
            />
          </Form.Item>
          <Divider>Twitter Card Settings</Divider>
          <Form.Item
            className="mb-8"
            name={['seo', 'twitter', 'useOpenGraph']}
            valuePropName="checked"
          >
            <Switch label="Use open graph settings" />
          </Form.Item>
          {!useOpenGraph && (
            <>
              <Form.Item
                label="Twitter Card Title"
                name={['seo', 'twitter', 'title']}
              >
                <Input placeholder="Enter twitter card title" />
              </Form.Item>
              <Form.Item
                label="Twitter Card Description"
                name={['seo', 'twitter', 'description']}
              >
                <Input.TextArea placeholder="Enter twitter card description" />
              </Form.Item>
              <Form.Item
                label="Twitter Card Image"
                name={['seo', 'twitter', 'image']}
              >
                <SelectAsset
                  categoryKey={ASSET_CATEGORY.IMAGE}
                  modalTitle="Select Image"
                  btnText="Image"
                  dataSelector={({ id, url }) => ({
                    id,
                    url
                  })}
                />
              </Form.Item>
            </>
          )}
        </>
      )}
    </fieldset>
  );
}

export default SeoInput;
