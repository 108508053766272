import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_IMPORT_JOBS = gql`
  query ImportJobs($filter: ExportJobsFilter!) {
    importJobs(filter: $filter) {
      count
      data {
        id
        fileUrl
        resultFileUrl
        status
        createdAt
      }
    }
  }
`;
