import { gql } from '@apollo/client';

export const CREATE_PLATFORM = gql`
  mutation createWorkspacePlatform($data: CreateWorkspacePlatformInput!) {
    createWorkspacePlatform(data: $data) {
      message
    }
  }
`;

export const UPDATE_WORKSPACE_PLATFORM = gql`
  mutation updateWorkspacePlatform(
    $where: WorkspacePlatformWhereUniqueInput!
    $data: UpdateWorkspacePlatformInput!
  ) {
    updateWorkspacePlatform(where: $where, data: $data) {
      message
    }
  }
`;
