import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CANCEL_DONATION = gql`
  mutation cancelDonationAdmin($id: ID!) {
    cancelDonationAdmin(where: { id: $id }) {
      message
    }
  }
`;
