import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DATE_FORMAT,
  LIST_TYPES,
  MODULES,
  ROUTES,
  USERS_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { GET_WORKSPACE_USERS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.workspaceUsers?.workspaceUsers ?? [],
  count: data?.workspaceUsers?.count ?? 0
});

const variablesSelector = ({ offset, sortField, sortOrder, ...rest }) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const Action = ({ id, filters }) => {
  const history = useHistory();
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.WORKSPACE_USER_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleEdit = () => {
    history.push(`${ROUTES?.WS_MEMBERS}/${id}/edit`, {
      filters
    });
  };

  return (
    <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} User`}>
      <Button type="text" className="text-btn" onClick={handleEdit}>
        {isViewOnly ? <EyeFilled /> : <EditOutlined />}
      </Button>
    </Tooltip>
  );
};

const columns = ({ filters }) => {
  return [
    {
      title: 'Avatar',
      dataIndex: 'user',
      width: 100,
      render: ({ firstName, lastName, profileImage }) => {
        return (
          <Avatar
            src={profileImage}
            firstName={firstName}
            lastName={lastName}
          />
        );
      }
    },
    {
      title: 'F name',
      dataIndex: ['user', 'firstName'],
      ellipsis: true
    },
    {
      title: 'L name',
      dataIndex: ['user', 'lastName'],
      ellipsis: true
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      ellipsis: true,
      render: (value) => value?.[0]?.name
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      ellipsis: true,
      render: (value) => (value ? 'Active' : 'Inactive')
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      width: 300,
      render: (value) => <a href={`mailto:${value}`}>{value}</a>
    },
    {
      title: 'Joined',
      dataIndex: 'createdAt',
      render: (value) => value && moment(value).format(DATE_FORMAT)
    },
    {
      title: 'Created By',
      dataIndex: 'creator',
      render: (creator) => `${creator?.firstName} ${creator?.lastName}`
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (value) => <Action id={value} filters={filters} />
    }
  ];
};

const WorkspaceUsers = ({ history }) => {
  const membersBreadcrumbs = [
    { label: MODULES?.WS_SETTINGS },
    { label: MODULES?.MEMBERS }
  ];

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.WORKSPACE_USER_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: USERS_SORT_FIELD,
      field: USERS_SORT_FIELD[0].value
    }
  });

  const filters = useMemo(() => ({ ...filterProps.filters }), [
    filterProps.filters
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.WS_MEMBERS}/add`, {
      filters
    });
  };

  return (
    <>
      <PageHeader
        menu={membersBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Workspace Users' }}
      />
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_WORKSPACE_USERS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, isAddEditAllowed, filters })?.filter(
              (item) => item !== false
            )
        }}
        limit={20}
      />
    </>
  );
};

export default WorkspaceUsers;
