import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id
      email
      profileImage
      firstName
      lastName
      roles
    }
  }
`;

export const GET_PROFILE_IMAGE_SIGNED_URL_ADMIN = gql`
  query GetAdminProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getAdminProfileImageUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
