import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';

import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_TYPES,
  CONFIG_METHOD_OPTIONS,
  CONFIG_METHOD_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectVideos } from '../../../../labels/collections/components/FormInputs';
import { GET_COLLECTION_ITEMS } from '../../../../labels/collections/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    videoTimestamp: true,
    videoDate: true,
    videoSpeaker: true,
    videoTopic: true,
    videoTag: true,
    videoOrder: true,
    videoProgressBar: true,
    videoDescription: true,
    videoCTA: true,
    videoReactions: true,
    viewAll: true
  },
  config: {
    method: null,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    videos: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_PLAYER_V2
    ].includes(type)
      ? { id: '', url: '', title: '' }
      : []
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoDescription',
    label: 'Video Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_PLAYER_V2
    ]
  },

  {
    name: 'videoTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2
    ]
  },
  {
    name: 'videoDate',
    label: 'Date',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2
    ]
  },
  {
    name: 'videoSpeaker',
    label: 'Speaker',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2
    ]
  },
  {
    name: 'videoTopic',
    label: 'Topic',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.VIDEO_CAROUSEL
    ]
  },
  {
    name: 'videoTag',
    label: 'Tags',
    allowedTypes: [MODULE_TYPES.VIDEO_PLAYER_V2]
  },
  {
    name: 'videoReactions',
    label: 'Reactions',
    allowedTypes: [
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoOrder',
    label: 'Order as Selected',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_PLAYER_V2
    ]
  },
  {
    name: 'videoProgressBar',
    label: 'Progress Bar',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoCTA',
    label: 'Video CTA',
    allowedTypes: [MODULE_TYPES.FEATURED_VIDEO_SLIDER]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'videoContinueWatchingCarouselModule',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'videoWatchLaterCarouselModule',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'videoCarouselModule',
  [MODULE_TYPES.VIDEO_LIST]: 'videoListModule',
  [MODULE_TYPES.VIDEO_GRID]: 'videoGridModule',
  [MODULE_TYPES.FEATURED_VIDEO]: 'featuredVideoModule',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'featuredVideoSliderModule',
  [MODULE_TYPES.VIDEO_PLAYER]: 'videoPlayerModule',
  [MODULE_TYPES.VIDEO_PLAYER_V2]: 'videoPlayerModuleV2'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'Video Continue Watching Configs',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'Video Watch Later Configs',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'Video Carousel Configs',
  [MODULE_TYPES.VIDEO_LIST]: 'Video List Configs',
  [MODULE_TYPES.VIDEO_GRID]: 'Video Grid Configs',
  [MODULE_TYPES.FEATURED_VIDEO]: 'Feature Video Configs',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'Feature Video Slider Configs',
  [MODULE_TYPES.VIDEO_PLAYER]: 'Video Player Configs',
  [MODULE_TYPES.VIDEO_PLAYER_V2]: 'Video Player V2 Configs'
};

const { VIDEO } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.VIDEO_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_GRID]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO]: [VIDEO, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_PLAYER]: [VIDEO],
  [MODULE_TYPES.VIDEO_PLAYER_V2]: [VIDEO]
};

const VideoForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const isMultiVideo = ![
    MODULE_TYPES.FEATURED_VIDEO,
    MODULE_TYPES.VIDEO_PLAYER,
    MODULE_TYPES.VIDEO_PLAYER_V2
  ].includes(type);

  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const settingsProps = Form.useWatch(['settings'], form);
  const { autoGenerateByType, method, autoGenerateById: generateById } =
    configProps ?? initialValues.config;
  const collectionId = generateById?.value;

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            method: defaultValues?.moduleData?.config?.method ?? null,
            ...(![
              MODULE_TYPES.VIDEO_PLAYER_V2,
              MODULE_TYPES.VIDEO_PLAYER
            ]?.includes(type) && {
              autoGenerateByType:
                defaultValues?.moduleData?.config?.videoAutoGenerateByType ||
                null,
              autoGenerateById: idm
                ? TEMPLATE_OPTION
                : getAutoGenerateByDataValue[
                    defaultValues?.moduleData?.config?.videoAutoGenerateByType
                  ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                  null,
              autoGenerateBySubCollectionId:
                defaultValues?.moduleData?.config
                  ?.autoGenerateBySubCollectionData === null
                  ? { label: 'All', value: 'all' }
                  : {
                      label:
                        defaultValues?.moduleData?.config
                          ?.autoGenerateBySubCollectionData?.title,
                      value:
                        defaultValues?.moduleData?.config
                          ?.autoGenerateBySubCollectionData?.id
                    },
              autoGenerateSortBy:
                defaultValues?.moduleData?.config?.videoAutoGenerateSortBy ||
                null,
              autoGenerateLimit:
                defaultValues?.moduleData?.config?.autoGenerateLimit || null
            }),
            videos: isMultiVideo
              ? defaultValues?.moduleData?.config?.videos?.map(
                  ({ id, title, imageThumbnail }) => ({
                    id,
                    title,
                    url: imageThumbnail?.url ?? ''
                  })
                ) || []
              : {
                  id: defaultValues?.moduleData?.config?.video?.id ?? '',
                  title: defaultValues?.moduleData?.config?.video?.title ?? '',
                  url:
                    defaultValues?.moduleData?.config?.video?.imageThumbnail
                      ?.url ?? ''
                }
          }
        })
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiVideo
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && isDefaultModule;

    const {
      videos,
      method: videoMethod,
      autoGenerateById,
      autoGenerateLimit,
      autoGenerateBySubCollectionId,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            method: videoMethod ?? CONFIG_METHOD_TYPES.MANUAL,
            autoGenerateById: autoGenerateById?.value,
            ...(videoMethod === CONFIG_METHOD_TYPES.AUTO_GENERATED &&
              autoGenerateBySubCollectionId && {
                autoGenerateBySubCollectionId:
                  autoGenerateBySubCollectionId?.value === 'all'
                    ? null
                    : autoGenerateBySubCollectionId?.value
              }),
            ...restConfig,
            ...(isDefault
              ? {
                  videoId: '*'
                }
              : {
                  ...(videoMethod === CONFIG_METHOD_TYPES.MANUAL &&
                    isMultiVideo && {
                      videos:
                        videos?.map(({ id }, i) => ({
                          videoId: id,
                          order: i + 1
                        })) ?? []
                    }),
                  ...(videoMethod === CONFIG_METHOD_TYPES.MANUAL &&
                    !isMultiVideo && {
                      videoId: videos?.id ?? ''
                    })
                }),
            ...(isMultiVideo && {
              autoGenerateLimit: autoGenerateLimit
                ? Number(autoGenerateLimit)
                : null
            })
          }
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        {![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && (
          <Space className="w-full" direction="vertical">
            {allowedTemplateConfig && pageType === VIDEO && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}

            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <div>
                  <Form.Item label="Config Method" name={['config', 'method']}>
                    <AntdSelect
                      options={
                        type === MODULE_TYPES.VIDEO_PLAYER
                          ? CONFIG_METHOD_OPTIONS?.filter(
                              ({ value }) =>
                                value === CONFIG_METHOD_TYPES.MANUAL
                            )
                          : CONFIG_METHOD_OPTIONS
                      }
                      placeholder="Select method"
                    />
                  </Form.Item>
                  {![
                    MODULE_TYPES.VIDEO_PLAYER,
                    MODULE_TYPES?.VIDEO_PLAYER_V2
                  ]?.includes(type) && (
                    <>
                      {method === CONFIG_METHOD_TYPES.AUTO_GENERATED && (
                        <>
                          <Form.Item
                            label="Auto Generated By Type"
                            name={['config', 'autoGenerateByType']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_OPTIONS?.concat([
                                {
                                  label: 'Show',
                                  value: AUTO_GENERATED_TYPES.SHOW
                                },
                                {
                                  label: 'Podcast',
                                  value: AUTO_GENERATED_TYPES.PODCAST
                                }
                              ])}
                              placeholder="Select type"
                              onChange={() => {
                                form.setFieldValue(
                                  ['config', 'autoGenerateById'],
                                  null
                                );
                              }}
                            />
                          </Form.Item>
                          {autoGenerateByType &&
                            autoGenerateByIdProps &&
                            ![
                              AUTO_GENERATED_TYPES.PODCAST,
                              AUTO_GENERATED_TYPES.SHOW
                            ]?.includes(autoGenerateByType) && (
                              <Form.Item
                                label="Auto Generated By"
                                name={['config', 'autoGenerateById']}
                              >
                                <Select
                                  placeholder="Select"
                                  query={autoGenerateByIdProps?.query}
                                  variablesSelector={(filter) => ({
                                    filter,
                                    ...(autoGenerateByType ===
                                      AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                                      where: { isActive: true }
                                    })
                                  })}
                                  dataSelector={
                                    autoGenerateByIdProps?.dataSelector
                                  }
                                  keys={autoGenerateByIdProps?.keys}
                                  {...(allowedTemplateConfig && {
                                    includeOptions
                                  })}
                                />
                              </Form.Item>
                            )}
                          {autoGenerateByType ===
                            AUTO_GENERATED_TYPES?.COLLECTION &&
                            collectionId && (
                              <Form.Item
                                label="Select SubCollection"
                                name={[
                                  'config',
                                  'autoGenerateBySubCollectionId'
                                ]}
                              >
                                <Select
                                  placeholder="Select sub collection"
                                  query={GET_COLLECTION_ITEMS}
                                  variablesSelector={() => ({
                                    where: {
                                      id: collectionId
                                    }
                                  })}
                                  dataSelector={(data) =>
                                    data?.collectionItemsAdmin?.collectionItems?.map(
                                      (item) => ({
                                        label: item?.itemData?.title,
                                        value: item?.itemData?.id
                                      })
                                    ) ?? []
                                  }
                                  includeOptions={[
                                    { label: 'All', value: 'all' }
                                  ]}
                                  keys={{
                                    data: 'collectionItemsAdmin',
                                    records: 'collectionItems',
                                    count: 'count'
                                  }}
                                />
                              </Form.Item>
                            )}
                          <Form.Item
                            label="Auto Generated Sort By"
                            name={['config', 'autoGenerateSortBy']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_SORT_BY_OPTIONS}
                              placeholder="Select sort by"
                            />
                          </Form.Item>
                        </>
                      )}
                      {[
                        CONFIG_METHOD_TYPES.AUTO_GENERATED,
                        CONFIG_METHOD_TYPES.RECOMMENDED
                      ]?.includes(method) && (
                        <>
                          {isMultiVideo && (
                            <Form.Item
                              label="No. of Auto generated Videos"
                              name={['config', 'autoGenerateLimit']}
                              rules={[
                                formValidatorRules?.number,
                                formValidatorRules?.maxNumberAllowed(
                                  configData?.MAX_AUTO_GENERATE_LIMIT?.value ||
                                    20
                                )
                              ]}
                            >
                              <Input placeholder="Enter number" />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {method === CONFIG_METHOD_TYPES.MANUAL && (
                    <Form.Item
                      label={isMultiVideo ? 'Select Videos' : 'Select Video'}
                      name={['config', 'videos']}
                      extra={
                        isMultiVideo ? 'Select items in order you want' : ''
                      }
                    >
                      <SelectVideos multiple={isMultiVideo} />
                    </Form.Item>
                  )}
                </div>
              </>
            )}
          </Space>
        )}
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default VideoForm;
