import { Button, Result } from 'antd';
import React from 'react';
import { ROUTES } from '../common/constants';

const NoAccess = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" href={ROUTES?.MAIN}>
          Back Home
        </Button>
      }
    />
  );
};

export default NoAccess;
