import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_COMMENT = gql`
  mutation UpdateCommentAdmin(
    $where: CommentWhereInput!
    $data: UpdateCommentInput!
  ) {
    updateCommentAdmin(where: $where, data: $data) {
      message
    }
  }
`;
