import { Button, ConfigProvider, Image, Input } from 'antd';
import React from 'react';
import webStyle from '../../../../styles/style-web.json';

function NewsLetter({
  title,
  description,
  background = 'bg-blue-2',
  darkBackground,
  responsive,
  image,
  icon
}) {
  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`newsletter-section  ${background} ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        <div className="container">
          <div className="newsletter-wrapper text-center ">
            {icon && (
              <div className="lead-gen-icon">
                <Image
                  preview={false}
                  fill
                  withBlur={false}
                  src={icon?.url}
                  alt={title}
                />
              </div>
            )}
            {title && (
              <div
                className={`title ${
                  darkBackground ? 'text-white-90' : 'text-black-90'
                }`}
              >
                {title}
              </div>
            )}
            {image && (
              <div className="lead-gen-image">
                <Image preview={false} src={image?.url} fill alt={title} />
              </div>
            )}
            {description && (
              <p
                className={`description ${
                  darkBackground ? 'text-white-90' : 'text-black-90'
                }`}
              >
                {description}
              </p>
            )}

            <div className="subscribe-group">
              <div className="form-group">
                <Input placeholder="Enter email" />
              </div>
              <Button
                size="large"
                className={`cs-button  ${
                  darkBackground
                    ? 'b-solid  bg-ivory text-n-900 hbg-n-200 text-n-900 hc-black-80 hb-ivory b-transparent hb-n-200'
                    : 'b-0 bg-n-900 text-white-90 hbg-n-800 hb-neutral-800 hc-white-70'
                } `}
              >
                Subscribe
              </Button>
            </div>
          </div>
          {/* after subscribe thank you html start here */}
          {/* <div className="newsletter-wrapper thank-you">
          <div className={`title text-center ${darkBackground && 'text-n-0'}`}>
            Thank you for Subscribing
          </div>

        </div> */}
          {/* after subscribe thank you html end here */}
        </div>
      </section>
    </ConfigProvider>
  );
}
export default NewsLetter;
