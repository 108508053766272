import { Button, Select, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise } from 'phosphor-react';
import React, { useMemo, useRef, useState } from 'react';
import {
  EXPORT_JOB_TYPES,
  FULL_DATE_TIME_FORMAT,
  LIST_TYPES,
  MODULES,
  STATUS_COLORS
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { GET_IMPORT_JOBS } from './graphql/Queries';

const variablesSelector = ({ limit, offset, type }) => ({
  filter: {
    limit,
    skip: offset,
    type
  }
});

const dataSelector = ({ importJobs }) => ({
  data: importJobs?.data || [],
  count: importJobs?.count || 0
});

const IMPORT_JOB_OPTIONS = [
  { label: 'Ui Labels', value: EXPORT_JOB_TYPES?.UI_LABEL }
];

const columns = [
  {
    title: 'Job ID',
    dataIndex: 'id',
    key: 'id',
    width: 380
  },
  {
    title: 'Uploaded File',
    dataIndex: 'fileUrl',
    key: 'fileUrl',
    ellipsis: true,
    render: (value) => (
      <a href={value} download className="download-links">
        {value}
      </a>
    )
  },
  {
    title: 'Result File',
    dataIndex: 'resultFileUrl',
    key: 'resultFileUrl',
    ellipsis: true,
    render: (value) => (
      <a href={value} download className="download-links">
        {value}
      </a>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS?.[value]}>
        {value}
      </Tag>
    )
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (value) =>
      value ? moment(value).format(FULL_DATE_TIME_FORMAT) : ''
  }
];

const ImportJobs = () => {
  const ref = useRef();
  const [type, setType] = useState(EXPORT_JOB_TYPES?.UI_LABEL);

  const importJobsBreadcrumbs = [
    { label: MODULES?.REPORTS },
    { label: MODULES?.IMPORT_JOBS }
  ];

  const filters = useMemo(
    () => ({
      type
    }),
    [type]
  );
  const handleTypeChange = (value) => setType(value);

  const handleRefresh = () => {
    if (ref?.current?.refresh) {
      ref?.current?.refresh();
    }
  };

  return (
    <>
      <PageHeader menu={importJobsBreadcrumbs} />
      <div className="assets-filter-wrapper">
        <div className="asset-filter-left" />
        <div className="asset-filter-right">
          <div className="asset-filter">
            <Select
              value={type}
              popupMatchSelectWidth={false}
              placeholder="Select type"
              options={IMPORT_JOB_OPTIONS}
              onChange={handleTypeChange}
              className="mr-8"
            />
            <Tooltip title="Refresh" placement="left">
              <Button
                className="text-btn d-flex align-center justify-center"
                type="text"
                icon={<ArrowClockwise size={24} />}
                onClick={handleRefresh}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <PageList
        ref={ref}
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_IMPORT_JOBS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={30}
      />
    </>
  );
};

export default ImportJobs;
