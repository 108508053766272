import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import EditPage from './EditPage';
import Pages from './Pages';
import AddPage from './component/AddPage';

const PagesWrapper = () => (
  <Switch>
    <Route
      path={ROUTES?.UI_CONFIG_PAGES}
      exact
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.VIEW,
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <Pages {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.UI_CONFIG_PAGES}/add`}
      exact
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <AddPage {...props} />
        </AccessControl>
      )}
    />
    <Route
      path={`${ROUTES?.UI_CONFIG_PAGES}/:id/edit`}
      exact
      render={(props) => (
        <AccessControl
          modulePermissions={[
            {
              moduleKey: WORKSPACE_ROLE_PERMISSION.PAGE_MANAGEMENT,
              allowedPermissions: [
                WORKSPACE_ROLE_LEVEL.EDIT,
                WORKSPACE_ROLE_LEVEL.DELETE
              ]
            }
          ]}
        >
          <EditPage {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.UI_CONFIG_PAGES}/:id`}
      render={() => <Redirect to={ROUTES?.UI_CONFIG_PAGES} />}
    />
  </Switch>
);

export default PagesWrapper;
