import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginAdmin($data: LoginInput!) {
    loginAdmin(data: $data) {
      accessToken
      refreshToken
      user {
        id
        email
        profileImage
        firstName
        lastName
        roles
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPasswordAdmin($data: ForgotPasswordInput!) {
    forgotPasswordAdmin(data: $data) {
      message
    }
  }
`;

export const USERS_FORGOT_PASSWORD = gql`
  mutation userForgetPasswordAdmin($data: UserForgetPasswordInput!) {
    userForgetPasswordAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePasswordAdmin($data: UpdatePasswordInput!) {
    updatePasswordAdmin(data: $data) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePasswordAdmin($data: ChangePasswordInput!) {
    changePasswordAdmin(data: $data) {
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutAdmin {
    logoutAdmin {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshTokenAdmin($data: RefreshTokenInput!) {
    refreshTokenAdmin(data: $data) {
      accessToken
      user {
        id
        email
        profileImage
        firstName
        lastName
        roles
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyResetTokenAdmin($data: ResetTokenInput!) {
    verifyResetTokenAdmin(data: $data) {
      message
    }
  }
`;

export const UPDATE_CURRENT_USER_ADMIN = gql`
  mutation UpdateCurrentUserAdmin($data: UpdateCurrentUserAdminInput!) {
    updateCurrentUserAdmin(data: $data) {
      message
    }
  }
`;

export const VERIFY_USER_ADMIN = gql`
  mutation VerifyUserAdmin($data: VerifyUserInput!) {
    verifyUserAdmin(data: $data) {
      message
      resetToken
    }
  }
`;
