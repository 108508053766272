import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_CONTACT_INQUIRY_STATUS = gql`
  mutation updateContactInquiry($id: ID!, $status: ContactInquiryStatus!) {
    updateContactInquiry(where: { id: $id }, data: { status: $status }) {
      message
    }
  }
`;
