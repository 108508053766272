import { Button, Image } from 'antd';
import { BookOpen, Pencil, ShareNetwork } from 'phosphor-react';
import React from 'react';

function FormCard({
  title = 'Petition to U.S. Presidential Candidates',
  background = 'bg-blue-2',
  cta,
  prayerCount = 0,
  type,
  submitCount = 0
}) {
  return (
    <div className={`form-card ${background}`}>
      <div className="vc-description">
        <div className="text-md-bold block fc-title text-black-90 hc-black-70 pointer">
          <Pencil size={24} /> {title}
        </div>
        <div className="d-flex justify-end">
          {type === 'PETITION' && (
            <div className="count-prayer text-tiny-kicker text-black-70 mr-12">
              {submitCount} Signed
            </div>
          )}
          <div className="count-prayer text-tiny-kicker text-black-70">
            {prayerCount} Prayers
          </div>
        </div>
        <div className="form-card-button-wrapper">
          <Button
            icon={<ShareNetwork size={16} weight="bold" />}
            size="small"
            className="d-flex align-center justify-center b-0 bg-n-900 hbg-n-700 hc-white-90 text-white-90 text-small"
            block
          >
            Share
          </Button>
          <Button
            icon={<BookOpen size={16} weight="bold" />}
            size="small"
            className="d-flex align-center justify-center b-0 bg-n-900 hbg-n-700 hc-white-90 text-white-90 text-small"
            block
          >
            Pray
          </Button>
          {cta?.length > 0 && (
            <Button
              icon={
                cta?.[0]?.icon?.url && (
                  <Image
                    src={cta?.[0]?.icon?.url}
                    width={16}
                    height={16}
                    alt="icon"
                  />
                )
              }
              size="small"
              block
              className="d-flex align-center justify-center b-0 bg-n-900 hbg-n-700 hc-white-90 text-white-90 text-small"
            >
              {cta?.[0]?.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormCard;
