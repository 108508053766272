import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowClockwise, PencilSimpleLine } from 'phosphor-react';
import React, { useCallback, useMemo } from 'react';
import podcastPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  CLOUD_FRONT_CACHE_KEYS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { getImageUrl } from '../../common/utils';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import CustomCard from '../component/card/Cscard';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../menus/graphql/Mutations';
import { GET_ARTICLES_MODULE } from './graphql/Queries';

const ArticleCard = ({
  createdAt,
  id,
  title,
  description,
  imageThumbnail,
  onEdit,
  onPreview,
  isViewOnly,
  isAssetAllowed,
  isLabelAllowed
}) => {
  return (
    <CustomCard
      onClick={onPreview}
      alt={title}
      src={getImageUrl(imageThumbnail?.url) ?? podcastPlaceholder}
      blurHash={imageThumbnail?.blurhash}
      title={moment(createdAt).format('ll')}
      heading={title}
      description={description}
      primaryOnClick={() => onEdit(id)}
      primary={
        isAssetAllowed &&
        isLabelAllowed &&
        (isViewOnly ? (
          <>
            <EyeFilled size={16} />
            View
          </>
        ) : (
          <>
            <PencilSimpleLine size={16} />
            Edit
          </>
        ))
      }
    />
  );
};

const getColumns = ({
  handleEdit: onEdit,
  isAssetAllowed,
  isLabelAllowed,
  isViewOnly
}) => [
  {
    title: 'Image',
    dataIndex: 'imageThumbnail',
    ellipsis: true,
    render: (value, { title }) => (
      <Image
        className="group-img-table"
        blurHash={value?.blurhash}
        src={
          getImageUrl(value?.url, { height: 50, width: 80 }) ??
          podcastPlaceholder
        }
        alt={title}
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  ...(isAssetAllowed && isLabelAllowed
    ? [
        {
          title: 'Action',
          dataIndex: 'id',
          render: (value) => (
            <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Article`}>
              <Button
                type="text"
                className="text-btn"
                onClick={() => onEdit(value)}
              >
                {isViewOnly ? <EyeFilled /> : <EditOutlined />}
              </Button>
            </Tooltip>
          )
        }
      ]
    : [])
];

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder
}) => ({
  filter: {
    skip: offset,
    search,
    limit
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ articlesAdmin }) => ({
  data: articlesAdmin?.articles ?? [],
  count: articlesAdmin?.count ?? 0
});

const Article = ({ history }) => {
  const filterProps = useFilterBar();

  const articlesBreadcrumbs = [
    { label: MODULES?.CONTENTS },
    { label: MODULES?.ARTICLES }
  ];

  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.OG_IMAGES }
    }
  );
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isLabelAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.LABEL_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAssetAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ASSET_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.VIEW,
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.CONTENTS_ARTICLES}/add`, {
      filters: filterProps?.filters,
      listMode: filterProps?.listMode
    });
  };

  const handleEdit = useCallback(
    (id) => {
      history.push(`${ROUTES?.CONTENTS_ARTICLES}/${id}/edit`, {
        filters: filterProps?.filters,
        listMode: filterProps?.listMode
      });
    },
    [filterProps]
  );

  const columns = useMemo(
    () =>
      getColumns({
        handleEdit,
        isAddEditAllowed,
        isAssetAllowed,
        isLabelAllowed,
        isViewOnly
      }),
    [handleEdit]
  );

  return (
    <div>
      <PageHeader
        menu={articlesBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed && isAssetAllowed && isLabelAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search by article name' }}
      >
        <Tooltip title="Update Share Preview" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<ArrowClockwise size={24} />}
            onClick={invalidateCache}
            loading={isInvalidating}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filterProps.filters}
        listMode={filterProps.listMode}
        query={GET_ARTICLES_MODULE}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        GridProps={{
          renderItem: (props) => (
            <ArticleCard
              {...props}
              onEdit={handleEdit}
              isAddEditAllowed={isAddEditAllowed}
              isAssetAllowed={isAssetAllowed}
              isLabelAllowed={isLabelAllowed}
              isViewOnly={isViewOnly}
            />
          )
        }}
        TableProps={{ columns }}
      />
    </div>
  );
};

export default Article;
