import { gql } from '@apollo/client';

export const GET_LOOPS = gql`
  query LoopsAdmin($filter: LoopsFilter) {
    loopsAdmin(filter: $filter) {
      count
      loops {
        id
        startDate
        endDate
        totalContentCount
        contentCount
        status
      }
    }
  }
`;

export const GET_LOOP = gql`
  query LoopAdmin($where: LoopAdminWhereInput!) {
    loopAdmin(where: $where) {
      id
      startDate
      endDate
      contents {
        id
        title
        summary
        order
        config {
          ctaTitle
        }
        instanceId
        instanceType
        status
        createdAt
        updatedAt
        instanceData {
          ... on Video {
            id
            imageThumbnail {
              url
              blurhash
              title
            }
            title
          }
          ... on Podcast {
            id
            imageThumbnail {
              url
              title
              blurhash
            }
            title
          }
          ... on Article {
            id
            title
            imageThumbnail {
              url
              title
              blurhash
            }
          }
          ... on Ad {
            id
            title
            image {
              url
              title
              blurhash
            }
          }
        }
      }
      status
      totalContentCount
      contentCount
      createdAt
      updatedAt
    }
  }
`;
