import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Radio,
  Tooltip
} from 'antd';
import { CaretRight, CurrencyDollar, Info, X } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import DonateModule from '../../../../assets/images/dotate.png';
import { isDarkMode } from '../../../../common/utils';
import webStyle from '../../../../styles/style-web.json';

function Donate({
  sectionDark,
  gradientBackground,
  title,
  background = 'bg-blue-2',
  description,
  videoSectionTitle,
  videoSectionSubTitle,
  responsive,
  videoTitle
}) {
  const [darkBackgroundVideo, videoBackground] = useMemo(() => {
    if (isDarkMode()) return [true, 'bg-n-1000'];
    return [false, 'bg-n-400'];
  }, []);

  const onChange = (e) => {
    // eslint-disable-next-line no-console
    console.log(`radio checked:${e.target.value}`);
  };
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(1);

  return (
    <ConfigProvider theme={webStyle}>
      <section
        className={`donate-wrapper ${background} ${
          responsive ? 'responsive-view' : ''
        }`}
      >
        <div className="container">
          <div
            className={`d-title text-center ${
              sectionDark ? 'text-white-90' : 'text-black-90'
            }`}
          >
            {title}
          </div>
          <p
            className={`d-dec text-center ${
              sectionDark ? 'text-white-90' : 'text-black-90'
            }`}
          >
            {description}
          </p>
          <div className="amount-wrapper">
            <div
              className={`amount-video-section ${videoBackground} ${
                gradientBackground && 'gradiant-background'
              } ${videoSectionTitle && 'amount-video-section-flex'}`}
            >
              {videoSectionTitle && (
                <div className="video-section-title">
                  <h3 className={`${darkBackgroundVideo && 'text-n-0'}`}>
                    {videoSectionTitle}
                  </h3>
                  <p className={`${darkBackgroundVideo && 'text-n-0'}`}>
                    {videoSectionSubTitle}
                  </p>
                </div>
              )}

              <div
                className={`video-block ${videoSectionTitle && 'video-flex'}`}
              >
                <div className="video-img">
                  <img src={DonateModule} fill objectFit="cover" alt="images" />
                </div>
                {videoTitle && (
                  <p className={`${darkBackgroundVideo && 'text-n-0'}`}>
                    {videoTitle}
                  </p>
                )}
              </div>
            </div>
            <div className="amount-form">
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <Form.Item label="">
                  <div className="form-label d-flex align-center text-black-90 gap-8 mb-16">
                    Select Tax Deductible Amount
                    <Tooltip
                      placement="top"
                      className="d-flex align-center"
                      title="information"
                    >
                      <Info size={20} />{' '}
                    </Tooltip>
                  </div>
                  <div className="cs-radiogroup">
                    <Radio.Group onChange={onChange} defaultValue="a">
                      <Radio.Button value="a">$10</Radio.Button>
                      <Radio.Button value="b">$50</Radio.Button>
                      <Radio.Button value="c">$100</Radio.Button>
                      <Radio.Button value="d">$1000</Radio.Button>
                    </Radio.Group>
                  </div>
                </Form.Item>
                <div className="cs-devider">
                  <Divider>Or</Divider>
                </div>
                <Form.Item>
                  <div className="form-group">
                    <div className="form-label">Enter Custom Amount</div>
                    <Input
                      placeholder="Amount"
                      prefix={<CurrencyDollar size={20} />}
                      suffix={<X size={20} />}
                    />
                  </div>
                </Form.Item>
                <div className="cs-radio-form">
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>One-time</Radio>
                    <Radio value={2}>Monthly</Radio>
                  </Radio.Group>
                </div>
                <Button
                  size="large"
                  className="cs-button between bg-n-900 text-white-90 hbg-n-800 hc-white-80 hb-black-80"
                  block
                >
                  Continue
                  <CaretRight size={20} />
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </ConfigProvider>
  );
}
export default Donate;
