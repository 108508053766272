import { Select } from 'antd';
import JoditEditor from 'jodit-react';
import 'jodit/es2021/jodit.min.css';
import { capitalize, isEmpty } from 'lodash';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import {
  ASSET_CATEGORY,
  BASE64_IMAGES,
  CONTENT_SELECTION,
  CONTENT_SELECTION_KEYS,
  getContentQueries
} from '../common/constants';
import SelectAsset from '../modules/assets/components/SelectAsset';
import { VideoCard } from '../modules/labels/collections/components/FormInputs';
import { useModal } from '../modules/videos/components/FormInputs';
import SelectableModal from './SelectableModal';
import './styles/joditEditor.less';

const SelectVideos = ({
  closeModal,
  open,
  onSelect,
  modalTitle,
  selectedContent,
  setSelectedContent
}) => {
  const handleCardSelect = (item) => {
    onSelect({
      id: item?.id,
      url: item?.imageThumbnail?.url ?? '',
      title: item?.title,
      slug: item?.slug ?? ''
    });
    setSelectedContent(CONTENT_SELECTION_KEYS.VIDEO);
  };
  const handleContentChange = (e) => {
    setSelectedContent(e);
  };

  return (
    <SelectableModal
      title={modalTitle ?? `Select ${capitalize(selectedContent)}`}
      open={open}
      onClose={() => {
        closeModal();
        setSelectedContent(CONTENT_SELECTION_KEYS.VIDEO);
      }}
      {...getContentQueries[selectedContent]}
      renderFilters={() => {
        return (
          <Select
            value={selectedContent}
            popupMatchSelectWidth={false}
            placeholder="Select Content Unit"
            options={CONTENT_SELECTION}
            onChange={handleContentChange}
            className="custom-content-select"
          />
        );
      }}
      renderItem={(item) => {
        return <VideoCard data={item} onSelect={handleCardSelect} />;
      }}
    />
  );
};

const MemoEditor = memo(
  ({
    disabled,
    className,
    onAssetVideoClick,
    onChange,
    value,
    height = '100%',
    onAssetImageClick
  }) => {
    const handleBlur = (val) => {
      if (val === '<p><br></p>') onChange('');
      else onChange(val);
    };

    const config = useMemo(
      () => ({
        buttons: [
          'paragraph',
          'bold',
          'underline',
          'italic',
          'strikethrough',
          'superscript',
          'subscript',
          'eraser',
          'outdent',
          'indent',
          'align',
          'ol',
          'ul',
          'link',
          'fullsize',
          'source',
          {
            name: 'preview',
            isDisabled: (editor) => {
              return editor.getMode() === 2;
            }
          },
          'undo',
          'redo',
          {
            iconURL: '/video.svg',
            tooltip: 'Add Content',
            name: 'content',
            exec: onAssetVideoClick
          },
          {
            iconURL: '/image.svg',
            tooltip: 'Add Image',
            name: 'assetImage',
            exec: onAssetImageClick
          }
        ],
        toolbarAdaptive: false,
        toolbarButtonSize: 'large',
        disabled,
        height,
        allowResizeX: false,
        allowResizeY: false,
        className,
        theme: 'dark',
        showPlaceholder: false
      }),
      [onAssetVideoClick, disabled, onAssetImageClick]
    );

    return <JoditEditor config={config} value={value} onBlur={handleBlur} />;
  }
);

const HtmlEditor = ({ value, onChange, disabled, height }) => {
  const editorRef = useRef(null);
  const assetRef = useRef(null);
  const [selectedContent, setSelectedContent] = useState(
    CONTENT_SELECTION_KEYS.VIDEO
  );

  const { open, openModal, closeModal } = useModal();

  const onAssetVideoClick = useCallback((editor) => {
    editorRef.current = editor;

    openModal();
  }, []);

  const onAssetImageClick = useCallback((editor) => {
    editorRef.current = editor;
    assetRef?.current?.imageClick();
  }, []);

  const handleChange = useCallback((val) => {
    onChange(val);
  }, []);

  const handleClose = () => {
    editorRef.current = null;
    closeModal();
  };

  const getRoute = {
    VIDEO: 'videos',
    PODCAST: 'podcasts',
    ARTICLE: 'articles',
    FORM: 'forms'
  };

  return (
    <>
      <SelectVideos
        open={open}
        selectedContent={selectedContent}
        setSelectedContent={setSelectedContent}
        onSelect={(item) => {
          if (editorRef.current && item?.slug) {
            editorRef.current?.s?.insertHTML(
              [
                CONTENT_SELECTION_KEYS.FORM,
                CONTENT_SELECTION_KEYS.ARTICLE
              ]?.includes(selectedContent)
                ? `
                <a href="/${getRoute[selectedContent]}/${
                    item?.slug
                  }" target="_blank" style="width: 170px; height: 160px; border-radius: 16px;">
                  <div style="width: 170px; height: 160px; border-radius:16px; position: relative;">
                  ${
                    selectedContent === CONTENT_SELECTION_KEYS.FORM
                      ? `<image src=${BASE64_IMAGES.FORM} style="border-radius:10px; max-width: 170px; max-height: 160px; width:100%; height:100%;" />`
                      : `<image src=${
                          !isEmpty(item?.url)
                            ? item?.url
                            : BASE64_IMAGES.ARTICLE
                        } style="border-radius:10px; max-width: 170px; max-height: 160px; width:100%; height:100%;" />`
                  }
                    <span style="position: absolute; padding: 5px; bottom: 5px; width: auto; left: 10px; right: 10px; margin: auto;
                      font-size: 14px;
                      text-align: center;
                      border-radius: 4px;
                      background-color: #222;
                      color: #fff;" contenteditable=false>${item?.title}</span>
                  </div>
                </a>`
                : `<a href="/${getRoute[selectedContent]}/${
                    item?.slug
                  }" target="_blank" style="position: relative;display: inline-block;">
                  <div class="video-wrapper" style="position: relative;">
                    ${
                      item?.url
                        ? `<image src="${item?.url}" style="display: block;" />`
                        : `<image
                          src="${BASE64_IMAGES.OTHERS}"
                          style="display: block;"
                        />`
                    }
                    <div style="position:absolute;height:100%;width:100%;inset:0;background:#000;opacity:0.5;z-index:1;"></div>
                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgZmlsbD0iI2ZmZmZmZiIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xMjgsMjRBMTA0LDEwNCwwLDEsMCwyMzIsMTI4LDEwNC4xMSwxMDQuMTEsMCwwLDAsMTI4LDI0Wm0wLDE5MmE4OCw4OCwwLDEsMSw4OC04OEE4OC4xLDg4LjEsMCwwLDEsMTI4LDIxNlptNDguMjQtOTQuNzgtNjQtNDBBOCw4LDAsMCwwLDEwMCw4OHY4MGE4LDgsMCwwLDAsMTIuMjQsNi43OGw2NC00MGE4LDgsMCwwLDAsMC0xMy41NlpNMTE2LDE1My41N1YxMDIuNDNMMTU2LjkxLDEyOFoiPjwvcGF0aD48L3N2Zz4=" style="position: absolute; z-index: 2;                    width: 50px; height: 50px;
                    left: 0; right: 0; top: 0; bottom: 0; margin: auto;"></img>
                  </div>
                </a>`
            );

            editorRef?.current?.synchronizeValues();
            handleClose();
          }
        }}
        closeModal={handleClose}
      />
      <SelectAsset
        customButton
        assetRef={assetRef}
        modalTitle="Select Image"
        categoryKey={ASSET_CATEGORY.IMAGE}
        dataSelector={({ id, url }) => ({
          id,
          url
        })}
        onChange={(item) => {
          if (editorRef.current) {
            editorRef.current?.s?.insertHTML(
              `<span style="width: 170px; height: 160px; position: relative;">
                  ${`<image src=${item?.url} style="max-width: 170px; max-height: 160px; width:100%; height:100%;" />`}
                </span>
             `
            );
            editorRef?.current?.synchronizeValues();
            handleClose();
          }
        }}
      />
      <MemoEditor
        value={value}
        onAssetVideoClick={onAssetVideoClick}
        onChange={handleChange}
        disabled={disabled}
        onAssetImageClick={onAssetImageClick}
        height={height}
      />
    </>
  );
};

export default HtmlEditor;
